// UserContext.js
import React, { createContext, useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const { user } = useAuth0();
  const [isPremiumUser, setIsPremiumUser] = useState(false);
  const [premiumKey, setPremiumKey] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/users/user-data/?email=${user.email}`);
          if (response.ok) {
            const data = await response.json();
            setIsPremiumUser(data.is_premium_user);
            setPremiumKey(data.premium_key);
          } else {
            console.error("Failed to fetch user data");
          }
        } catch (error) {
          console.error("Error fetching user data:", error);
        }
      }
    };

    fetchUserData();
  }, [user]);

  return (
    <UserContext.Provider value={{ isPremiumUser, premiumKey }}>
      {children}
    </UserContext.Provider>
  );
};