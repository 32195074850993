import React, { useState, useEffect } from "react";
import {
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Button,
  Menu,
  MenuItem,
  Tooltip,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { UsersFetch } from "../components/Backend Routes/fetchUsers";
import { AddUser, UpdateUsers, DeleteUsers } from '../components/Backend Routes/TeamFetch';
import { useAuth0 } from '@auth0/auth0-react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import AddUserDialog from '../components/Company Profile Components/AddUserDialog';
import EditUserDialog from '../components/Company Profile Components/EditUserDialog';
import DeleteUserDialog from '../components/Company Profile Components/DeleteUserDialog';

// Column definitions for the DataGrid
const columns = [
  { field: "id", headerName: "ID", flex: 0.5, minWidth: 40 },
  { field: "name", headerName: "Name", flex: 2, minWidth: 250 },
  { field: "email", headerName: "Email", flex: 2, minWidth: 300 },
  { field: "subscription_tier", headerName: "Subscription Tier", flex: 1.5, minWidth: 200 },
];

const TeamManagement = () => {
  // Auth and navigation hooks
  const { user } = useAuth0();
  const navigate = useNavigate();

  // Fetch users data
  const { users, premiumKey, loading, error, refetchUsers } = UsersFetch(user?.email || "");

  // Add loading states for each operation
  const [isAddingUser, setIsAddingUser] = useState(false);
  const [isEditingUser, setIsEditingUser] = useState(false);
  const [isDeletingUser, setIsDeletingUser] = useState(false);

  // Form validation states
  const [addEmailError, setAddEmailError] = useState("");
  const [editEmailError, setEditEmailError] = useState("");

  // UI control states
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [AddPopup, setAddPopup] = useState(false);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

  // Add loading state
  const [isSubmitting, setIsSubmitting] = useState(false);

  // User selection and operation states
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState(false);

  // Form states
  const [newUser, setNewUser] = useState({
    name: "",
    email: "",
    subscription_tier: ""
  });

  const [editedUser, setEditedUser] = useState({
    name: "",
    email: "",
    subscription_tier: ""
  });

  // State for managing snackbar notifications
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });
  

  // // Effect to update edited user when selection changes
  useEffect(() => {
    if (selectedUsers.length === 1) {
        const selectedUser = selectedUsers[0];
        // Find the organization entry that matches the premiumKey
        const organizationEntry = selectedUser.organization_id?.find(
            org => Object.keys(org)[0] === premiumKey
        );
        
        // Extract the subscription tier value if found
        const subscription_tier = organizationEntry ? organizationEntry[premiumKey] : "";

        setEditedUser({
            name: selectedUser.name,
            email: selectedUser.email,
            subscription_tier: subscription_tier
        });
    } else {
        setEditedUser({
            name: "",
            email: "",
            subscription_tier: ""
        });
    }
  }, [selectedUsers, premiumKey]); // Added premiumKey to dependencies

  // Menu handlers
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  // Dialog control handlers
  const handleAdd = () => {
    setAddPopup(true);
  };

  const handleEdit = () => {
    setOpenEditPopup(true);
    handleClose();
  };

  const handleDelete = () => {
    setOpenDeleteConfirmation(true);
    handleClose();
  };

  // Form input handlers
  const handleNewUserChange = (field, value) => {
    setNewUser(prevUser => ({
      ...prevUser,
      [field]: value
    }));

    if (field === "email") {
      if (!validateEmail(value)) {
        setAddEmailError("Please enter a valid email address");
      } else {
        setAddEmailError("");
      }
    }
  };

  const handleEditChange = (field, value) => {
    setEditedUser(prevUser => ({
      ...prevUser,
      [field]: value
    }));

    if (field === "email") {
      if (!validateEmail(value)) {
        setEditEmailError("Please enter a valid email address");
      } else {
        setEditEmailError("");
      }
    }
  };

  // Update handleAddSubmit
  const handleAddSubmit = async () => {
    if (isSubmitting) return; // Prevent multiple submissions
    
    setIsSubmitting(true);
    try {
      await AddUser({
        ...newUser,
        premiumKey
      }, user?.email || "");

      setSnackbar({
        open: true,
        message: "Team member added",
        severity: "success"
      });

      await refetchUsers();
      setAddPopup(false);
      setNewUser({
        name: "",
        email: "",
        subscription_tier: ""
      });
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error adding team member",
        severity: "error"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleEditSubmit = async () => {
    if (isEditingUser) return; // Prevent multiple submissions
    
    setIsEditingUser(true);
    try {
      await UpdateUsers({
        ...editedUser,
        premiumKey
      });
  
      setSnackbar({
        open: true,
        message: "Team member info edited",
        severity: "success"
      });
  
      await refetchUsers();
      setOpenEditPopup(false);
      setSelectedUsers([]);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error editing user info",
        severity: "error"
      });
    } finally {
      setIsEditingUser(false);
    }
  };

  const handleDeleteConfirm = async () => {
    if (!confirmDelete || isDeletingUser) return;
    
    setIsDeletingUser(true);
    try {
      const users = selectedUsers.map(user => user.email || "");
      await DeleteUsers(users, premiumKey || "");
  
      await refetchUsers();
      setSnackbar({
        open: true,
        message: "Team member(s) deleted",
        severity: "success"
      });
  
      setOpenDeleteConfirmation(false);
      setConfirmDelete(false);
      setSelectedUsers([]);
    } catch (error) {
      setSnackbar({
        open: true,
        message: "Error deleting team member(s)",
        severity: "error"
      });
    } finally {
      setIsDeletingUser(false);
    }
  };

  // Utility functions
  const validateEmail = (email) => {
    const pattern = /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/;
    return pattern.test(email);
  };

  // Handler for closing the snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const returnProfile = () => {
    navigate('/profile');
  };

  // DataGrid selection handler
  const handleSelectionChange = (selection) => {
    const selectedUserData = selection.map(rowIndex => users[rowIndex-1]);
    setSelectedUsers(selectedUserData);
  };


  // Format data for DataGrid
  const rows = Array.isArray(users)
    ? users.map((user, index) => ({
        id: index + 1,
        name: user.name,
        email: user.email,
        subscription_tier: user.organization_id?.[0] 
          ? Object.values(user.organization_id[0])[0] 
          : "",
      }))
    : [];

  // Render component
  return (
    <div className="page-container">
      {/* Back button and header */}
      <Tooltip title="Back to Company Profile">
        <IconButton onClick={returnProfile}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <Typography variant="h4">Manage Team</Typography>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />

      {/* Main content */}
      <div className="admin-table-container">
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : premiumKey ? (
          <>
            {/* Action buttons */}
            <div style={{ marginBottom: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleClick}
                className="standard-button"
                disabled={selectedUsers.length === 0}
                style={{ 
                  backgroundColor: selectedUsers.length === 0 ? 'grey' : 'primary', 
                  marginRight: '15px' 
                }}
              >
                Actions
              </Button>
              <Menu anchorEl={anchorEl} open={open} onClose={handleClose}>
                {selectedUsers.length === 1 && (
                  <MenuItem onClick={handleEdit}>Edit User</MenuItem>
                )}
                <MenuItem onClick={handleDelete}>Remove Users</MenuItem>
              </Menu>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAdd}
                className="standard-button"
                disabled={selectedUsers.length > 0}
                style={{ 
                  backgroundColor: selectedUsers.length === 0 ? 'grey' : 'primary' 
                }}
              >
                Add User
              </Button>
            </div>

            {/* Users table */}
            <div style={{ height: 500, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                initialState={{
                  pagination: {
                    paginationModel: { page: 0, pageSize: 10 },
                  },
                }}
                pageSizeOptions={[5, 10]}
                checkboxSelection
                onRowSelectionModelChange={handleSelectionChange}
                getRowClassName={() => 'table-row'}
                sx={{
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: 'Black',
                    textAlign: 'center',
                    fontSize: '18px',
                    color: 'white',
                    '& .MuiCheckbox-root': {
                      color: 'white',
                    },
                  },
                }}
              />
            </div>
          </>
        ) : (
          <Typography variant="h5" align="center" style={{ marginTop: '20px' }}>
            Oh Snap! You aren't a premium user so you don't have access to the manage team tab. 
            If you think that's incorrect then reach out to sitesteer_ai@gmail.com.
          </Typography>
        )}
      </div>

      {/* Add User Dialog */}
      <AddUserDialog
        open={AddPopup}
        onClose={() => !isSubmitting && setAddPopup(false)}
        onSubmit={handleAddSubmit}
        newUser={newUser}
        onFieldChange={handleNewUserChange}
        isSubmitting={isSubmitting}
      />

      {/* Edit User Dialog */}
      <EditUserDialog
        open={openEditPopup}
        onClose={() => !isEditingUser && setOpenEditPopup(false)}
        onSubmit={handleEditSubmit}
        editedUser={editedUser}
        onFieldChange={handleEditChange}
        isSubmitting={isEditingUser}
      />

      {/* Delete User Dialog */}
      <DeleteUserDialog
        open={openDeleteConfirmation}
        onClose={() => !isDeletingUser && setOpenDeleteConfirmation(false)}
        onConfirm={handleDeleteConfirm}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
        selectedCount={selectedUsers.length}
        isSubmitting={isDeletingUser}
      />

      {/* Snackbar for displaying success/error messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default TeamManagement;