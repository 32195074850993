// components/ArchiveConfirmationDialog.js

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress 
} from '@mui/material';

/**
 * ArchiveConfirmationDialog Component
 *
 * Displays a confirmation dialog when the user attempts to archive an item.
 *
 * Props:
 * - open (boolean): Controls whether the dialog is open.
 * - onClose (function): Function to call when closing the dialog.
 * - onConfirm (function): Function to call when the archive action is confirmed.
 * - confirmArchive (boolean): Indicates whether the user has checked the confirmation checkbox.
 * - setConfirmArchive (function): Function to update the confirmArchive state.
 * - itemName (string): Name of the item being archived (e.g., "prospect").
 */
const ArchiveConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  confirmArchive,
  setConfirmArchive,
  itemName,
  isArchiving = false,
}) => (
  <Dialog
    open={open}
    onClose={onClose}
    disableEscapeKeyDown={isArchiving}
    disableBackdropClick={isArchiving}
    >
    <DialogTitle>Confirm Archiving</DialogTitle>
    <DialogContent>
      <Typography>
        Are you sure you want to archive this {itemName} entry?
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={confirmArchive}
            onChange={(e) => setConfirmArchive(e.target.checked)}
            disabled={isArchiving}
          />
        }
        label={`Yes, I want to archive this ${itemName}`}
      />
    </DialogContent>
    <DialogActions>
    <Button 
        onClick={onClose}
        disabled={isArchiving}
      >
        Cancel
      </Button>
      <Button
        onClick={onConfirm}
        color="error"
        disabled={!confirmArchive || isArchiving}
        sx={{ 
          minWidth: 80,
          position: 'relative'
        }}
      >
        {isArchiving ? (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              left: '50%',
              marginLeft: '-12px'
            }}
          />
        ) : 'Archive'}
      </Button>
    </DialogActions>
  </Dialog>
);

// Wrap with React.memo to prevent unnecessary re-renders when props haven't changed
export default React.memo(ArchiveConfirmationDialog);
