// // components/DeleteConfirmationDialog.js
// import React from 'react';
// import { 
//   Dialog, 
//   DialogTitle, 
//   DialogContent, 
//   DialogActions, 
//   Button, 
//   Typography, 
//   FormControlLabel, 
//   Checkbox 
// } from '@mui/material';

// const DeleteConfirmationDialog = ({
//   open,
//   onClose,
//   onConfirm,
//   confirmDelete,
//   setConfirmDelete,
//   itemName,
// }) => (
//   <Dialog open={open} onClose={onClose}>
//     <DialogTitle>Confirm Deletion</DialogTitle>
//     <DialogContent>
//       <Typography>
//         Are you sure you want to delete this {itemName} entry?
//       </Typography>
//       <FormControlLabel
//         control={
//           <Checkbox
//             checked={confirmDelete}
//             onChange={(e) => setConfirmDelete(e.target.checked)}
//           />
//         }
//         label={`Yes, I want to delete this ${itemName}`}
//       />
//     </DialogContent>
//     <DialogActions>
//       <Button onClick={onClose}>Cancel</Button>
//       <Button
//         onClick={onConfirm}
//         color="error"
//         disabled={!confirmDelete}
//       >
//         Delete
//       </Button>
//     </DialogActions>
//   </Dialog>
// );

// export default React.memo(DeleteConfirmationDialog);

import React from 'react';
import { 
  Dialog, 
  DialogTitle, 
  DialogContent, 
  DialogActions, 
  Button, 
  Typography, 
  FormControlLabel, 
  Checkbox,
  CircularProgress 
} from '@mui/material';

const DeleteConfirmationDialog = ({
  open,
  onClose,
  onConfirm,
  confirmDelete,
  setConfirmDelete,
  itemName,
  isDeleting = false,
}) => (
  <Dialog 
    open={open} 
    onClose={onClose}
    disableEscapeKeyDown={isDeleting}
    disableBackdropClick={isDeleting}
  >
    <DialogTitle>Confirm Deletion</DialogTitle>
    <DialogContent>
      <Typography>
        Are you sure you want to delete this {itemName} entry?
      </Typography>
      <FormControlLabel
        control={
          <Checkbox
            checked={confirmDelete}
            onChange={(e) => setConfirmDelete(e.target.checked)}
            disabled={isDeleting}
          />
        }
        label={`Yes, I want to delete this ${itemName}`}
      />
    </DialogContent>
    <DialogActions>
      <Button 
        onClick={onClose}
        disabled={isDeleting}
      >
        Cancel
      </Button>
      <Button
        onClick={onConfirm}
        color="error"
        disabled={!confirmDelete || isDeleting}
        sx={{ 
          minWidth: 80,
          position: 'relative'
        }}
      >
        {isDeleting ? (
          <CircularProgress
            size={24}
            sx={{
              position: 'absolute',
              left: '50%',
              marginLeft: '-12px'
            }}
          />
        ) : 'Delete'}
      </Button>
    </DialogActions>
  </Dialog>
);

export default React.memo(DeleteConfirmationDialog);