// hooks/useArchivedFilter.js
// Filter the data based on archive toggle
import { useMemo } from 'react';

export function useArchivedFilter(data, showArchived) {
  return useMemo(() => {
    return data.filter(item => {
      if (showArchived) {
        return true;
      }
      return item.status !== "Archived";
    });
  }, [data, showArchived]);
}