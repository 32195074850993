// QuoteFetch.jsx
// Base URL for API requests, retrieved from environment variables
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

/**
 * Fetches prospect company data from the API.
 * @param {string} premiumKey - The premium key for API authentication.
 * @returns {Promise<Array>} A promise that resolves to an array of customer objects.
 */
export const fetchProspectCompanies = async (premiumKey) => {
  try {
    // Make API request to fetch customer list
    const response = await fetch(`${API_BASE_URL}/prospect/customer-list?owner=${premiumKey}`);
    if (!response.ok) throw new Error('Failed to fetch prospect customer data');
    const data = await response.json();
    console.log("Raw Prospects data:", data);

    // Validate the structure of the received data
    if (!data || !Array.isArray(data.customers)) {
      console.error("Unexpected data structure:", data);
      throw new Error('Unexpected data structure from Prospects API');
    }

    return data.customers;  // Return the array of customer objects
  } catch (err) {
    console.error("Error fetching Prospect companies:", err);
    throw err;  // Re-throw the error for handling in the calling function
  }
};

/**
 * Fetches quote data from the API.
 * @param {string} premiumKey - The premium key for API authentication.
 * @returns {Promise<Array>} A promise that resolves to an array of quote objects with added 'id' property.
 */
export const fetchQuoteData = async (premiumKey) => {
  try {
    // Make API request to fetch quote details
    const response = await fetch(`${API_BASE_URL}/quote/quote-details/?owner=${premiumKey}`);
    if (!response.ok) throw new Error('Failed to fetch quote data');
    const data = await response.json();
    console.log("Raw quote data:", data);

    // Validate the structure of the received data
    if (!data || !data.items) {
      console.error("Unexpected data structure:", data);
      throw new Error('Unexpected data structure from Quote API');
    }

    // Map over the items to ensure each has an 'id' property
    return data.items.map((item, index) => ({
      ...item,
      id: item.quoteId || index.toString(),
    }));
  } catch (err) {
    console.error("Error fetching quote data:", err);
    throw err;  // Re-throw the error for handling in the calling function
  }
};

/**
 * Fetches quote data from the API.
 * @param {string} premiumKey - The premium key for API authentication.
 * @returns {Promise<Array>} A promise that resolves to an array of quote objects with added 'id' property.
 */
export const fetchActiveQuoteData = async (premiumKey) => {
  try {
    // Make API request to fetch quote details
    const response = await fetch(`${API_BASE_URL}/quote/active-quote-details/?owner=${premiumKey}`);
    if (!response.ok) throw new Error('Failed to fetch quote data');
    const data = await response.json();
    console.log("Raw quote data:", data);

    // Validate the structure of the received data
    if (!data || !data.items) {
      console.error("Unexpected data structure:", data);
      throw new Error('Unexpected data structure from Quote API');
    }

    // Map over the items to ensure each has an 'id' property
    return data.items.map((item, index) => ({
      ...item,
      id: item.quoteId || index.toString(),
    }));
  } catch (err) {
    console.error("Error fetching quote data:", err);
    throw err;  // Re-throw the error for handling in the calling function
  }
};

/**
 * Updates quote data via the API.
 * @param {string} premiumKey - The premium key for API authentication.
 * @param {Array} newData - The new quote data to be updated.
 * @returns {Promise<Object>} A promise that resolves to the API response.
 */
export const updateQuoteData = async (premiumKey, newData) => {
  try {
    // Clean the data by removing unnecessary fields and ensuring quoteId exists
    const cleanedData = newData.map(({
      id,
      companyName,
      projectName,
      address,
      ...item
    }) => ({
      ...item,
      quoteId: item.quoteId || "",
    }));
    
    // Prepare the request body
    const requestBody = {
      owner_org: premiumKey,
      items: cleanedData
    };

    console.log("Sending data to backend:", requestBody);

    // Make API request to update quote details
    const response = await fetch(`${API_BASE_URL}/quote/quote-details/?owner=${premiumKey}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    
    // Handle potential errors from the API
    if (!response.ok) {
      const responseData = await response.json();
      let errorMessage = 'Failed to update quote data';
      if (responseData.detail) {
        if (Array.isArray(responseData.detail)) {
          errorMessage += ': ' + responseData.detail.map(err => JSON.stringify(err)).join(', ');
        } else {
          errorMessage += ': ' + responseData.detail;
        }
      }
      throw new Error(errorMessage);
    }

    return await response.json();  // Return the API response
  } catch (error) {
    console.error("Error updating quote data:", error);
    throw error;  // Re-throw the error for handling in the calling function
  }
};