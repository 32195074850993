import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Tooltip,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@mui/material";
import { UserContext } from "../components/UserContext";
import { DataGrid, GridColDef, GridActionsCellItem } from "@mui/x-data-grid";
import { useAuth0 } from '@auth0/auth0-react';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const unitOptions = ["/ hour", "/ project", "/ day", "/ unit"];
const currencyOptions = ["GBP", "Eur", "USD", "CHF"];

const PricingDetails = () => {
  const { user } = useAuth0();
  const { isPremiumUser, premiumKey } = useContext(UserContext);
  const [pricingData, setPricingData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [openAddEditPopup, setOpenAddEditPopup] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [messageText, setMessageText] = useState("");
  const [editedItem, setEditedItem] = useState(null);
  const navigate = useNavigate();

  const fetchPricingData = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/company/pricing/?owner=${premiumKey}`);
    //  {console.log('response', response)}
      if (!response.ok) throw new Error('Failed to fetch pricing data');
      const data = await response.json();
      {console.log('pricing data 1', data)}
      setPricingData(data.items.map((item, index) => ({ ...item, id: index })));
      setLoading(false);
    } catch (err) {
      setError(err.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPricingData();
  }, []);

  const handleAddEdit = (id = null) => {
    if (id !== null) {
      const itemToEdit = pricingData.find(item => item.id === id);
      setEditedItem(itemToEdit);
    } else {
      setEditedItem({
        category: "",
        vatPercentage: 0,
        cost: 0,
        currency: "",
        units: "/ hour",
      });
    }
    setOpenAddEditPopup(true);
  };

  const handleDelete = (id) => {
    const updatedData = pricingData.filter(item => item.id !== id);
    updatePricingData(updatedData);
  };

  const handleChange = (field, value) => {
    setEditedItem(prevItem => ({
      ...prevItem,
      [field]: field === 'vatPercentage' || field === 'cost' ? Number(value) : value,
    }));
  };

  const handleSubmit = async () => {
    let updatedData;
    if (editedItem.id !== undefined) {
      updatedData = pricingData.map(item => 
        item.id === editedItem.id ? editedItem : item
      );
    } else {
      updatedData = [...pricingData, { ...editedItem, id: pricingData.length }];
    }
    await updatePricingData(updatedData);
  };

  const updatePricingData = async (newData) => {
    try {
      // Remove the 'id' field from each item
      const cleanedData = newData.map(({ id, ...item }) => ({
        ...item,
        vatPercentage: Number(item.vatPercentage),
        cost: Number(item.cost),
      }));
      
      const requestBody = {
        owner_org: premiumKey,
        items: cleanedData
      };

      console.log("Sending data to backend:", requestBody);

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/company/pricing/?owner=${premiumKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      
      const responseData = await response.json();
      console.log("Full response from server:", responseData);

      if (!response.ok) {
        let errorMessage = 'Failed to update pricing data';
        if (responseData.detail) {
          if (Array.isArray(responseData.detail)) {
            errorMessage += ': ' + responseData.detail.map(err => JSON.stringify(err)).join(', ');
          } else {
            errorMessage += ': ' + responseData.detail;
          }
        }
        throw new Error(errorMessage);
      }
      
      setPricingData(newData);
      setMessageText("Pricing data updated successfully");
      setShowMessage(true);
      setOpenAddEditPopup(false);
    } catch (error) {
      console.error("Error updating pricing data:", error);
      setMessageText(`Failed to update pricing data: ${error.message}`);
      setShowMessage(true);
    }
  };

  const handleCloseMessage = () => {
    setShowMessage(false);
  };

  const returnProfile = () => {
    navigate('/profile');
  };

  const columns: GridColDef[] = [
    { field: "category", headerName: "Cost Category", flex: 2, minWidth: 200 },
    { 
      field: "vatPercentage", 
      headerName: "VAT %", 
      flex: 1, 
      minWidth: 100, 
    //   type: 'number',
    },
    { 
      field: "cost", 
      headerName: "Cost", 
      flex: 1, 
      minWidth: 100, 
    //   type: 'number',
    },
    { 
        field: "currency", 
        headerName: "Currency", 
        flex: 1, 
        minWidth: 100, 
    },
    { field: "units", headerName: "Units", flex: 1, minWidth: 100 },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleAddEdit(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={() => handleDelete(id)}
            color="inherit"
          />,
        ];
      }
    },
  ];

  return (
    <div className="page-container">
      <Tooltip title="Back to Profile">
        <IconButton onClick={returnProfile}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <Typography variant="h4">Pricing Details</Typography>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />
      <div className="pricing-details-container">
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            <div style={{ marginBottom: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddEdit()}
                className="standard-button"
                style={{ marginRight: '15px' }}
              >
                Add Pricing Item
              </Button>
            </div>
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={pricingData}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                sx={{
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: 'Black',
                    textAlign: 'center',
                    fontSize: '18px',
                    color: 'white',
                  },
                }}
              />
            </div>
          </>
        )}
      </div>
      {/* Add/Edit Pricing Item Popup */}
      <Dialog open={openAddEditPopup} onClose={() => setOpenAddEditPopup(false)}>
        <DialogTitle>{editedItem && editedItem.id !== undefined ? "Edit Pricing Item" : "Add Pricing Item"}</DialogTitle>
        <DialogContent>
          <TextField
            label="Category"
            value={editedItem?.category || ""}
            onChange={(e) => handleChange("category", e.target.value)}
            fullWidth
            margin="normal"
            required
          />
          <TextField
            label="VAT %"
            value={editedItem?.vatPercentage || ""}
            onChange={(e) => handleChange("vatPercentage", e.target.value)}
            fullWidth
            margin="normal"
            required
            type="number"
          />
          <TextField
            label="Cost"
            value={editedItem?.cost || ""}
            onChange={(e) => handleChange("cost", e.target.value)}
            fullWidth
            margin="normal"
            required
            type="number"
          />
            <FormControl fullWidth margin="normal">
            <InputLabel id="currency-label">Currency</InputLabel>
            <Select
              labelId="currency-label"
              value={editedItem?.currency || ""}
              onChange={(e) => handleChange("currency", e.target.value)}
              fullWidth
              required
            >
              {currencyOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel id="units-label">Units</InputLabel>
            <Select
              labelId="units-label"
              value={editedItem?.units || "/ hour"}
              onChange={(e) => handleChange("units", e.target.value)}
              fullWidth
              required
            >
              {unitOptions.map((option) => (
                <MenuItem key={option} value={option}>{option}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenAddEditPopup(false)}>Cancel</Button>
          <Button onClick={handleSubmit} color="primary">Save</Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={showMessage}
        autoHideDuration={2000}
        onClose={handleCloseMessage}
        message={messageText}
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

export default PricingDetails;