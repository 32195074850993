import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
  CircularProgress
} from "@mui/material";

const DeleteUserDialog = ({ 
  open, 
  onClose, 
  onConfirm, 
  confirmDelete,
  setConfirmDelete,
  selectedCount,
  isSubmitting = false 
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      disableEscapeKeyDown={isSubmitting}
    >
      <DialogTitle>Confirm Remove</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to remove the selected {selectedCount} {selectedCount === 1 ? 'user' : 'users'}?
        </Typography>
        <FormControlLabel
          control={
            <Checkbox 
              checked={confirmDelete}
              onChange={(e) => setConfirmDelete(e.target.checked)}
              disabled={isSubmitting}
            />
          }
          label="Confirm Delete"
        />
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          onClick={onConfirm}
          color="error"
          disabled={!confirmDelete || isSubmitting}
          sx={{ 
            minWidth: 80,
            position: 'relative'
          }}
        >
          {isSubmitting ? (
            <CircularProgress
              size={24}
              color="error"
              sx={{
                position: 'absolute',
                left: '50%',
                marginLeft: '-12px'
              }}
            />
          ) : 'Delete'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteUserDialog;