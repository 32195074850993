// hooks/useURLParameters.js
/**
 * Extract 'companyId' and 'companyName' from URL query parameters and apply it as a filter.
 * Prevents infinite loops by checking if the filter is already set.
 */
// hooks/useURLParameters.js
import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

export function useURLParameters({ 
  onSetInitialIds, 
  onSetFilter, 
  companyLookup 
}) {
  const location = useLocation();
  const hasSetInitialFilter = useRef(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const companyId = params.get("companyId");
    const projectId = params.get("projectId");
    const companyName = params.get("companyName");
    const action = params.get('action');
    
    // Handle the add action with IDs - this should always work
    if (action === 'add' && companyId && projectId) {
      onSetInitialIds({ companyId, projectId });
    }

    // Only set the filter once when the component mounts
    if (companyName && !hasSetInitialFilter.current) {
      console.log('Setting initial filter for companyName:', companyName);
      onSetFilter({
        items: [
          {
            field: "companyName",
            operatorValue: "equals",
            value: companyName,
          },
        ],
      });
      hasSetInitialFilter.current = true;
    }
  }, [location.search, onSetInitialIds, onSetFilter, companyLookup]);

  // Provide a way to reset the filter flag if needed
  const resetFilterFlag = () => {
    hasSetInitialFilter.current = false;
  };

  return { resetFilterFlag };
}