// EarlySignon.jsx
import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Snackbar, IconButton, Typography, Checkbox, FormControlLabel } from '@mui/material';
import { Link } from 'react-router-dom';
import TextInput from '../components/Form Components/TextInput';
import CloseIcon from '@mui/icons-material/Close';

const EarlySignon = () => {
  const { control, handleSubmit, watch } = useForm();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const navigate = useNavigate();
  const privacyNoticeAccepted = watch('privacyNoticeAccepted', false);
  const url = `${process.env.REACT_APP_API_BASE_URL}/users/early-signon/`;

  const headingStyle = {
    textAlign: 'center',
    margin: 0, // Resetting margin to ensure the text is perfectly centered
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  const onSubmit = async (data) => {
    console.log('Form submitted');
    try {
      const response = await fetch(url, {
        // const response = await fetch(`https://api.polyglotplus.com/early-signon/`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...data,
        }),
      });
    console.log('Body:', JSON.stringify({
        ...data,
      }));
      if (response.ok) {
        console.log('request submitted successfully');
        setShowSuccessMessage(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
          navigate('/'); // Redirect to the root route after 3 seconds
        }, 3000);
      } else {
        console.error('Failed to send request');
      }
    } catch (error) {
      console.error('Error signing up for early adoption', error);
    }
  };

  return (
    <div className="signon-page-container">
      <div className="signon-page-content">
        <div className="signon-text">
            <h2>Be the first to see Site Steer in Action</h2>
            <p>What you'll have when signing up for Eearly Bird Adoption:</p>
            <ul>
                <li>A concierge service from one of our experts to make adoption of Site Steer seemless.</li>
                <li>First month of use for free.</li>
                <li>You'll have disroportionate influence on the product and we'll make sure to build the product to fit your needs!</li>
            </ul>
            </div>
            <div className="signon-form-wrapper">
            <h1 style={headingStyle}>Signup for Early Bird Adoption</h1>
            <form onSubmit={handleSubmit(onSubmit)}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                <Controller
                    name="name"
                    control={control}
                    defaultValue=""
                    rules={{ required: false }}
                    render={({ field }) => <TextInput {...field} label="Name" required />}
                />
                </Grid>
                <Grid item xs={12}>
                <Controller
                    name="organization"
                    control={control}
                    defaultValue=""
                    rules={{ required: true }}
                    render={({ field }) => <TextInput {...field} label="Organization" required />}
                />
                </Grid>
                <Grid item xs={12}>
                <Controller
                    name="email"
                    control={control}
                    defaultValue=""
                    placeholder="(Optional)"
                    rules={{ required: false }}
                    render={({ field }) => <TextInput {...field} label="Email" required />}
                />
                </Grid>
                <Grid item xs={12}>
                <FormControlLabel
                    control={
                    <Controller
                        name="privacyNoticeAccepted"
                        control={control}
                        defaultValue={false}
                        rules={{ required: true }}
                        render={({ field }) => <Checkbox {...field} />}
                    />
                    }
                    label={
                    <Typography>
                        {/* I have read and acknowledge the <strong>Privacy Notice</strong>. */}
                        I have read and acknowledge the <Link to="/privacy-notice" target="_blank" rel="noopener noreferrer">Privacy Notice</Link>.
                    </Typography>
                    }
                />
                </Grid>
                <Grid item xs={12}>
                <FormControlLabel
                    control={
                    <Controller
                        name="receiveMarketingCommunication"
                        control={control}
                        defaultValue={false}
                        render={({ field }) => <Checkbox {...field} />}
                    />
                    }
                    label={<Typography>I accept to receive communication from Site Steer</Typography>}
                />
                </Grid>
                <Grid item xs={12}>
                <Button type="submit" variant="contained" color="primary" disabled={!privacyNoticeAccepted}>
                    Submit
                </Button>
                </Grid>
                <Snackbar
                open={showSuccessMessage}
                autoHideDuration={3000}
                onClose={handleCloseSuccessMessage}
                message="Request Successfully Submitted"
                action={
                    <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
                    <CloseIcon fontSize="small" />
                    </IconButton>
                }
                />
            </Grid>
            </form>
        </div>
        </div>
    </div>
  );
};

export default EarlySignon;