// CompanyFetch.jsx
import { useState, useEffect } from "react";

export const CompanyFetch = (owner) => {
  const [company, setCompany] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchCompany = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/company/?owner=${owner}`);
      if (response.ok) {
        const data = await response.json();
        setCompany(data);
        setLoading(false);
      } else {
        setError('Failed to fetch user profiles');
        setLoading(false);
      }
    } catch (error) {
      setError('Error: ' + error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCompany();
  }, []);

  return { company, loading, error, refetchCompany: fetchCompany };  // Include premiumKey in the return value
};
