import React, { useState, useRef, useEffect } from 'react';
import { TextField, Button, Typography } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import DatePicker from './DatePicker';
import NumberField from './NumberField';
import DecimalField from './DecimalField';
import SignatureDialog from './SignatureDialog';
import ColorDropdown from './ColourDropdown';
import RequiredAsterisk from './RequiredAsterisk';
import PictureCapture from './PictureCapture'; // Import the PictureCapture component

const Table = React.forwardRef(({ name, label, required, control, columns, data, initialRows = 1 }, ref) => {
  const { setValue, getValues } = useFormContext();
  const [rows, setRows] = useState(Array(initialRows).fill({}));
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const [selectedSignatureData, setSelectedSignatureData] = useState(null);
  const [openPictureDialog, setOpenPictureDialog] = useState(false);
  const [selectedPictureData, setSelectedPictureData] = useState(null);
  const fileInputRef = useRef(null);
  const tableRef = useRef(null);
  const [headerFontSize, setHeaderFontSize] = useState(16); // Start with default font size


  useEffect(() => {
    const adjustHeaderFontSize = () => {
      if (tableRef.current) {
        const tableWidth = tableRef.current.offsetWidth;
        const totalColumnWidth = columns.length * 100; // Assuming each column is 100px wide
        const ratio = tableWidth / totalColumnWidth;
        
        // Adjust font size based on ratio, with a minimum of 12px
        const newFontSize = Math.max(12, Math.min(14, 14 * ratio));
        setHeaderFontSize(newFontSize);
      }
    };

    adjustHeaderFontSize();
    window.addEventListener('resize', adjustHeaderFontSize);
    return () => window.removeEventListener('resize', adjustHeaderFontSize);
  }, [columns]);


  const handleAddRow = () => {
    setRows((prevRows) => [
      ...prevRows,
      prevRows.length > 0 ? { ...prevRows[prevRows.length - 1] } : {},
    ]);
  };

  const handleSignoffClick = (rowIndex, columnName) => {
    setSelectedSignatureData({ rowIndex, columnName });
    setOpenSignatureDialog(true);
  };

  const handleSignatureDialogClose = () => {
    setOpenSignatureDialog(false);
    setSelectedSignatureData(null);
  };

  const handlePictureClick = (rowIndex, columnName) => {
    setSelectedPictureData({ rowIndex, columnName });
    setOpenPictureDialog(true);
  };

  const handlePictureDialogClose = () => {
    setOpenPictureDialog(false);
    setSelectedPictureData(null);
  };

  const handlePictureCapture = (pictureData) => {
    const { rowIndex, columnName } = selectedPictureData;
    setRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[rowIndex][columnName] = pictureData;
      return newRows;
    });
    // Update the form data with the base64 string
    setValue(`${name}.${rowIndex}.${columnName}`, pictureData);
    handlePictureDialogClose();
  };

  const handleSignatureDialogSubmit = (signatureData) => {
    const { rowIndex, columnName } = selectedSignatureData;
    setRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[rowIndex][columnName] = signatureData;
      return newRows;
    });
    setValue(`${name}.${rowIndex}.${columnName}`, signatureData);
    console.log("Updated signature data:", getValues(`${name}.${rowIndex}.${columnName}`));
    handleSignatureDialogClose();
  };

  const renderField = (column, rowIndex) => {
    const fieldName = `${name}.${rowIndex}.${column.name}`;
    const isRequired = column.required;

    switch (column.dataType) {
    case 'description':
      return (
        <Typography variant="body1" gutterBottom>
          {data && data[rowIndex] && data[rowIndex][column.name]}
        </Typography>
      );
      case 'colour':
        return (
          <Controller
            name={fieldName}
            control={control}
            defaultValue="green"
            render={({ field }) => (
              <ColorDropdown
                {...field}
                onChange={(value) => {
                  setRows((prevRows) => {
                    const newRows = [...prevRows];
                    newRows[rowIndex][column.name] = value;
                    return newRows;
                  });
                  field.onChange(value);
                }}
                fullWidth
              />
            )}
          />
        );
      case 'text':
        return (
          <Controller
            name={fieldName}
            control={control}
            defaultValue=""
            rules={{ required: column.required }}
            render={({ field }) => (
              <TextField
                {...field}
                placeholder="Enter text here"
                fullWidth
              />
            )}
          />
        );
      case 'number':
        return (
          <Controller
            name={fieldName}
            control={control}
            defaultValue=""
            rules={{ required: column.required }}
            render={({ field }) => (
              <NumberField {...field} fullWidth />
            )}
          />
        );
      case 'decimal':
        return (
          <Controller
            name={fieldName}
            control={control}
            defaultValue=""
            rules={{ required: column.required }}
            render={({ field }) => (
              <DecimalField {...field} fullWidth />
            )}
          />
        );
      case 'date':
        return (
          <Controller
            name={fieldName}
            control={control}
            defaultValue=""
            rules={{ required: column.required }}
            render={({ field }) => (
              <DatePicker {...field} fullWidth />
            )}
          />
        );
      case 'signature':
        const signatureData = rows[rowIndex][column.name];
        const isSignatureEmpty = !signatureData || Object.keys(signatureData).length === 0;
        return (
          <Button
            variant="contained"
            onClick={() => handleSignoffClick(rowIndex, column.name)}
            fullWidth
            style={{ backgroundColor: isSignatureEmpty ? 'red' : 'green', color: 'white' }}
          >
            {isSignatureEmpty ? 'Sign' : 'Signed'}
          </Button>
        );
      case 'picture':
        return (
          <Controller
            name={fieldName}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <div>
                <Button
                  variant="contained"
                  onClick={() => handlePictureClick(rowIndex, column.name)}
                  fullWidth
                  style={{ backgroundColor: field.value ? 'green' : 'red', color: 'white' }}
                >
                  {field.value ? 'Picture Taken' : 'Take Picture'}
                </Button>
                {field.value && (
                  <img src={field.value} alt="Captured" style={{ maxWidth: '100%', marginTop: '10px' }} />
                )}
              </div>
            )}
          />
        );
      default:
        return null;
    }
  };

  const renderLabel = (label, isRequired) => (
    <>
      {label}
      {isRequired && <RequiredAsterisk />}
    </>
  );

  return (
    <div className="form-element">
      <label>{label}</label>
      <hr style={{ borderTop: '2px solid black' }} />
      <div style={{ overflowX: 'auto' }}>
      <table>
        <thead>
          <tr>
            {columns && columns.map((column, colIndex) => (
              <th
                key={colIndex}
                className="table-header"
                style={{
                  textAlign: 'center',
                  padding: '8px',
                  border: '1px solid black',
                  outline: '1px solid black',
                  fontSize: `${headerFontSize}px`,
                }}
              >
                {renderLabel(column.label, column.required)}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((_, rowIndex) => (
            <tr key={rowIndex}>
              {columns && columns.map((column, colIndex) => (
                <td
                  key={colIndex}
                  style={{
                    textAlign: 'center',
                    padding: '4px',
                    border: '1px solid black',
                    outline: '1px solid black',
                  }}
                >
                  {renderField(column, rowIndex)}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Button color="inherit" onClick={handleAddRow}>Add Row</Button>
      <SignatureDialog
        open={openSignatureDialog}
        onClose={handleSignatureDialogClose}
        onSubmit={handleSignatureDialogSubmit}
      />
      <PictureCapture
        open={openPictureDialog}
        onClose={handlePictureDialogClose}
        onCapture={handlePictureCapture}
      />
    </div>
    </div>
  );
});

export default Table;


// import React, { useState } from 'react';
// import { TextField, Button } from '@mui/material';
// import { Controller } from 'react-hook-form';
// import DatePicker from './DatePicker';
// import NumberField from './NumberField';
// import DecimalField from './DecimalField';
// import SignatureDialog from './SignatureDialog';
// import ColorDropdown from './ColourDropdown';

// const Table = React.forwardRef(({ name, label, required, control, columns }, ref) => {
//   const [rows, setRows] = useState([{}]);
//   const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
//   const [selectedSignatureData, setSelectedSignatureData] = useState(null);

//   const handleAddRow = () => {
//     setRows([...rows, {}]);
//   };

//   const handleSignoffClick = (rowIndex, columnName) => {
//     setSelectedSignatureData({ rowIndex, columnName });
//     setOpenSignatureDialog(true);
//   };

//   const handleSignatureDialogClose = () => {
//     setOpenSignatureDialog(false);
//     setSelectedSignatureData(null);
//   };

//   const handleSignatureDialogSubmit = (signatureData) => {
//     const { rowIndex, columnName } = selectedSignatureData;
//     setRows((prevRows) => {
//       const newRows = [...prevRows];
//       newRows[rowIndex][columnName] = signatureData;
//       return newRows;
//     });
//     handleSignatureDialogClose();
//   };

//   const renderField = (column, rowIndex) => {
//     const fieldName = `${name}.${rowIndex}.${column.name}`;
//     switch (column.dataType) {
//       case 'colour':
//         return (
//           <Controller
//             name={fieldName}
//             control={control}
//             defaultValue="green"
//             render={({ field }) => (
//               <ColorDropdown
//                 {...field}
//                 onChange={(value) => {
//                   setRows((prevRows) => {
//                     const newRows = [...prevRows];
//                     newRows[rowIndex][column.name] = value;
//                     return newRows;
//                   });
//                   field.onChange(value);
//                 }}
//                 fullWidth
//               />
//             )}
//           />
//         );
//       case 'text':
//         return (
//           <Controller
//             name={fieldName}
//             control={control}
//             defaultValue=""
//             rules={{ required: column.required }}
//             render={({ field }) => (
//               <TextField
//                 {...field}
//                 placeholder="Enter text here"
//                 fullWidth
//               />
//             )}
//           />
//         );
//       case 'number':
//         return (
//           <Controller
//             name={fieldName}
//             control={control}
//             defaultValue=""
//             rules={{ required: column.required }}
//             render={({ field }) => (
//               <NumberField {...field} fullWidth />
//             )}
//           />
//         );
//       case 'decimal':
//         return (
//           <Controller
//             name={fieldName}
//             control={control}
//             defaultValue=""
//             rules={{ required: column.required }}
//             render={({ field }) => (
//               <DecimalField {...field} fullWidth />
//             )}
//           />
//         );
//       case 'date':
//         return (
//           <Controller
//             name={fieldName}
//             control={control}
//             defaultValue=""
//             rules={{ required: column.required }}
//             render={({ field }) => (
//               <DatePicker {...field} fullWidth />
//             )}
//           />
//         );
//       case 'signature':
//         const signatureData = rows[rowIndex][column.name];
//         const isSignatureEmpty = !signatureData || Object.keys(signatureData).length === 0;
//         return (
//           <Button
//             variant="contained"
//             onClick={() => handleSignoffClick(rowIndex, column.name)}
//             fullWidth
//             style={{ backgroundColor: isSignatureEmpty ? 'red' : 'green', color: 'white' }}
//           >
//             {isSignatureEmpty ? 'Sign' : 'Signed'}
//           </Button>
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     <div className="form-element">
//       <label>{label}</label>
//       <hr style={{ borderTop: '2px solid black' }} />
//       <table>
//         <thead>
//           <tr>
//             {columns && columns.map((column, colIndex) => (
//               <th
//                 key={colIndex}
//                 className="table-header"
//                 style={{
//                   textAlign: 'left',
//                   padding: '8px',
//                   border: '1px solid black',
//                   outline: '1px solid black',
//                 }}
//               >
//                 {column.label}
//               </th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {rows.map((_, rowIndex) => (
//             <tr key={rowIndex}>
//               {columns && columns.map((column, colIndex) => (
//                 <td
//                   key={colIndex}
//                   style={{
//                     padding: '8px',
//                     border: '1px solid black',
//                     outline: '1px solid black',
//                   }}
//                 >
//                   {renderField(column, rowIndex)}
//                 </td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <Button onClick={handleAddRow}>Add Row</Button>
//       <SignatureDialog
//         open={openSignatureDialog}
//         onClose={handleSignatureDialogClose}
//         onSubmit={handleSignatureDialogSubmit}
//       />
//     </div>
//   );
// });

// export default Table;