import React from 'react';
import FadeInText from './FadeInText';
import desktopImage from '../images/Desktop Example.png';
import mobileImage from '../images/Mobile Phone Example.png';

const CustomisableSlatesSection = () => {
  return (
    <section className="cs-section">
      <h2 className="cs-main-title">Customisable Slates</h2>
      <div className="cs-grid">
        <div className="cs-desktop">
          <FadeInText>
            <h3>Build your paperwork on computer</h3>
            <img src={desktopImage} alt="Desktop Application" className="cs-desktop-image" />
          </FadeInText>
        </div>
        <div className="cs-mobile">
          <FadeInText>
            <h3>Assign to your team on mobile</h3>
            <img src={mobileImage} alt="Mobile Application" className="cs-mobile-image" />
          </FadeInText>
        </div>
        <div className="cs-features-list">
          <h3>QA/QC & Handover</h3>
          <p>Easily track the completion stage of work.</p>
          <h3>H&S Forms</h3>
          <p>Simplify your compliance process with customizable purchase order templates.</p>
          <h3>Quotes & Invoices</h3>
          <p>Customise your quotes & invoices to match your brand and streamline your billing process.</p>
          <p>and much more!</p>
        </div>
      </div>
    </section>
  );
};

export default CustomisableSlatesSection;

// import React from 'react';
// import FadeInText from './FadeInText';
// import logo from './images/Sitesteer main logo.png'; // Update this path
// import desktopImage from './images/Desktop Example.png'; // Update this path
// import mobileImage from './images/Mobile Phone Example.png'; // Update this path

// const CustomisableSlatesSection = () => {
//   return (
//     <section className="cs-section">
//       <div className="cs-fixed-content">
//         <h2 className="cs-main-title">Customisable Slates</h2>
//       </div>
//       <div className="cs-scrollable-content">
//         <FadeInText>
//             <h3>Build your paperwork on computer</h3>
//             <img src={desktopImage} alt="Desktop Application" className="cs-desktop-image" />
//         </FadeInText>
//         <FadeInText>
//             <h3>Assign to your team on mobile</h3>  
//             <img src={mobileImage} alt="Mobile Application" className="cs-mobile-image" />
//         </FadeInText>
//         <div className="cs-features-list">
//           <h3>QA/QC & Handover</h3>
//           <p>Easily track the completion stage of work.</p>
//           <h3>H&S Forms</h3>
//           <p>Simplify your compliance process with customizable purchase order templates.</p>
//           <h3>Quotes & Invoices</h3>
//           <p>Customise your quotes & invoices to match your brand and streamline your billing process.</p>
//           <p>and much more!</p>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default CustomisableSlatesSection;