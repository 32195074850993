import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";


export const PrivateRoute = ({ component }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div>Loading...</div>
    ),
  });

  return <Component />;
};

// import React from "react";
// import { Navigate } from "react-router-dom";
// import { withAuthenticationRequired } from "@auth0/auth0-react";

// export const PrivateRoute = ({ component, userProfileExists, ...args }) => {
//   const Component = withAuthenticationRequired(component, {
//     onRedirecting: () => <div>Loading...</div>,
//   });

//   return userProfileExists ? <Component {...args} /> : <Navigate to="/" replace />;
// };
