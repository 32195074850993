// AddUser.js
export const AddUser = async (userFields, adminEmail) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/team/add-user/?email=${adminEmail}`;

  // Transform the data structure to match backend expectations
  // organization_id should be an array of objects like [{ org_key: subscription_tier }]
  const transformedUserFields = {
      name: userFields.name,
      email: userFields.email,
      premiumKey: userFields.premiumKey,
      subscription_tier: userFields.subscription_tier 
  };

  console.log('Sending to backend:', transformedUserFields);

  try {
      const response = await fetch(url, {
          method: 'PUT',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify(transformedUserFields),
      });

      if (!response.ok) {
          const errorData = await response.json().catch(() => null);
          console.error('Server response:', errorData);
          throw new Error(`HTTP error! status: ${response.status}`);
      }

      const responseData = await response.json();
      console.log('Add user response:', responseData);
      return responseData;
  } catch (error) {
      console.error('Add user error:', error);
      throw error;
  }
};


// DeleteUser.js
export const DeleteUsers = async (users, premiumKey) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/team/delete-users/?premiumKey=${premiumKey}`;

  try {
    const response = await fetch(`${url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( users ),
    });

    console.log('Sending users to backend:', users);
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Receive response from backend
    const responseData = await response.json();
    console.log('Deletion response:', responseData);

    // Return the response data
    return responseData;
  } catch (error) {
    console.error('Deletion error:', error);
    // Rethrow the error to be caught in the calling component
    throw error;
  }
};


// UpdateUsers.js
export const UpdateUsers = async (updatedFields) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/team/update-team-users/`;

  // Transform the data structure to match backend expectations
// organization_id should be an array of objects like [{ org_key: subscription_tier }]
const transformedUserFields = {
  name: updatedFields.name,
  email: updatedFields.email,
  premiumKey: updatedFields.premiumKey,
  subscription_tier: updatedFields.subscription_tier 
};

  console.log('Sending to backend:', updatedFields);

  try {
    const response = await fetch(`${url}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(updatedFields),
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Receive response from backend
    const responseData = await response.json();
    console.log('Update response:', responseData);

    // Return the response data
    return responseData;
  } catch (error) {
    console.error('Update error:', error);
    // Rethrow the error to be caught in the calling component
    throw error;
  }
};