// import React from 'react';
// import FadeInText from './FadeInText';
// import phoneImage from '../images/push-notifications-phone.png'; // Update this path to your actual image path

// const PushNotificationsSection = () => {
//   return (
//     <section className="pn-section">
//       <h2 className="pn-main-title">Push Notifications</h2>
//       <div className="pn-grid">
//         <div className="pn-features-list">
//           <FadeInText>
//             <div className="pn-number">1</div>
//             <h3>Stay Informed</h3>
//             <p>Receive instant updates on project status, deadlines, and other critical information.</p>
//             <div className="pn-number">2</div>
//             <h3>Improved Communication</h3>
//             <p>Enhance collaboration and ensure your team is always on the same page.</p>
//             <div className="pn-number">3</div>
//             <h3>Streamlined Workflows</h3>
//             <p>Minimize delays and maximize efficiency with real-time notifications.</p>
//           </FadeInText>
//         </div>
//         <div className="pn-phone">
//           <FadeInText>
//             <img src={phoneImage} alt="Push Notifications on Phone" className="pn-phone-image" />
//           </FadeInText>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default PushNotificationsSection;
import React from 'react';
import FadeInText from './FadeInText';
import phoneImage from '../images/Push notifications.png'; // Update this path to your actual image path

const PushNotificationsSection = () => {
  return (
    <section className="pn-section">
      <h2 className="pn-main-title">Push Notifications</h2>
      <div className="pn-grid">
        <div className="pn-features-list">
          <FadeInText>
            <div className="pn-feature">
              <div className="pn-number">1</div>
              <div className="pn-feature-content">
                <h3>Stay Informed</h3>
                <p>Receive instant updates on project status, deadlines, and other critical information.</p>
              </div>
            </div>
            <div className="pn-feature">
              <div className="pn-number">2</div>
              <div className="pn-feature-content">
                <h3>Improved Communication</h3>
                <p>Enhance collaboration and ensure your team is always on the same page.</p>
              </div>
            </div>
            <div className="pn-feature">
              <div className="pn-number">3</div>
              <div className="pn-feature-content">
                <h3>Streamlined Workflows</h3>
                <p>Minimize delays and maximize efficiency with real-time notifications.</p>
              </div>
            </div>
          </FadeInText>
        </div>
        <div className="pn-phone">
        {/* <img src={phoneImage} alt="Push Notifications on Phone" className="pn-phone-image" /> */}
          <FadeInText>
            <img src={phoneImage} alt="Push Notifications on Phone" className="pn-phone-image" />
          </FadeInText>
        </div>
      </div>
    </section>
  );
};

export default PushNotificationsSection;