/**
 * Handles the downloading of a quote or invocie as a PDF.
 * @param {string} premiumKey - The premium key for API authentication.
 * @param {string} id - The unique identifier of the quote or invoice to download.
 */
export const handleDownload = async (premiumKey, stage, stageId, Id) => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/${stage}/download/?owner=${premiumKey}&${stageId}=${Id}`, {
        method: 'GET',
      });
      
      if (!response.ok) {
        throw new Error(`Failed to download ${stage}`);
      }
      
      return await response.blob();
    } catch (error) {
      console.error(`Error downloading ${stage}:`, error);
      throw error;
    }
  };