import React from 'react';
import { Controller } from 'react-hook-form';
import TextInput from '../Form Components/TextInput';
import Checkbox from '../Form Components/Checkbox';
import DatePicker from '../Form Components/DatePicker';
import Signature from '../Form Components/Signature';
import RadioButton from '../Form Components/RadioButton';
import Table from '../Form Components/Table_Create';
import NumberField from '../Form Components/NumberField';
import DecimalField from '../Form Components/DecimalField';
import Dropdown from '../Form Components/Dropdown';
import FileUpload from '../Form Components/FileUpload';
import SectionBreak from '../Form Components/SectionBreak';
import Description from '../Form Components/Description';
import RequiredAsterisk from '../Form Components/RequiredAsterisk';

const RenderEditSlate = ({ element, control, register, onUpdateElement, onOpenTableColumnDialog}) => {
  if (!element || !control || !register) {
    console.error('Element, control, or register is not defined');
    return null;
  }

  console.log('received request to add or remove:', element)

  const renderLabel = (label) => (
    <>
      {label}
      {element.required && <RequiredAsterisk />}
    </>
  );

  switch (element.field_type) {
    case 'text':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <TextInput {...field} label={renderLabel(element.label)} />
          )}
        />
      );
    case 'checkbox':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue={[]}
          render={({ field }) => (
            <Checkbox {...field} label={renderLabel(element.label)} options={element.options || []} />
          )}
        />
      );
    case 'date':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <DatePicker {...field} label={renderLabel(element.label)} />
          )}
        />
      );
    case 'signature':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Signature {...field} label={renderLabel(element.label)} />
          )}
        />
      );
    case 'radio':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <RadioButton {...field} label={renderLabel(element.label)} options={element.options || []} />
          )}
        />
      );
    case 'number':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <NumberField {...field} label={renderLabel(element.label)} />
          )}
        />
      );
    case 'decimal':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <DecimalField {...field} label={renderLabel(element.label)} />
          )}
        />
      );
    case 'dropdown':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Dropdown {...field} label={renderLabel(element.label)} options={element.options || []} />
          )}
        />
      );
    case 'file':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <FileUpload {...field} label={renderLabel(element.label)} />
          )}
        />
      );
    case 'section_break':
      return <SectionBreak label={renderLabel(element.label)} />;
    case 'description':
      return <Description name={element.name} label={element.label} register={register}/>;    
    case 'table':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          render={({ field }) => (
            <Table
              {...field}
              label={element.label}
              columns={element.columns}
              register={register}
              onAddColumnClick={() => {
                onOpenTableColumnDialog(element);
              }}
              onUpdateColumns={(updatedColumns) => {
                const updatedElement = {
                  ...element,
                  columns: updatedColumns,
                };
                onUpdateElement(updatedElement);
              }}
            />
          )}
        />
      );
    default:
      return null;
  }
};

export default RenderEditSlate;