// TextInput.js
import React from 'react';
import { TextField, Typography } from '@mui/material';

// const TextInput = React.forwardRef(({ name, label, required, ...props }, ref) => {
const TextInput = React.forwardRef(({ name, label, required, register, ...props }, ref) => {  
  return (
    <div className="form-element">
      <Typography variant="subtitle1" className="form-element-label">
          {label}
        </Typography>
      <TextField
        id={name}
        name={name}
        // inputRef={ref}
        inputRef={register}
        required={required}
        fullWidth
        {...props}
      />
    </div>
  );
});

export default TextInput;

// // TextInput.js
// import React from 'react';

// const TextInput = React.forwardRef(({ name, label, required, ...props }, ref) => {
//   return (
//     <div className="form-element">
//       <label htmlFor={name}>{label}</label>
//       <input type="text" id={name} name={name} ref={ref} required={required} {...props} />
//     </div>
//   );
// });

// export default TextInput;