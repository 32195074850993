// DataFetch.js
import { useState, useEffect } from "react";

export const useFetchFormData = (url, owner, status) => {
  const [formFields, setFormFields] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchFormFields = async () => {
    setLoading(true);
    try {
      // Add organization, email, and status as query parameters to the URL
      const response = await fetch(`${url}?owner_org=${owner}&status=${status}`);
      const data = await response.json();
      setFormFields(data.forms);
      setError(null);
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
    console.log('formFields', formFields)
  };

  useEffect(() => {
    fetchFormFields();
  }, [url, owner, status]);

  const refetchFormData = () => {
    console.log('fetchFormFields called')
    fetchFormFields();
    console.log(fetchFormFields)
  };

  return { formFields, loading, error, refetchFormData };
};