// CreateProject.js
import React, { useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, TextField, MenuItem, Grid, IconButton, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from "../components/UserContext";
import { UsersFetch } from "../components/Backend Routes/fetchUsers";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip';
import '../App.css';

const CreateProject = () => {
  const { control, handleSubmit, reset } = useForm();
  const navigate = useNavigate();
  const { user } = useAuth0();
  const { users, refetchUsers } = UsersFetch(user.email);
  const { isPremiumUser, premiumKey } = useContext(UserContext);
  const url = `${process.env.REACT_APP_API_BASE_URL}/projects/create-project/`

  // Handle form submission
  const onSubmit = async (formData) => {
    console.log('Form data:', formData);
    const submissionData = {
      completion_date: null,
      projectId: "1",
      database_id: "",
      owner: premiumKey,
      ...formData,
    };
    console.log('submissionData:', submissionData);
    
    try {
      // const response = await fetch('http://localhost:8000/create-project', {
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(submissionData),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const responseData = await response.json();
      console.log('Response data:', responseData);
      navigate('/projects');
    } catch (error) {
      console.error('Error:', error);
    }
  };

  const returnProjects = () => {
    navigate('/projects');
  };

  return (
    <div className="page-container">
    <div className="create-project-container">
      <Tooltip title="Back to projects">
        <IconButton onClick={() => returnProjects()}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <Typography variant="h4">Create Project</Typography>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Controller
              name="projectName"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Project Name"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="projectType"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Project Type"
                  select
                  fullWidth
                  required
                >
                  <MenuItem value="Major Works">Major Works</MenuItem>
                  <MenuItem value="Small Works">Small Works</MenuItem>
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="projectLead"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Project Lead"
                  fullWidth
                  required
                  select  
                >
                  {users.map((user) => (
                    <MenuItem key={user.email} value={user.name}>
                      {user.name} - {user.email}
                    </MenuItem>
                  ))}
                  </TextField>
              )}
            />
          </Grid>
          {/* <Grid item xs={12} md={6}>
            <Controller
              name="timezone"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Timezone"
                  select
                  fullWidth
                  required
                >
                  <MenuItem value="GMT-12:00">GMT-12:00</MenuItem>
                  <MenuItem value="GMT-11:00">GMT-11:00</MenuItem>
                  <MenuItem value="GMT-10:00">GMT-10:00</MenuItem>
                  <MenuItem value="GMT-09:00">GMT-09:00</MenuItem>
                  <MenuItem value="GMT-08:00">GMT-08:00</MenuItem>
                  <MenuItem value="GMT-07:00">GMT-07:00</MenuItem>
                  <MenuItem value="GMT-06:00">GMT-06:00</MenuItem>
                  <MenuItem value="GMT-06:00">GMT-05:00</MenuItem>
                  <MenuItem value="GMT-05:00">GMT-04:00</MenuItem>
                  <MenuItem value="GMT-04:00">GMT-03:00</MenuItem>
                  <MenuItem value="GMT-03:00">GMT-02:00</MenuItem>
                  <MenuItem value="GMT-02:00">GMT-01:00</MenuItem>
                  <MenuItem value="GMT-01:00">GMT-01:00</MenuItem>
                  <MenuItem value="GMT-00:00">GMT+00:00</MenuItem>
                  <MenuItem value="GMT+01:00">GMT+01:00</MenuItem>
                  <MenuItem value="GMT+02:00">GMT+02:00</MenuItem>
                  <MenuItem value="GMT+03:00">GMT+03:00</MenuItem>
                  <MenuItem value="GMT+04:00">GMT+04:00</MenuItem>
                  <MenuItem value="GMT+05:00">GMT+05:00</MenuItem>
                  <MenuItem value="GMT+06:00">GMT+06:00</MenuItem>
                  <MenuItem value="GMT+07:00">GMT+07:00</MenuItem>
                  <MenuItem value="GMT+08:00">GMT+08:00</MenuItem>
                  <MenuItem value="GMT+09:00">GMT+09:00</MenuItem>
                  <MenuItem value="GMT+10:00">GMT+10:00</MenuItem>
                  <MenuItem value="GMT+11:00">GMT+11:00</MenuItem>
                  <MenuItem value="GMT+12:00">GMT+12:00</MenuItem>
                </TextField>
              )}
            />
          </Grid> */}
          <Grid item xs={12} md={6}>
            <Controller
              name="currency"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Currency"
                  select
                  fullWidth
                  required
                >
                  <MenuItem value="USD">USD</MenuItem>
                  <MenuItem value="GBP">GBP</MenuItem>
                  <MenuItem value="EUR">EUR</MenuItem>
                  {/* Add more currencies as needed */}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="estimated_date"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Estimated Due Date"
                  type="date"
                  fullWidth
                  required
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Controller
              name="status"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Status"
                  select
                  fullWidth
                  required
                >
                  <MenuItem value="Active">In Progress</MenuItem>
                  <MenuItem value="Completed">Completed</MenuItem>
                  {/* Add more currencies as needed */}
                </TextField>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="address"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Project Address"
                  fullWidth
                  required
                />
              )}
            />
          </Grid>
        </Grid>
        <div style={{ marginTop: '20px', textAlign: 'right' }}>
          <Button variant="contained" className="standard-button" type="submit">Create Project</Button>
        </div>
      </form>
    </div>
  </div>
  );
};

export default CreateProject;
