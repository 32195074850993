// ColourDropdown.js
import React from 'react';
import { Select, MenuItem } from '@mui/material';

const ColourDropdown = React.forwardRef(({ name, label, required, value, onChange }, ref) => {
  const handleChange = (event) => {
    onChange(event.target.value);
  };

   // Custom rendering of the selected item
   const renderSelectedValue = (selected) => (
    <div
      style={{
        backgroundColor: selected,
        color: selected === 'yellow' ? 'black' : 'white',
        borderRadius: '4px',
      }}
    >
      {selected}
    </div>
  );
  
  return (
    <div>
      <Select
        name={name}
        value={value}
        onChange={handleChange}
        ref={ref}
        required={required}
        variant="outlined"
        fullWidth
        renderValue={renderSelectedValue} // Use the custom rendering function
      >
        <MenuItem value="green" style={{ backgroundColor: 'green', color: 'white' }}>
          Green
        </MenuItem>
        <MenuItem value="yellow" style={{ backgroundColor: 'yellow', color: 'black' }}>
          Yellow
        </MenuItem>
        <MenuItem value="red" style={{ backgroundColor: 'red', color: 'white' }}>
          Red
        </MenuItem>
      </Select>
    </div>
  );
});

export default ColourDropdown;