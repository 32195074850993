import React from 'react';
import { Typography, IconButton } from '@mui/material';
import CloseIcon from "@mui/icons-material/Close";


/**
 * Render the applied filters as visual filter bubbles above the DataGrid.
 * Each bubble displays the header name and filter value, and includes a close icon to remove the filter.
 */
export function FilterBubbles({ filterModel, fieldToHeaderName, companyLookup, onRemoveFilter }) {
  return (
    <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", marginBottom: "10px" }}>
      {filterModel.items.map((filter, index) =>
        filter.value ? (
          <div
            key={index}
            style={{
              backgroundColor: "#FFD700",
              borderRadius: "20px",
              padding: "5px 10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Typography variant="body2">
              {`${fieldToHeaderName[filter.field] || filter.field} - ${
                filter.field === "companyId" && companyLookup 
                  ? companyLookup[filter.value] || filter.value 
                  : filter.value
              }`}
            </Typography>
            <IconButton size="small" onClick={() => onRemoveFilter(index)}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </div>
        ) : null
      )}
    </div>
  );
}