import React, { useState } from 'react';
import { List, ListItem, ListItemText, Grid, Snackbar, Typography, Button, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import TextInput from '../components/Form Components/TextInput';
import Checkbox from '../components/Form Components/Checkbox';
import DatePicker from '../components/Form Components/DatePicker';
import Signature from '../components/Form Components/Signature';
import RadioButton from '../components/Form Components/RadioButton';
import Table from '../components/Form Components/Table_Submit';
import NumberField from '../components/Form Components/NumberField';
import DecimalField from '../components/Form Components/DecimalField';
import Dropdown from '../components/Form Components/Dropdown';
import FileUpload from '../components/Form Components/FileUpload';
import SectionBreak from '../components/Form Components/SectionBreak';
import { useAuth0 } from '@auth0/auth0-react';
import Description from '../components/Form Components/Description';
import RequiredAsterisk from '../components/Form Components/RequiredAsterisk';
import { SlatesFetch } from '../components/Backend Routes/UserSlatesFetch';

const YourForms = () => {
  const methods = useForm();
  const { control, register, handleSubmit, reset, setValue, getValues, formState: { errors } } = methods;
  const [selectedForm, setSelectedForm] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { user } = useAuth0()
  const url = `${process.env.REACT_APP_API_BASE_URL}/slates/user-slates/`
  const { slates, loading, error, refetchSlates } = SlatesFetch(
    url,
    user.email,
    'false'
  );

  console.log('slates:', slates)
  
  const handleFormSelect = (form) => {
    setSelectedForm(form);
    reset(form.data);
  };

  console.log('selectedForm:', selectedForm)
  
  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };
  
  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const renderFormElement = (element, index) => {
    const isRequired = element.required;

    const renderLabel = (label) => (
      <>
        {label}
        {isRequired && <RequiredAsterisk />}
      </>
    );

    switch (element.field_type) {
      case 'email':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            rules={{
              required: "Please enter your email",
              pattern: {
                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                message: "Please enter a valid email"
              }
            }}
            render={({ field }) => (
              <TextInput 
                {...field} 
                type="email"
                label={element.label} 
                placeholder="Enter the assignee email" 
              />
            )}
          />
        );
      case 'text':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            rules={{ required: element.required }}
            render={({ field }) => <TextInput {...field} label={renderLabel(element.label)} />} 
          />
        );
      case 'checkbox':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue={[]}
            rules={{ required: element.required }}
            render={({ field: { onChange, value, ...field } }) => (
              <Checkbox
                {...field}
                label={renderLabel(element.label)}
                options={element.options || []}
                onChange={onChange}
                required={element.required}
                value={value}
              />
            )}
          />
        );
      case 'section_break':
        return (
          <SectionBreak
            name={element.name}
            label={element.label}
            control={control}
            register={register}
            required={element.required}
          />
        );
      case 'description':
        return (
          <Description
            name={element.name}
            label={renderLabel(element.label)}
            control={control}
            register={register}
            required={element.required}
          />
        );
      case 'date':
        return (
          <Controller 
            name={element.name}
            control={control}
            defaultValue=""
            rules={{ required: element.required }}
            render={({ field }) => <DatePicker {...field} label={renderLabel(element.label)} />}
          />
        );
      case 'signature':
        return (
          <Controller
            key={index}
            name={element.name}
            control={control}
            render={({ field }) => (
              <Signature
                {...field}
                label={element.label}
                required={element.required}
                setValue={setValue}
                getValues={getValues}
              />
            )}
          />
        );
      case 'radio':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            rules={{ required: element.required }}
            render={({ field }) => (
              <RadioButton {...field} label={renderLabel(element.label)} options={element.options || []} />
            )}
          />
        );
      case 'number':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            rules={{ required: element.required }}
            render={({ field }) => <NumberField {...field} label={renderLabel(element.label)} />}
          />
        );
      case 'decimal':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            rules={{ required: element.required }}
            render={({ field }) => <DecimalField {...field} label={renderLabel(element.label)} />}
          />
        );
      case 'table':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            rules={{ required: element.required }}
            render={({ field }) => (
              <Table
                {...field}
                name={element.name.toString()} // Pass the name as a string
                label={renderLabel(element.label)}
                columns={element.columns} // Pass the columns data to the Table component
                control={control}
                register={register}
                data={selectedForm.data[element.name]} // Pass the table data from the selected form
                initialRows={selectedForm.data[element.name]?.length || 1} // Set the initial number of rows based on the fetched data
              />
            )}
          />
        );
      case 'dropdown':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            rules={{ required: element.required }}
            render={({ field }) => (
              <Dropdown {...field} label={renderLabel(element.label)} options={element.options || []} />
            )}
          />
        );
      case 'file':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            rules={{ required: element.required }}
            render={({ field }) => <FileUpload {...field} label={renderLabel(element.label)} />}
          />
        );
      default:
        return null;
    }
  };

  const onSubmit = async (formData) => {
    console.log('Form submitted:', formData);
  
    if (Object.keys(errors).length > 0) {
      console.log('Errors in form:', errors);
      return;
    }
  
    const currentDateTime = new Date().toISOString();
  
    const updatedForm = {
      ...selectedForm,
      last_updated: currentDateTime,
      data: { ...formData },
      status: true,
    };
  
    const formDataToSend = new FormData();
  
    const filePromises = [];
    Object.entries(updatedForm.data).forEach(([key, value]) => {
      if (Array.isArray(value)) {
        value.forEach((item, rowIndex) => {
          Object.entries(item).forEach(([columnName, fieldValue]) => {
            if (fieldValue && typeof fieldValue === 'string' && fieldValue.startsWith('data:image')) {
              const promise = fetch(fieldValue)
                .then(res => res.blob())
                .then(blob => {
                  const fileName = `${key}_${rowIndex}_${columnName}.jpg`;
                  formDataToSend.append('files', blob, fileName);
                  // Update the updatedForm to use the filename instead of the data URL
                  updatedForm.data[key][rowIndex][columnName] = fileName;
                });
              filePromises.push(promise);
            }
          });
        });
      }
    });
  
    await Promise.all(filePromises);
  
    console.log('updatedForm before JSON.stringify:', updatedForm);
  
    formDataToSend.append('json', JSON.stringify(updatedForm));
  
    // Debug: Log the formDataToSend
    for (let [key, value] of formDataToSend.entries()) {
      if (key === 'files') {
        console.log(key, value.name, value.size);
      } else {
        console.log(key, value);
      }
    }
  
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/submit-slate/${selectedForm.database_id}`, {
        method: 'PUT',
        body: formDataToSend,
      });
  
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${errorText}`);
      }
  
      const responseData = await response.json();
      console.log('Submission response:', responseData);
  
      setShowSuccessMessage(true);
      refetchSlates();
      setSelectedForm(null);
    } catch (error) {
      console.error('Submission error:', error);
    }
  };


  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
  };

  return (
    <div className="your-slate-panel">
    {/* <h1>Your Slates</h1> */}
    <Typography variant="h4">Your Slates</Typography>
    <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />
    <Grid container spacing={2}>
      <Grid item xs={3}>
        <div className="elements-panel">
          <List>
            {slates && slates.map((form, index) => (
              <ListItem className="form-element-button" key={index} onClick={() => handleFormSelect(form)}>
                <ListItemText primary={form.title} secondary={`Due: ${formatDate(form.due_date)}`} /> 
              </ListItem>
            ))}
          </List>
        </div>
      </Grid>
      <Grid item xs={9}>
        {selectedForm ? (
          <div className="form-preview" style={{ border: '2px solid black',  overflowX: 'auto'}}>, 
            <div className="form-element-container">
              <Typography className="form-title" variant="h4" gutterBottom>
                {selectedForm.project} 
              </Typography>
              <Typography className="form-title" variant="h4" gutterBottom>
                {selectedForm.title} 
              </Typography>
              <Typography className="form-description" variant="subtitle1" gutterBottom>
                {selectedForm.description}
              </Typography>
              <FormProvider {...methods}>
                <form onSubmit={handleSubmit(onSubmit)}>
                  {selectedForm.fields.map((element, index) => {
                    if (element.field_type === 'description') {
                      return (
                        <div key={element.name} className="form-element">
                          <Typography variant="body1" gutterBottom>
                            {selectedForm.data.description}
                          </Typography>
                        </div>
                      );
                    } else {
                      return (
                        <div key={element.name} className="form-element">
                          {renderFormElement(element, index)}
                        </div>
                      );
                    }
                  })}
                  <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
                    <Button type="submit" variant="contained" className="form-element-submit">
                      Submit Slate
                    </Button>
                  </div>
                </form>
              </FormProvider>
            </div>
          </div>
        ) : (
          <Typography variant="body1">Please select a slate from the list.</Typography>
        )}
      </Grid>
      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={3000}
        onClose={handleCloseSuccessMessage}
        message="Form Submitted Successfully"
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </Grid>
    </div>
  );
};

export default YourForms;


// import React, { useState } from 'react';
// import { List, ListItem, ListItemText, Grid, Snackbar, Typography, Button, IconButton } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import { useForm, Controller, FormProvider } from 'react-hook-form';
// import TextInput from '../components/Form Components/TextInput';
// import Checkbox from '../components/Form Components/Checkbox';
// import DatePicker from '../components/Form Components/DatePicker';
// import Signature from '../components/Form Components/Signature';
// import RadioButton from '../components/Form Components/RadioButton';
// import Table from '../components/Form Components/Table_Submit';
// import NumberField from '../components/Form Components/NumberField';
// import DecimalField from '../components/Form Components/DecimalField';
// import Dropdown from '../components/Form Components/Dropdown';
// import FileUpload from '../components/Form Components/FileUpload';
// import SectionBreak from '../components/Form Components/SectionBreak';
// import { useAuth0 } from '@auth0/auth0-react';
// import Description from '../components/Form Components/Description';
// import RequiredAsterisk from '../components/Form Components/RequiredAsterisk';
// import { SlatesFetch } from '../components/Backend Routes/UserSlatesFetch';

// const YourForms = () => {
//   const methods = useForm();
//   const { control, register, handleSubmit, reset, setValue, getValues, formState: { errors } } = methods;
//   const [selectedForm, setSelectedForm] = useState(null);
//   const [showSuccessMessage, setShowSuccessMessage] = useState(false);
//   const { user } = useAuth0()
//   const url = `${process.env.REACT_APP_API_BASE_URL}/user-slates/`
//   const { slates, loading, error, refetchSlates } = SlatesFetch(
//     url,
//     user.email,
//     'false'
//   );

//   console.log('slates:', slates)
  
//   const handleFormSelect = (form) => {
//     setSelectedForm(form);
//     reset(form.data);
//   };

//   console.log('selectedForm:', selectedForm)
  
//   const handleCloseSuccessMessage = () => {
//     setShowSuccessMessage(false);
//   };
  
//   if (loading) {
//     return <div>Loading...</div>;
//   }

//   if (error) {
//     return <div>Error: {error.message}</div>;
//   }

//   const renderFormElement = (element, index) => {
//     const isRequired = element.required;

//     const renderLabel = (label) => (
//       <>
//         {label}
//         {isRequired && <RequiredAsterisk />}
//       </>
//     );

//     switch (element.field_type) {
//       case 'email':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             rules={{
//               required: "Please enter your email",
//               pattern: {
//                 value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
//                 message: "Please enter a valid email"
//               }
//             }}
//             render={({ field }) => (
//               <TextInput 
//                 {...field} 
//                 type="email"
//                 label={element.label} 
//                 placeholder="Enter the assignee email" 
//               />
//             )}
//           />
//         );
//       case 'text':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             rules={{ required: element.required }}
//             render={({ field }) => <TextInput {...field} label={renderLabel(element.label)} />} 
//           />
//         );
//       case 'checkbox':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue={[]}
//             rules={{ required: element.required }}
//             render={({ field: { onChange, value, ...field } }) => (
//               <Checkbox
//                 {...field}
//                 label={renderLabel(element.label)}
//                 options={element.options || []}
//                 onChange={onChange}
//                 required={element.required}
//                 value={value}
//               />
//             )}
//           />
//         );
//       case 'section_break':
//         return (
//           <SectionBreak
//             name={element.name}
//             label={element.label}
//             control={control}
//             register={register}
//             required={element.required}
//           />
//         );
//       case 'description':
//         return (
//           <Description
//             name={element.name}
//             label={renderLabel(element.label)}
//             control={control}
//             register={register}
//             required={element.required}
//           />
//         );
//       case 'date':
//         return (
//           <Controller 
//             name={element.name}
//             control={control}
//             defaultValue=""
//             rules={{ required: element.required }}
//             render={({ field }) => <DatePicker {...field} label={renderLabel(element.label)} />}
//           />
//         );
//       case 'signature':
//         return (
//           <Controller
//             key={index}
//             name={element.name}
//             control={control}
//             render={({ field }) => (
//               <Signature
//                 {...field}
//                 label={element.label}
//                 required={element.required}
//                 setValue={setValue}
//                 getValues={getValues}
//               />
//             )}
//           />
//         );
//       case 'radio':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             rules={{ required: element.required }}
//             render={({ field }) => (
//               <RadioButton {...field} label={renderLabel(element.label)} options={element.options || []} />
//             )}
//           />
//         );
//       case 'number':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             rules={{ required: element.required }}
//             render={({ field }) => <NumberField {...field} label={renderLabel(element.label)} />}
//           />
//         );
//       case 'decimal':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             rules={{ required: element.required }}
//             render={({ field }) => <DecimalField {...field} label={renderLabel(element.label)} />}
//           />
//         );
//       case 'table':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             rules={{ required: element.required }}
//             render={({ field }) => (
//               <Table
//                 {...field}
//                 name={element.name.toString()} // Pass the name as a string
//                 label={renderLabel(element.label)}
//                 columns={element.columns} // Pass the columns data to the Table component
//                 control={control}
//                 register={register}
//                 data={selectedForm.data[element.name]} // Pass the table data from the selected form
//                 initialRows={selectedForm.data[element.name]?.length || 1} // Set the initial number of rows based on the fetched data
//               />
//             )}
//           />
//         );
//       case 'dropdown':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             rules={{ required: element.required }}
//             render={({ field }) => (
//               <Dropdown {...field} label={renderLabel(element.label)} options={element.options || []} />
//             )}
//           />
//         );
//       case 'file':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             rules={{ required: element.required }}
//             render={({ field }) => <FileUpload {...field} label={renderLabel(element.label)} />}
//           />
//         );
//       default:
//         return null;
//     }
//   };

//   const onSubmit = async (formData) => {
//     console.log('Form submitted:', formData);

//     //Stops the submission of the tables which have mandatory fields.
//     if (Object.keys(errors).length > 0) {
//       console.log('Errors in form:', errors);
//       return;
//     }

//     const updatedForm = {
//       ...selectedForm,
//       data: formData,
//       status: true,
//     };

//     console.log('updatedForm:', updatedForm);

//     try {
//       const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/submit-slate/${selectedForm.database_id}`, {
//         method: 'PUT',
//         headers: { 'Content-Type': 'application/json' },
//         body: JSON.stringify(updatedForm),
//       });

//       if (!response.ok) {
//         throw new Error('Network response was not ok');
//       }

//       const responseData = await response.json();
//       console.log('Submission response:', responseData);

//       setShowSuccessMessage(true);
//       refetchSlates();
//       setSelectedForm(null);
//     } catch (error) {
//       console.error('Submission error:', error);
//     }
//   };

//   const formatDate = (dateString) => {
//     const date = new Date(dateString);
//     return date.toLocaleDateString('en-GB', {
//       day: '2-digit',
//       month: '2-digit',
//       year: 'numeric'
//     });
//   };

//   return (
//     <div className="your-slate-panel">
//     {/* <h1>Your Slates</h1> */}
//     <Typography variant="h4">Your Slates</Typography>
//     <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />
//     <Grid container spacing={2.5}>
//       <Grid item xs={0}>
//         <div className="elements-panel">
//           <List>
//             {slates && slates.map((form, index) => (
//               <ListItem className="form-element-button" key={index} onClick={() => handleFormSelect(form)}>
//                 <ListItemText primary={form.title} secondary={`Due: ${formatDate(form.due_date)}`} /> 
//               </ListItem>
//             ))}
//           </List>
//         </div>
//       </Grid>
//       <Grid item xs={9}>
//         {selectedForm ? (
//           <div className="form-preview" style={{ border: '2px solid black'}}>
//             <div className="form-element-container">
//               <Typography className="form-title" variant="h4" gutterBottom>
//                 {selectedForm.project} 
//               </Typography>
//               <Typography className="form-title" variant="h4" gutterBottom>
//                 {selectedForm.title} 
//               </Typography>
//               <Typography className="form-description" variant="subtitle1" gutterBottom>
//                 {selectedForm.description}
//               </Typography>
//               <FormProvider {...methods}>
//                 <form onSubmit={handleSubmit(onSubmit)}>
//                   {selectedForm.fields.map((element, index) => {
//                     if (element.field_type === 'description') {
//                       return (
//                         <div key={element.name} className="form-element">
//                           <Typography variant="body1" gutterBottom>
//                             {selectedForm.data.description}
//                           </Typography>
//                         </div>
//                       );
//                     } else {
//                       return (
//                         <div key={element.name} className="form-element">
//                           {renderFormElement(element, index)}
//                         </div>
//                       );
//                     }
//                   })}
//                   <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
//                     <Button type="submit" variant="contained" className="form-element-submit">
//                       Submit Slate
//                     </Button>
//                   </div>
//                 </form>
//               </FormProvider>
//             </div>
//           </div>
//         ) : (
//           <Typography variant="body1">Please select a slate from the list.</Typography>
//         )}
//       </Grid>
//       <Snackbar
//         open={showSuccessMessage}
//         autoHideDuration={3000}
//         onClose={handleCloseSuccessMessage}
//         message="Form Submitted Successfully"
//         action={
//           <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
//             <CloseIcon fontSize="small" />
//           </IconButton>
//         }
//       />
//     </Grid>
//     </div>
//   );
// };

// export default YourForms;