import React, { useContext, useState } from 'react';
import { Button, Grid, Typography, Snackbar, Checkbox, FormControlLabel, IconButton, Switch, Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { DeleteAssignedSlate } from "../components/Backend Routes/GeneralDelete"; // Backend API functions
import { EditAssignedSlate  } from '../components/Backend Routes/EditAssignedSlate';
import { SlatesFetch } from '../components/Backend Routes/ProjectSlatesFetch';
import { UserContext } from "../components/UserContext";
import EditAssignedPopup from '../components/Project Components/EditAssignedPopup';
import TemplateSelectionDialog from './TemplateSelectionDialog';
import DownloadIcon from '@mui/icons-material/Download';
import SlateDownloadButton from '../components/Project Components/SlateDownloadButton';
import CloseIcon from '@mui/icons-material/Close';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import Tooltip from '@mui/material/Tooltip';
import '../App.css';

const ProjectDetails = () => {
  const { projectName } = useParams();
  const location = useLocation();
  const projectId = location.state?.projectId;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedSlateId, setSelectedSlateId] = useState(null);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showEditMessage, setShowEditMessage] = useState(false);
  const [selectedSlate, setSelectedSlate] = useState(null);
  const [openAssignedPopup, setOpenAssignedPopup] = useState(false);
  const { user } = useAuth0();
  const navigate = useNavigate();
  const delete_url = `${process.env.REACT_APP_API_BASE_URL}/delete-assigned-slate`;
  const url = `${process.env.REACT_APP_API_BASE_URL}/project-slates/`;
  const { isPremiumUser, premiumKey } = useContext(UserContext);
  const { slates, loading, error, refetchSlates } = SlatesFetch(
    url,
    projectId,
    premiumKey // replace with dynamic user email
  );

  console.log(`Project Name: ${decodeURIComponent(projectName)}, Project ID: ${projectId}`);

  const columns = [
    { field: 'title', headerName: 'Title', flex: 1, headerAlign: 'center'},
    { field: 'assignee', headerName: 'Assignee', flex: 1, headerAlign: 'center'},
    { field: 'due_date', headerName: 'Due Date', flex: 1, headerAlign: 'center'},
    { 
      field: 'status', 
      headerName: 'Status', 
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <Typography variant="body2" align="center" style={{ width: '100%' }} color={params.value ? "primary" : "secondary"}>
          {params.value ? 'Complete' : 'Active'}
        </Typography>
      ),
      cellClassName: 'status-cell'
    },
    {
      field: 'actions',
      headerName: 'Actions',
      headerAlign: 'center',
      flex: 1,
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit Slate">
            <IconButton onClick={() => handleEdit(params.row.id)}>✏️</IconButton>
          </Tooltip>
          <Tooltip title="Delete Slate">
            <IconButton onClick={() => handleDelete(params.row.id)}>🗑️</IconButton>
          </Tooltip>
          {params.row.status && (
            <SlateDownloadButton slateId={params.row.id} />
        )}
        </div>
      ),
    },
  ];


  // const rows = slates.map((slate, index) => ({
    // id: index,
  const rows = slates.map((slate) => ({
    id: slate.database_id,
    title: slate.title,
    assignee: slate.assignee,
    due_date: new Date(slate.due_date).toLocaleDateString(),
    status: slate.status
  }));

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;
  
  const handleImportSlates = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleImport = (selectedTemplates) => {
    console.log('Importing templates:', selectedTemplates);
    refetchSlates(); // Refetch slates to update the active section
  };

  // Implement the edit functionality for the assigned slate
  // const handleEdit = (id) => {
  //   const slate = slates[id];
  //   setSelectedSlate(slate);
  //   setOpenAssignedPopup(true);
  // };
  const handleEdit = (databaseId) => {
    const slate = slates.find(s => s.database_id === databaseId);
    setSelectedSlate(slate);
    setOpenAssignedPopup(true);
  };

  // Implement the functionality to delete the assigned slate
  // const handleDelete = (id) => {
  //   setSelectedSlateId(slates[id].database_id);
  //   setOpenConfirmationDialog(true);
  // };
  const handleDelete = (databaseId) => {
    setSelectedSlateId(databaseId);
    setOpenConfirmationDialog(true);
  };
  

  const handleConfirmDelete = async () => {
    if (confirmDelete) {
      try {
        // Call the DeleteSlate function and await the response
        const response = await DeleteAssignedSlate(delete_url, selectedSlateId);

        // Show success message
        setShowDeleteMessage(true);

        // Refetch the form data after successful deletion
        refetchSlates();

        // Close the confirmation dialog
        setOpenConfirmationDialog(false);
        setConfirmDelete(false);
      } catch (error) {
        console.error('Error deleting slate.', error);
        // Handle the error, e.g., show an error message
      }
    }
  };

  const handleDownload = (id) => {
    console.log(`Download project with id: ${id}`);
    // Implement the delete functionality
  };

  const returnProjects = () => {
    navigate('/projects');
  };

  const handleCloseSuccessMessage = () => {
    setShowDeleteMessage(false);
    setShowEditMessage(false);
  };

  return (
    <div className="projects-container">
      <Tooltip title="Back to projects">
        <IconButton onClick={() => returnProjects()}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <Typography variant="h4" style={{ marginBottom: '20px' }}>Project: {projectName}</Typography>
      <Button className="standard-button" variant="contained" onClick={handleImportSlates}>Assign Slates</Button>
      <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ marginTop: '20px' }}>
        <Grid item>
          <Typography variant="h5">Project Slates</Typography>
        </Grid>
      </Grid>
      <div style={{ marginTop: '20px', height: 400, width: '100%' }}>
        {rows.length > 0 ? (
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={5}
            rowsPerPageOptions={[5]}
            getRowClassName={() => 'table-row'}
            sx={{
              '& .MuiDataGrid-columnHeader': {
                backgroundColor: '#EFECE6',
                fontWeight: 'bold',
                textAlign: 'center',
                fontSize: '18px',
              },
            }}
          />
        ) : (
          <Typography variant="body1">Oh, you haven't assigned any slates on your project yet.</Typography>
        )}
      </div>
      <TemplateSelectionDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        onImport={handleImport}
        projectId={projectId} // Forwarding project name to the dialog
      />
         {/* Dialog for confirming the deletion of a project */}
         <Dialog open={openConfirmationDialog} onClose={() => setOpenConfirmationDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this assigned slate?</Typography>
          <FormControlLabel
          control={<Checkbox checked={confirmDelete} onChange={(e) => setConfirmDelete(e.target.checked)} />}
          label="Yes"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmationDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar for displaying success message */}
      <EditAssignedPopup
        open={openAssignedPopup}
        onClose={() => setOpenAssignedPopup(false)}
        slate={selectedSlate}
        onUpdate={refetchSlates}
      />
      <Snackbar
        open={showDeleteMessage}
        autoHideDuration={2000} //disappears after 3s
        onClose={handleCloseSuccessMessage}
        message="Deleted assigned slate"
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <Snackbar
        open={showEditMessage}
        autoHideDuration={2000} //disappears after 3s
        onClose={handleCloseSuccessMessage}
        message="Updated assigned slate"
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

export default ProjectDetails;


// import React, { useEffect, useState } from 'react';
// import { useParams } from 'react-router-dom';
// import { Button, Typography, TextField, IconButton, Grid, MenuItem } from '@mui/material';
// import { useAuth0 } from '@auth0/auth0-react';
// import SearchIcon from '@mui/icons-material/Search';
// import { SlatesFetch } from '../components/ProjectSlatesFetch';
// import TemplateSelectionDialog from './TemplateSelectionDialog';
// import '../App.css';

// const ProjectDetails = () => {
//   const { projectName } = useParams();
//   console.log(projectName)
//   const [dialogOpen, setDialogOpen] = useState(false);  
//   const { user } = useAuth0();
//   const { slates, loading, error, refetchSlates } = SlatesFetch(
//     `http://localhost:8000/project-slates/`,
//     projectName,
//     user.email // replace with dynamic user email
//   );
  
//   console.log("slates:", slates)

//   const handleImportSlates = () => {
//     setDialogOpen(true);
//   };

//   const handleDialogClose = () => {
//     setDialogOpen(false);
//   };

//   const handleImport = (selectedTemplates) => {
//     // Implement the functionality to import the selected templates into the project
//     console.log('Importing templates:', selectedTemplates);
//     refetchSlates(); // Refetch slates to update the active section
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error}</div>;

//   return (
//     <div className="projects-container">
//       <Typography variant="h4" style={{ marginBottom: '20px' }}>Project: {projectName}</Typography>
//       <Button variant="contained" onClick={handleImportSlates}>Import Slates</Button>
//       <Grid container spacing={2} alignItems="center" justifyContent="space-between" style={{ marginTop: '20px' }}>
//         <Grid item>
//           <Typography variant="h5">Project Slates</Typography>
//         </Grid>
//         <Grid item>
//           <TextField
//             select
//             variant="outlined"
//             size="small"
//             placeholder="Select category"
//             style={{ marginRight: '10px' }}
//           >
//             <MenuItem value="category1">Active</MenuItem>
//             <MenuItem value="category2">Completed</MenuItem>
//           </TextField>
//           <TextField
//             variant="outlined"
//             placeholder="Search your slates"
//             size="small"
//             InputProps={{
//               endAdornment: (
//                 <IconButton>
//                   <SearchIcon />
//                 </IconButton>
//               ),
//             }}
//           />
//         </Grid>
//       </Grid>
//       <div style={{ marginTop: '20px' }}>
//         <Typography variant="h6">Active</Typography>
//         {slates?.active?.length > 0 ? (
//           slates.active.map((slate, index) => (
//             <div key={index} className="slate-card">
//               <Typography variant="body1">{slate.title}</Typography>
//             </div>
//           ))
//         ) : (
//           <Typography variant="body1">Oh snap! You don't have any slates yet</Typography>
//         )}
//       </div>
//       <div style={{ marginTop: '20px' }}>
//         <Typography variant="h6">Completed Forms</Typography>
//         {slates?.completed?.length > 0 ? (
//           slates.completed.map((slate, index) => (
//             <div key={index} className="slate-card">
//               <Typography variant="body1">{slate.title}</Typography>
//             </div>
//           ))
//         ) : (
//           <Typography variant="body1">Oh snap! You don't have any completed forms yet</Typography>
//         )}
//       </div>
//       <TemplateSelectionDialog
//         open={dialogOpen}
//         onClose={handleDialogClose}
//         onImport={handleImport}
//         projectName={projectName} // Forwarding project name to the dialog
//       />
//     </div>
//   );
// };

// export default ProjectDetails;