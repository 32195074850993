// UpdateUsers.js
export const UpdateUsers = async (user_ids, updatedFields) => {
    const url = `${process.env.REACT_APP_API_BASE_URL}/team/update-users`;
  
    // Prepare the request body
    const requestBody = {
        user_ids: user_ids.map((user) => user.database_id),
        updated_fields: updatedFields,
    };
    console.log('requestBody: ', requestBody)
    try {
      const response = await fetch(`${url}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
    
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
  
      // Receive response from backend
      const responseData = await response.json();
      console.log('Update response:', responseData);
  
      // Return the response data
      return responseData;
    } catch (error) {
      console.error('Update error:', error);
      // Rethrow the error to be caught in the calling component
      throw error;
    }
  };