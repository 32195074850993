import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress
} from "@mui/material";

const CompanyDialog = ({ 
  open, 
  onClose, 
  onSubmit, 
  editedCompany,  // Changed from editedItem to match parent component
  onFieldChange,
  isSubmitting = false,
  isEdit = false  // Add this to determine if we're editing or adding
}) => {
  // Email validation
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // State for validation errors
  const [validationErrors, setValidationErrors] = useState({
    companyEmail: '',  // Changed to match the field name in parent
    companyTelephone: ''  // Changed to match the field name in parent
  });

  // Handle email change with validation
  const handleEmailChange = (value) => {
    const error = value && !validateEmail(value) ? 'Please enter a valid email address' : '';
    setValidationErrors(prev => ({ ...prev, companyEmail: error }));
    onFieldChange("companyEmail", value);
  };

  // Handle phone number change with validation
  const handlePhoneChange = (value) => {
    const numbersOnly = value.replace(/[^\d]/g, '');
    if (value !== numbersOnly) {
      setValidationErrors(prev => ({ 
        ...prev, 
        companyTelephone: 'Please enter numbers only' 
      }));
    } else {
      setValidationErrors(prev => ({ ...prev, companyTelephone: '' }));
    }
    onFieldChange("companyTelephone", numbersOnly);
  };

  // Handle form submission with validation
  const handleSubmit = () => {
    // Validate all fields before submission
    const emailError = editedCompany.companyEmail && !validateEmail(editedCompany.companyEmail) 
      ? 'Please enter a valid email address' 
      : '';
    
    setValidationErrors(prev => ({
      ...prev,
      companyEmail: emailError
    }));

    // Only submit if there are no validation errors
    if (!emailError && !validationErrors.companyTelephone) {
      onSubmit();
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      disableEscapeKeyDown={isSubmitting}
    >
      <DialogTitle>
        {isEdit ? "Edit Company Details" : "Add Company Details"}
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Company Name"
          value={editedCompany?.companyName || ""}
          onChange={(e) => onFieldChange("companyName", e.target.value)}
          fullWidth
          margin="normal"
          required
          disabled={isSubmitting}
        />
        <TextField
          label="Company Address"
          value={editedCompany?.companyAddress || ""}
          onChange={(e) => onFieldChange("companyAddress", e.target.value)}
          fullWidth
          margin="normal"
          required
          disabled={isSubmitting}
        />
        <TextField
          label="VAT Number"
          value={editedCompany?.companyVat || ""}
          onChange={(e) => onFieldChange("companyVat", e.target.value)}
          fullWidth
          margin="normal"
          required
          disabled={isSubmitting}
        />
        <TextField
          label="Email"
          value={editedCompany?.companyEmail || ""}
          onChange={(e) => handleEmailChange(e.target.value)}
          error={!!validationErrors.companyEmail}
          helperText={validationErrors.companyEmail}
          fullWidth
          margin="normal"
          required
          disabled={isSubmitting}
        />
        <TextField
          label="Telephone"
          value={editedCompany?.companyTelephone || ""}
          onChange={(e) => handlePhoneChange(e.target.value)}
          error={!!validationErrors.companyTelephone}
          helperText={validationErrors.companyTelephone}
          fullWidth
          margin="normal"
          required
          disabled={isSubmitting}
        />
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose} 
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button 
          onClick={handleSubmit} 
          color="primary" 
          disabled={
            isSubmitting || 
            !!validationErrors.companyEmail || 
            !!validationErrors.companyTelephone
          }
          sx={{ 
            minWidth: 80,
            position: 'relative'
          }}
        >
          {isSubmitting ? (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                left: '50%',
                marginLeft: '-12px'
              }}
            />
          ) : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyDialog;