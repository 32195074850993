// RadioButton.js
import React from 'react';
import { FormControl, Typography, RadioGroup, FormControlLabel, Radio } from '@mui/material';

const RadioButton = React.forwardRef(({ name, label, options, required, ...props }, ref) => {
  return (
    <div className="form-element">
      <FormControl component="fieldset" required={required}>
      <Typography variant="subtitle1" component="legend">
          {label}
        </Typography>
        <RadioGroup aria-label={label} name={name} ref={ref} {...props}>
          {options.map((option, index) => (
            <FormControlLabel key={index} value={option} control={<Radio />} label={option} />
          ))}
        </RadioGroup>
      </FormControl>
    </div>
  );
});

export default RadioButton;

// // RadioButton.js
// import React from 'react';

// const RadioButton = React.forwardRef(({ name, label, options, required, ...props }, ref) => {
//   return (
//     <div className="form-element">
//       <label>{label}</label>
//       {options.map((option, index) => (
//         <div key={index}>
//           <input
//             type="radio"
//             id={`${name}-${option}`}
//             name={name}
//             value={option}
//             ref={ref}
//             required={required}
//             {...props}
//           />
//           <label htmlFor={`${name}-${option}`}>{option}</label>
//         </div>
//       ))}
//     </div>
//   );
// });

// export default RadioButton;