// CreateForms.js
import React, { useState, useEffect, useContext } from 'react';
//Using material-ui for component formatting
import { Button, Snackbar, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
// importing react-hook-form for form management
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from "../components/UserContext";
import FormBuilder from '../components/Form Components/FormBuilder';
import '../App.css';

const CreateForms = () => {
  const { control, register, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm(); //importing all required react-hook-form components
  const { user } = useAuth0();
  //Defining the different variables used to define the form: title, description, setting the form as a template etc. 
  const [formBuilderElements, setFormBuilderElements] = React.useState([]);
  const { isPremiumUser, premiumKey } = useContext(UserContext);
  const [title, setTitle] = React.useState('');
  const [description, setDescription] = React.useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate()
  const url = `${process.env.REACT_APP_API_BASE_URL}/slates/create-slate/`

//useEffect called to reset the form values upon form submission
  useEffect(() => {
    if (formSubmitted) {
      setTitle('');
      setDescription('');
      setFormBuilderElements([]);
      reset();
      setFormSubmitted(false);
    }
  }, [formSubmitted]);

  //Function triggered on submit by the user
  const onSubmit = async (formData) => {
    console.log('onSubmit triggered');
    console.log('Form data:', formData);
    console.log('Title:', title);
    console.log('Description:', description);

    // Get the current date and time
    const currentDateTime = new Date().toISOString();


    // Defining the structure of the submission data from the form to the backend for form creation
    const submissionData = {
      title: title,
      description: description,
      owner_org: premiumKey,
      last_updated: currentDateTime,
      fields: [
        ...formBuilderElements.map((element) => {
          if (element.field_type === 'table') {
            // Update the field name for the table element
            return {
              name: element.name,
              field_type: element.field_type,
              label: element.label,
              required: false,
              columns: element.columns, // Include the columns array
            };
          } else {
            return element;
          }
        }),
      ],
      data: { ...formData},
      status: false, // setting a status of the form at creation as default to fault to indicate the form is 'raw' and yet to be filled in.
    };

    console.log('Submission Data:', submissionData);

    // Adjust fetch call to match your backend endpoint
    try {
        const response = await fetch(url, {
        // const response = await fetch('https://api.polyglotplus.com/create-slate/', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(submissionData),
        });
        console.log('Body:', JSON.stringify(submissionData)); 
        if (!response.ok) {
            throw new Error('Network response was not ok');
        }
        //Receive response from backend
        const responseData = await response.json();
        console.log('Submission response:', responseData);
        // Show success message
        setShowSuccessMessage(true)
        // Trigger form reset
        setFormSubmitted(true);
        //nanigate back to templates
        setTimeout(() => {
          setShowSuccessMessage(false);
          navigate('/slate-templates'); // Redirect to the temaplate route after 2 seconds
        }, 1000);
        
    } catch (error) {
        console.error('Submission error:', error); // Handle error
    }
  };

  //Functions that trigger variable setting upon the submit button selection 
  const handleTitleChange = (title) => {
    setTitle(title);
  };
  const handleDescriptionChange = (description) => {
    setDescription(description);
  };
  const handleFormBuilderSubmit = (elements) => {
    setFormBuilderElements(elements);
  };
  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };


  return (
    <div className="container">
      {/* React Hook Form handleSubmit wrapper around onSubmit function*/}
      <form onSubmit={handleSubmit(onSubmit)}>      
        <FormBuilder
          onTitleChange={handleTitleChange} // Pass the Title
          onDescriptionChange={handleDescriptionChange} // Pass the Description 
          onSubmit={handleFormBuilderSubmit} // Pass the form elements
          control={control}
          resetForm={formSubmitted}
          register={register}
          errors={errors}
        />
        <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
          <Button className="form-element-submit" type="submit" variant="primary">
            Create Slate
          </Button>
        </div>
      </form>

      {/* Snackbar for displaying success message */}
      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={1000} //disappears after 3s
        onClose={handleCloseSuccessMessage}
        message="Template Successfully Created"
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
}

export default CreateForms;