// Table.js
import React, { useState, useEffect } from 'react';
import { TextField, Button } from '@mui/material';
import DatePicker from './DatePicker';
import NumberField from './NumberField';
import DecimalField from './DecimalField';
import SignatureDialog from './SignatureDialog'; // Import the SignatureDialog component
import ColorDropdown from './ColourDropdown'; // Import the ColorDropdown component
import Description from './Description';
import PictureCapture from './PictureCapture'; // Import the PictureCapture component

const Table = React.forwardRef(({ name, label, required, control, register, columns, onUpdateColumns, onAddColumnClick }, ref) => {
  const [rows, setRows] = useState([{}]);
  const [tableColumns, setTableColumns] = useState(columns || []);
  const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
  const [selectedSignatureData, setSelectedSignatureData] = useState(null);
  const [openPictureDialog, setOpenPictureDialog] = useState(false);
  const [selectedPictureData, setSelectedPictureData] = useState(null);

  useEffect(() => {
    setTableColumns(columns || []);
  }, [columns]);

  const handleAddRow = () => {
    setRows([...rows, {}]);
  };
  
  const handlePictureClick = (rowIndex, columnName) => {
    setSelectedPictureData({ rowIndex, columnName });
    setOpenPictureDialog(true);
  };

  const handlePictureDialogClose = () => {
    setOpenPictureDialog(false);
    setSelectedPictureData(null);
  };

  const handlePictureCapture = (pictureData) => {
    const { rowIndex, columnName } = selectedPictureData;
    setRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[rowIndex][columnName] = pictureData;
      return newRows;
    });
    handlePictureDialogClose();
  };

  const handleSignoffClick = (rowIndex, columnName) => {
    setSelectedSignatureData({ rowIndex, columnName });
    setOpenSignatureDialog(true);
  };

  const handleSignatureDialogClose = () => {
    setOpenSignatureDialog(false);
    setSelectedSignatureData(null);
  };

  const handleSignatureDialogSubmit = (signatureData) => {
    const { rowIndex, columnName } = selectedSignatureData;
    // Save the signature data to the corresponding row and column
    setRows((prevRows) => {
      const newRows = [...prevRows];
      newRows[rowIndex][columnName] = signatureData;
      return newRows;
    });
    handleSignatureDialogClose();
  };

  const handleAddColumn = (newColumn) => {
    setTableColumns((prevColumns) => [...prevColumns, newColumn]);
    onUpdateColumns([...tableColumns, newColumn]);
  };

  const handleDeleteColumnClick = () => {
    if (tableColumns.length > 0) {
      const updatedColumns = tableColumns.slice(0, -1);
      setTableColumns(updatedColumns);
      onUpdateColumns(updatedColumns);
    }
  };

  const renderField = (column, rowIndex) => {
    // const fieldName = `${String(name)}-${String(rowIndex)}-${String(column.name)}`; // Convert values to strings
    const fieldName = `${name}.${rowIndex}.${column.name}`;
    console.log("Registering field: ", fieldName, "Type: ", column.dataType);
    switch (column.dataType) {
      case 'colour':
        return (
          <ColorDropdown
            name={fieldName}
            inputRef={register} //register function from react-hook-form, registers the input into the form context.
            value={rows[rowIndex][column.name] || 'green'} // Set default value to 'green'
            onChange={(value) => { //This snippet updates the state of rows when a new colour is selected
              setRows((prevRows) => {
                const newRows = [...prevRows];
                newRows[rowIndex][column.name] = value;
                return newRows;
              });
            }}
          />
        );
      case 'text':
        // return <TextField name={fieldName} inputRef={register} fullWidth/>;
        return <TextField name={fieldName} control={control} fullWidth/>;
      case 'number':
        return (
          <NumberField
            name={fieldName}
            control={control}
            fullWidth
          />
        );
      case 'description':
        return (
          <Description
            name={fieldName}
            // label={column.label}
            control={control}
            register={register}
            required={column.required}
            fullWidth
          />
        );
      case 'decimal':
        return (
          <DecimalField
            name={fieldName}
            control={control}
            fullWidth
          />
        );
      case 'date':
        return (
          <DatePicker
            name={fieldName}
            control={control}
            fullWidth
          />
        );
      case 'signature':
        return (
          <Button
            variant="contained"
            onClick={() => handleSignoffClick(rowIndex, column.name)}
            fullWidth
            style={{
              backgroundColor: 'red',
            }}
          >
            Sign
          </Button>
        );
      case 'picture':
        const pictureData = rows[rowIndex][column.name];
        const isPictureTaken = pictureData !== undefined;
        return (
          <Button
            variant="contained"
            onClick={() => handlePictureClick(rowIndex, column.name)}
            fullWidth
            style={{ backgroundColor: isPictureTaken ? 'green' : 'red', color: 'white' }}
          >
            {isPictureTaken ? 'Picture Taken' : 'Take Picture'}
          </Button>
        );
      default:
        return null;
    }
    };

  return (
    <div className="form-element">
      {/* Add Column callss the AddColumnClick Function which comes as a prop from the FormBuilder component */}
      <label>{label}</label>
      <hr style={{ borderTop: '2px solid black' }} />
      <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
        <colgroup>
          {columns && columns.map((_, colIndex) => (
            <col key={colIndex} style={{ width: `${100 / columns.length}%` }} />
          ))}
        </colgroup>
        <thead>
          <tr>
            {columns && columns.map((column, colIndex) => (
              <th
                key={colIndex}
                className="table-header"
                style={{
                  textAlign: 'left',
                  padding: '8px',
                  border: '1px solid black',
                  outline: '1px solid black',
                }}
              >
                {column.label}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {rows.map((_, rowIndex) => (
            <tr key={rowIndex}>
              {columns && columns.map((column, colIndex) => (
                <td
                key={colIndex}
                style={{
                  padding: '8px',
                  border: '1px solid black',
                  outline: '1px solid black',
                }}
              >
                {renderField(column, rowIndex)}
              </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
      <Button color="inherit" onClick={handleAddRow} disabled={!tableColumns || tableColumns.length === 0}>
        Add Row
      </Button>
      <Button color="inherit" onClick={onAddColumnClick} disabled={columns && columns.length >= 10}> 
         Add Column
      </Button>
      <Button color="inherit" onClick={handleDeleteColumnClick} disabled={!tableColumns || tableColumns.length === 0}>
        Delete Column
      </Button>
      <SignatureDialog
        open={openSignatureDialog}
        onClose={handleSignatureDialogClose}
        onSubmit={handleSignatureDialogSubmit}
      />
      <PictureCapture
        open={openPictureDialog}
        onClose={handlePictureDialogClose}
        onCapture={handlePictureCapture}
      />
    </div>
  );
});

export default Table;

// // Table.js
// import React, { useState } from 'react';
// import { TextField, Button } from '@mui/material';
// import DatePicker from './DatePicker';
// import NumberField from './NumberField';
// import DecimalField from './DecimalField';
// import SignatureDialog from './SignatureDialog'; // Import the SignatureDialog component
// import ColorDropdown from './ColourDropdown'; // Import the ColorDropdown component
// import Description from './Description';

// const Table = React.forwardRef(({ name, label, required, control, register, columns, onAddColumnClick }, ref) => {
//   const [rows, setRows] = useState([{}]);
//   const [openSignatureDialog, setOpenSignatureDialog] = useState(false);
//   const [selectedSignatureData, setSelectedSignatureData] = useState(null);

//   const handleAddRow = () => {
//     setRows([...rows, {}]);
//   };
  
//   const handleSignoffClick = (rowIndex, columnName) => {
//     setSelectedSignatureData({ rowIndex, columnName });
//     setOpenSignatureDialog(true);
//   };

//   const handleSignatureDialogClose = () => {
//     setOpenSignatureDialog(false);
//     setSelectedSignatureData(null);
//   };

//   const handleSignatureDialogSubmit = (signatureData) => {
//     const { rowIndex, columnName } = selectedSignatureData;
//     // Save the signature data to the corresponding row and column
//     setRows((prevRows) => {
//       const newRows = [...prevRows];
//       newRows[rowIndex][columnName] = signatureData;
//       return newRows;
//     });
//     handleSignatureDialogClose();
//   };

//   const handleDeleteColumnClick = () => {
//     if (columns.length > 0) {
//       const updatedColumns = columns.slice(0, -1);
//       onAddColumnClick(updatedColumns);
//     }
//   };

//   const renderField = (column, rowIndex) => {
//     // const fieldName = `${String(name)}-${String(rowIndex)}-${String(column.name)}`; // Convert values to strings
//     const fieldName = `${name}.${rowIndex}.${column.name}`;
//     console.log("Registering field: ", fieldName, "Type: ", column.dataType);
//     switch (column.dataType) {
//       case 'colour':
//         return (
//           <ColorDropdown
//             name={fieldName}
//             inputRef={register} //register function from react-hook-form, registers the input into the form context.
//             value={rows[rowIndex][column.name] || 'green'} // Set default value to 'green'
//             onChange={(value) => { //This snippet updates the state of rows when a new colour is selected
//               setRows((prevRows) => {
//                 const newRows = [...prevRows];
//                 newRows[rowIndex][column.name] = value;
//                 return newRows;
//               });
//             }}
//           />
//         );
//       case 'text':
//         // return <TextField name={fieldName} inputRef={register} fullWidth/>;
//         return <TextField name={fieldName} control={control} fullWidth/>;
//       case 'number':
//         return (
//           <NumberField
//             name={fieldName}
//             control={control}
//             fullWidth
//           />
//         );
//       case 'description':
//         return (
//           <Description
//             name={fieldName}
//             // label={column.label}
//             control={control}
//             register={register}
//             required={column.required}
//             fullWidth
//           />
//         );
//       case 'decimal':
//         return (
//           <DecimalField
//             name={fieldName}
//             control={control}
//             fullWidth
//           />
//         );
//       case 'date':
//         return (
//           <DatePicker
//             name={fieldName}
//             control={control}
//             fullWidth
//           />
//         );
//       case 'signature':
//         return (
//           <Button
//             variant="contained"
//             onClick={() => handleSignoffClick(rowIndex, column.name)}
//             fullWidth
//             style={{
//               backgroundColor: 'red',
//             }}
//           >
//             Sign
//           </Button>
//         );
//       default:
//         return null;
//     }
//     };

//   return (
//     <div className="form-element">
//       {/* Add Column callss the AddColumnClick Function which comes as a prop from the FormBuilder component */}
//       <label>{label}</label>
//       <hr style={{ borderTop: '2px solid black' }} />
//       <table style={{ width: '100%', borderCollapse: 'collapse', tableLayout: 'fixed' }}>
//         <colgroup>
//           {columns && columns.map((_, colIndex) => (
//             <col key={colIndex} style={{ width: `${100 / columns.length}%` }} />
//           ))}
//         </colgroup>
//         <thead>
//           <tr>
//             {columns && columns.map((column, colIndex) => (
//               <th
//                 key={colIndex}
//                 className="table-header"
//                 style={{
//                   textAlign: 'left',
//                   padding: '8px',
//                   border: '1px solid black',
//                   outline: '1px solid black',
//                 }}
//               >
//                 {column.label}
//               </th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {rows.map((_, rowIndex) => (
//             <tr key={rowIndex}>
//               {columns && columns.map((column, colIndex) => (
//                 <td
//                 key={colIndex}
//                 style={{
//                   padding: '8px',
//                   border: '1px solid black',
//                   outline: '1px solid black',
//                 }}
//               >
//                 {renderField(column, rowIndex)}
//               </td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </table>
//       <Button color="inherit" onClick={handleAddRow} disabled={!columns || columns.length === 0}>Add Row</Button>
//       <Button color="inherit" onClick={onAddColumnClick} disabled={columns && columns.length >= 10}> 
//         Add Column
//       </Button>
//       <Button color="inherit" onClick={handleDeleteColumnClick} disabled={!columns || columns.length === 0}>
//         Delete Column
//       </Button>
//       <SignatureDialog
//         open={openSignatureDialog}
//         onClose={handleSignatureDialogClose}
//         onSubmit={handleSignatureDialogSubmit}
//       />
//     </div>
//   );
// });

// export default Table;