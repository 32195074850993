import React, { useState, useContext } from 'react';
import { Button, Typography, Snackbar, Checkbox, FormControlLabel, IconButton, Switch, Tooltip, Dialog, DialogTitle, DialogContent, DialogActions, } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ArchiveIcon from '@mui/icons-material/Archive';
import { useAuth0 } from '@auth0/auth0-react';
import { DeleteProject } from "../components/Backend Routes/GeneralDelete"; // Backend API functions
import { ArchiveProject } from '../components/Backend Routes/ArchiveProject';
import { ProjectFetch } from '../components/Project Components/ProjectFetch';
import { DataGrid } from '@mui/x-data-grid';
import { UserContext } from "../components/UserContext";
import EditProjectPopup from '../components/Project Components/EditProjectPopup';
import InfoIcon from '@mui/icons-material/Info';
import '../App.css';

const Projects = () => {
  const navigate = useNavigate();
  const [projectName, setprojectName] = useState('');
  const { user } = useAuth0();
  const [selectedProjectId, setSelectedProjectId] = useState(null);
  const [selectedProjectName, setSelectedProjectName] = useState(null);
  const [selectedProjectOwner, setSelectedProjectOwner] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [showDeleteMessage, setShowDeleteMessage] = useState(false);
  const [showArchivedMessage, setShowArchivedMessage] = useState(false);
  const [showEditMessage, setShowEditMessage] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmArchive, setConfirmArchive] = useState(false);
  const delete_url = `${process.env.REACT_APP_API_BASE_URL}/projects/delete-project`;
  const archive_url = `${process.env.REACT_APP_API_BASE_URL}/projects/archive-project`;
  const url = `${process.env.REACT_APP_API_BASE_URL}/projects/`;
  const [openArchiveConfirmationDialog, setOpenArchiveConfirmationDialog] = useState(false);
  const [selectedProject, setSelectedProject] = useState(null);
  const [openEditPopup, setOpenEditPopup] = useState(false);
  const { isPremiumUser, premiumKey } = useContext(UserContext);
  const { projects, loading, error, refetchProjects } = ProjectFetch(
    url,
    premiumKey // replace with dynamic user email
  );
  const [showArchived, setShowArchived] = useState(false);
  console.log('projects:', projects)

  const handleCreateProject = () => {
    navigate('/create-project');
  };


  // Then, update the handleRowClick function:
const handleRowClick = (params) => {
  const projectId = params.id; // This is now the unique database_id
  const projectName = params.row.name;
  
  console.log(`Navigating to project: ${projectName} with ID: ${projectId}`);
  navigate(`/projects/${encodeURIComponent(projectName)}`, { state: { projectId: projectId } });
};


  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  const columns = [
    { field: 'name', headerName: 'Project', flex: 1.5, width: 250, headerAlign: 'center'},
    { field: 'type', headerName: 'Project Type', flex: 1, width: 150, headerAlign: 'center' },
    { field: 'health', headerName: 'Project Health', flex: 1, width: 150, headerAlign: 'center'},
    { field: 'status', headerName: 'Status', flex: 1, width: 150, headerAlign: 'center' },
    { field: 'lead', headerName: 'Project Lead', flex: 1.25, width: 200, headerAlign: 'center' },
    { field: 'estimatedCompletion', headerName: 'Estimated Completion', flex: 1.25, width: 200, headerAlign: 'center' },
    { field: 'dateCompleted', headerName: 'Date Completed', flex: 1.25, width: 200, headerAlign: 'center' },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      renderCell: (params) => (
        <div>
          <Tooltip title="Edit Project">
            <IconButton onClick={() => handleEdit(params.row.id)}>✏️</IconButton>
          </Tooltip>
          <Tooltip title="Delete Project">
            <IconButton onClick={() => handleDelete(params.row.id)}>🗑️</IconButton>
          </Tooltip>
          <Tooltip title="Archive Project">
              <IconButton onClick={() => handleArchive(params.row.id)}>
                <ArchiveIcon/>
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];


const rows = projects.filter((project) => showArchived || project.status !== 'Archived')
  .map((project) => ({
    id: project.projectId, // Use the actual unique identifier from your data
    name: project.projectName,
    type: project.projectType,
    health: "70%",
    status: project.status,
    lead: project.projectLead,
    estimatedCompletion: new Date(project.estimated_date).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }),
    dateCompleted: project.completion_date ? new Date(project.completion_date).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    }) : "TBD",
  }));


// Function to handle the editing of a project info
  const handleEdit = (projectId) => {
    const projectToEdit = projects.find(project => project.projectId === projectId);
    console.log(`Project to edit is ${projectToEdit}`);
    if (projectToEdit) {
      setSelectedProject(projectToEdit);
      setOpenEditPopup(true);
    } else {
      console.error(`Project with id ${projectId} not found`);
      setOpenEditPopup(true);
    }
  };

  // Function to handle the archiving of a project
  const handleArchive = (id) => {
    setSelectedProjectId(projects[id].database_id);
    setOpenArchiveConfirmationDialog(true);
  };
  
// Function to handle the deleting of a project
  // const handleDelete = (id) => {
  //   setSelectedProjectId(projects[id].database_id);
  //   setSelectedProjectName(projects[id].projectName);
  //   setSelectedProjectOwner(projects[id].owner);
  //   setOpenConfirmationDialog(true);
  // };
  const handleDelete = (projectId) => {
    const projectToDelete = projects.find(project => project.projectId === projectId);
    if (projectToDelete) {
      setSelectedProjectId(projectId);
      setSelectedProjectName(projectToDelete.projectName);
      setSelectedProjectOwner(projectToDelete.owner);
      setOpenConfirmationDialog(true);
    } else {
      console.error(`Project with id ${projectId} not found`);
    }
  };

  const handleArchiveConfirmation = async () => {
    try {
      // Send a request to the backend to change the project status to "archived"
      const response = await ArchiveProject(archive_url, selectedProjectId);

      // Show success message
      setShowArchivedMessage(true);

      // Refetch the projects after successful archival
      refetchProjects();

      // Close the archive confirmation dialog
      setOpenArchiveConfirmationDialog(false);
      setConfirmArchive(false);
    } catch (error) {
      console.error('Error archiving project:', error);
      // Handle the error, e.g., show an error message
    }
  };

  const handleConfirmDelete = async () => {
    if (confirmDelete) {
      try {
        // Call the DeleteSlate function and await the response
        const response = await DeleteProject(delete_url, selectedProjectId, selectedProjectName, selectedProjectOwner);

        // Show success message
        setShowDeleteMessage(true);

        // Refetch the form data after successful deletion
        refetchProjects();

        // Close the confirmation dialog
        setOpenConfirmationDialog(false);
        setConfirmDelete(false);
        setSelectedProjectId(null);
        setSelectedProjectName(null);
        setSelectedProjectOwner(null);
      } catch (error) {
        console.error('Error deleting project:', error);
        // Handle the error, e.g., show an error message
      }
    }
  };

  const toggleArchived = () => {
    setShowArchived(!showArchived);
    // Implement fetching of archived projects if necessary
  };

  const handleCloseSuccessMessage = () => {
    setShowDeleteMessage(false);
    setShowArchivedMessage(false);
    setShowEditMessage(false);
  };

  return (
    <div className="projects-container">
      <Typography variant="h4">Your Projects</Typography>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />
      {projects.length === 0 ? (
        <div className="no-projects">
          <Typography variant="h6">Oh snap! You don't have any projects yet</Typography>
          <Button className="standard-button" variant="contained" startIcon={<AddIcon />} onClick={handleCreateProject}>
            New Project
          </Button>
        </div>
      ) : (
        <div>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Button className="standard-button" variant="contained" startIcon={<AddIcon />} onClick={handleCreateProject}>
              New Project
            </Button>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Tooltip title="Double click on the row for project specific view">
                <InfoIcon />
              </Tooltip>
              <Typography variant="body1" style={{ marginLeft: '20px' }}>Toggle archive</Typography>
              <Switch checked={showArchived} onChange={toggleArchived} />
            </div>
          </div>
          <div style={{ height: 400, width: '100%', marginTop: '20px'}}>
            <DataGrid
              rows={rows}
              columns={columns}
              pageSize={5}
              rowsPerPageOptions={[5]}
              onRowDoubleClick={handleRowClick}
              sx={{
                '& .MuiDataGrid-columnHeader': {
                  backgroundColor: '#EFECE6',
                  textAlign: 'center',
                  fontSize: '18px',
                },
              }}
            />
          </div>
        </div>
      )}
      {/* Dialog for confirming the archviing of a project */}
      <Dialog open={openArchiveConfirmationDialog} onClose={() => setOpenArchiveConfirmationDialog(false)}>
        <DialogTitle>Confirm Archival</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you wish to archive the project?</Typography>
          <FormControlLabel
            control={<Checkbox checked={confirmArchive} onChange={(e) => setConfirmArchive(e.target.checked)} />}
            label="Yes"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenArchiveConfirmationDialog(false)}>Cancel</Button>
          <Button onClick={handleArchiveConfirmation} color="primary">Archive</Button>
        </DialogActions>
      </Dialog>
      {/* Popup for editing project details*/}
      <EditProjectPopup
        open={openEditPopup}
        onClose={() => setOpenEditPopup(false)}
        project={selectedProject}
        onUpdate={refetchProjects}
        org_owner={premiumKey}
      />
      {/* Dialog for confirming the deletion of a project */}
      <Dialog open={openConfirmationDialog} onClose={() => setOpenConfirmationDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this project?</Typography>
          <FormControlLabel
          control={<Checkbox checked={confirmDelete} onChange={(e) => setConfirmDelete(e.target.checked)} />}
          label="Yes"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmationDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar for displaying success message */}
      <Snackbar
        open={showDeleteMessage}
        autoHideDuration={2000} //disappears after 3s
        onClose={handleCloseSuccessMessage}
        message="Project Deleted"
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <Snackbar
        open={showArchivedMessage}
        autoHideDuration={2000} //disappears after 3s
        onClose={handleCloseSuccessMessage}
        message="Project Archived"
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
      <Snackbar
        open={showEditMessage}
        autoHideDuration={2000} //disappears after 3s
        onClose={handleCloseSuccessMessage}
        message="Project Details Updated"
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

export default Projects;


// // Projects.js
// import React, {useState} from 'react';
// import { Button, Typography, Grid, TextField, IconButton, Switch } from '@mui/material';
// import { useNavigate } from 'react-router-dom';
// import AddIcon from '@mui/icons-material/Add';
// import SearchIcon from '@mui/icons-material/Search';
// import { useAuth0 } from '@auth0/auth0-react';
// import { ProjectFetch } from '../components/ProjectFetch';
// import { DataGrid } from '@mui/x-data-grid';
// import '../App.css';

// const Projects = () => {
//   const navigate = useNavigate();
//   const { user } = useAuth0();
//   const { projects, loading, error, refetchProjects } = ProjectFetch(
//     'http://localhost:8000/Projects/',
//     user.email // replace with dynamic user email
//   );

//   const [showArchived, setShowArchived] = useState(false);

//   // Handle click for creating a new project
//   const handleCreateProject = () => {
//     navigate('/create-project');
//   };

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error}</div>;

//   const columns = [
//     { field: 'name', headerName: 'Project', width: 200 },
//     { field: 'type', headerName: 'Project Type', width: 150 },
//     { field: 'health', headerName: 'Project Health', width: 150 },
//     { field: 'status', headerName: 'Status', width: 150 },
//     { field: 'lead', headerName: 'Project Lead', width: 200 },
//     { field: 'estimatedCompletion', headerName: 'Estimated Completion', width: 200 },
//     { field: 'dateCompleted', headerName: 'Date Completed', width: 200 },
//     {
//       field: 'actions',
//       headerName: 'Actions',
//       width: 150,
//       renderCell: (params) => (
//         <div>
//           <IconButton onClick={() => handleEdit(params.row.id)}>✏️</IconButton>
//           <IconButton onClick={() => handleDelete(params.row.id)}>🗑️</IconButton>
//         </div>
//       ),
//     },
//   ];

//   const rows = projects.map((project, index) => ({
//     id: index,
//     name: project.projectName,
//     type: project.projectType,
//     health: "70%",
//     status: "TBD",
//     lead: project.projectLead,
//     estimatedCompletion: "TBD",
//     dateCompleted: "TBD",
//   }));

//   const handleEdit = (id) => {
//     console.log(`Edit project with id: ${id}`);
//     // Implement the edit functionality
//   };

//   const handleDelete = (id) => {
//     console.log(`Delete project with id: ${id}`);
//     // Implement the delete functionality
//   };

//   const toggleArchived = () => {
//     setShowArchived(!showArchived);
//     // Implement fetching of archived projects if necessary
//   };

//   return (
//     <div className="projects-container">
//       <Typography variant="h4">Your Projects</Typography>
//       <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />
//       <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//         <Button variant="contained" startIcon={<AddIcon />} onClick={handleCreateProject}>
//           New Project
//         </Button>
//         <div>
//           <TextField
//             variant="outlined"
//             placeholder="search projects"
//             size="small"
//             InputProps={{
//               endAdornment: (
//                 <IconButton>
//                   <SearchIcon />
//                 </IconButton>
//               ),
//             }}
//           />
//           <Typography variant="body1" style={{ display: 'inline', marginLeft: '20px' }}>Toggle archive</Typography>
//           <Switch checked={showArchived} onChange={toggleArchived} />
//         </div>
//       </div>
//       <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
//         <DataGrid rows={rows} columns={columns} pageSize={5} rowsPerPageOptions={[5]} />
//       </div>
//     </div>
//   );
// };

// export default Projects;
