// Description.js
import React from 'react';
import { TextField } from '@mui/material';

const Description = ({ name, label, control, register, required }) => {
  return (
    <div>
      <TextField
        name={name}
        label={label}
        variant="outlined"
        fullWidth
        multiline
        InputProps={{
          style: {
            overflow: 'auto',
          },
        }}
        {...register(name, { required })}
      />
    </div>
  );
};

export default Description;