// RenderSlatePreview.js
import React from 'react';
import { Controller } from 'react-hook-form';
import { Typography } from '@mui/material';
import TextInput from '../Form Components/TextInput';
import Checkbox from '../Form Components/Checkbox';
import DatePicker from '../Form Components/DatePicker';
import Signature from '../Form Components/Signature';
import RadioButton from '../Form Components/RadioButton';
import Table from '../Form Components/Table_Submit';
import NumberField from '../Form Components/NumberField';
import DecimalField from '../Form Components/DecimalField';
import Dropdown from '../Form Components/Dropdown';
import FileUpload from '../Form Components/FileUpload';
import SectionBreak from '../Form Components/SectionBreak';
import RequiredAsterisk from '../Form Components/RequiredAsterisk';
import PictureCapture from '../Form Components/PictureCapture'; // Import the PictureCapture component

const RenderSlatePreview = (element, index, control, register, data, initialRows) => {
  const isRequired = element.required;

  const renderLabel = (label) => (
    <>
      {label}
      {isRequired && <RequiredAsterisk />}
    </>
  );

  switch (element.field_type) {
    case 'email':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          rules={{
            required: "Please enter your email",
            pattern: {
              value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
              message: "Please enter a valid email"
            }
          }}
          render={({ field }) => (
            <TextInput 
              {...field} 
              type="email"
              label={element.label} 
              placeholder="Enter the assignee email" 
            />
          )}
        />
      );
    case 'text':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          rules={{ required: element.required }}
          render={({ field }) => <TextInput {...field} label={renderLabel(element.label)} />} 
        />
      );
    case 'checkbox':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue={[]}
          rules={{ required: element.required }}
          render={({ field: { onChange, value, ...field } }) => (
            <Checkbox
              {...field}
              label={renderLabel(element.label)}
              options={element.options || []}
              onChange={onChange}
              required={element.required}
              value={value}
            />
          )}
        />
      );
    case 'section_break':
      return (
        <SectionBreak
          name={element.name}
          label={element.label}
          control={control}
          register={register}
          required={element.required}
        />
      );
    case 'description':
      return (
        <div key={element.name} className="form-element">
          <Typography variant="body1" gutterBottom>
            {data[element.name]}
          </Typography>
        </div>
      );
    case 'date':
      return (
        <Controller 
          name={element.name}
          control={control}
          defaultValue=""
          rules={{ required: element.required }}
          render={({ field }) => <DatePicker {...field} label={renderLabel(element.label)} />}
        />
      );
    case 'signature':
      return (
        <Controller
          key={index}
          name={element.name}
          control={control}
          render={({ field }) => (
            <Signature
              {...field}
              label={element.label}
              required={element.required}
            />
          )}
        />
      );
    case 'radio':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          rules={{ required: element.required }}
          render={({ field }) => (
            <RadioButton {...field} label={renderLabel(element.label)} options={element.options || []} />
          )}
        />
      );
    case 'number':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          rules={{ required: element.required }}
          render={({ field }) => <NumberField {...field} label={renderLabel(element.label)} />}
        />
      );
    case 'decimal':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          rules={{ required: element.required }}
          render={({ field }) => <DecimalField {...field} label={renderLabel(element.label)} />}
        />
      );
    case 'table':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          rules={{ required: element.required }}
          render={({ field }) => (
            <Table
              {...field}
              name={element.name.toString()} // Pass the name as a string
              label={renderLabel(element.label)}
              columns={element.columns} // Pass the columns data to the Table component
              control={control}
              register={register}
              data={data[element.name]} // Pass the table data from the form data
              initialRows={initialRows} // Pass the initialRows prop to the Table 
            />
          )}
        />
      );
    case 'dropdown':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          rules={{ required: element.required }}
          render={({ field }) => (
            <Dropdown {...field} label={renderLabel(element.label)} options={element.options || []} />
          )}
        />
      );
    case 'file':
      return (
        <Controller
          name={element.name}
          control={control}
          defaultValue=""
          rules={{ required: element.required }}
          render={({ field }) => <FileUpload {...field} label={renderLabel(element.label)} />}
        />
      );
    default:
      return null;
  }
};

export default RenderSlatePreview;