// // We import the TextField component from @mui/material to create the input fields for the title and description.
// // The component receives title, description, onTitleChange, and onDescriptionChange as props. These props will be used to manage the state of the title and description fields in the parent component.
// // The TextField component is used to render the input fields for the title and description. The label prop sets the label for each field, and the value prop binds the field values to the corresponding state variables in the parent component.
// // The onChange prop is used to handle changes in the title and description fields. It will call the respective change handlers passed from the parent component.
// // The fullWidth prop is set to true to make the fields take up the full width of their container.
// // The required prop is set to true for the title field to indicate that it is a mandatory field.
// // The multiline prop is set to true for the description field to allow multiple lines of text input.
// // The rows prop is set to 3 for the description field to specify the number of visible text lines.

// TitleDescription.js
import React from 'react';
import { TextField, Typography } from '@mui/material';
import { Controller } from 'react-hook-form';
import TextInput from './TextInput';
import DatePicker from './DatePicker';

const TitleDescription = ({ title, description, onTitleChange, onDescriptionChange, control }) => {

  const handleTitleChange = (event) => {
    onTitleChange(event.target.value);
  };

  const handleDescriptionChange = (event) => {
    onDescriptionChange(event.target.value);
  };

  return (
    <div className="form-element-title-description">
      {/* Title field */}
      <div style={{ marginBottom: '16px' }}>
        <Typography variant="subtitle1" >
          Slate Title
        </Typography>
        <TextField
          value={title}
          // onChange={onTitleChange}
          onChange={handleTitleChange}
          fullWidth
          required
          placeholder="Enter the form title"
        />
      </div>
      {/* Description field */}
      <div style={{ marginBottom: '16px' }}>
        <Typography variant="subtitle1" >
          Slate Description
        </Typography>
        <TextField
          value={description}
          // onChange={onDescriptionChange}
          onChange={handleDescriptionChange}
          fullWidth
          multiline
          rows={3}
          placeholder="Enter the form description"
        />
      </div>
      {/* Assignee Email field */}
      {/* <div style={{ marginBottom: '16px' }}>
        <Typography variant="subtitle1">
          Assignee Email
        </Typography>
        <Controller
          name="email"
          control={control}
          defaultValue=""
          rules={{
            required: "Please enter the assignee email",
            pattern: {
              value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
              message: "Please enter a valid email"
            }
          }}
          render={({ field }) => (
            <TextInput
              {...field}
              type="email"
              placeholder="Enter the assignee email"
            />
          )}
        />
      </div> */}
      {/* Due Date field */}
      {/* <div style={{ marginBottom: '16px' }}>
        <Typography variant="subtitle1">
          Due Date
        </Typography>
        <Controller
          name="due_date"
          control={control}
          defaultValue=""
          rules={{ required: "Please enter the due date" }}
          render={({ field }) => <DatePicker {...field} />}
        />
      </div> */}
    </div>
  );
};

export default TitleDescription;