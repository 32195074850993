import React from 'react';
import FadeInText from './FadeInText';
import AuditHub from '../images/AuditHub.png';

const AuditHubDashboard = () => {
  return (
    <section className="ahd-section">
      <div className="ahd-content">
        <h2 className="ahd-title">Audit Hub Dashboard</h2>
        <div className="ahd-dashboard-image">
          {/* Replace with your actual dashboard image */}
          <img src={AuditHub} alt="Compliance Dashboard" />
        </div>
        <div className="ahd-features">
          <div className="ahd-feature">
            <FadeInText>
              <h3>Visibility</h3>
              <p>Get a comprehensive view of all completed and pending slates, streamlining your project oversight.</p>
            </FadeInText>
            <div className="ahd-number">1</div>
          </div>
          <div className="ahd-feature">
            <FadeInText>
              <h3>Compliance</h3>
              <p>Ensure your team adheres to industry regulations and internal policies through the Audit Center.</p>
            </FadeInText>
            <div className="ahd-number">2</div>
          </div>
          <div className="ahd-feature">
            <FadeInText>
              <h3>Insights</h3>
              <p>Leverage data-driven analytics to identify areas for improvement and optimize your workflows.</p>
            </FadeInText>
            <div className="ahd-number">3</div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AuditHubDashboard;