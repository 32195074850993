// ProspectFetch.jsx
const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;

export const fetchCrmCompanies = async (premiumKey) => {
  try {
    const response = await fetch(`${API_BASE_URL}/customer/customer-list/?owner=${premiumKey}`);
    if (!response.ok) throw new Error('Failed to fetch CRM data');
    const data = await response.json();
    console.log("Raw CRM data:", data);

    if (!data || !Array.isArray(data.customers)) {
      console.error("Unexpected data structure:", data);
      throw new Error('Unexpected data structure from CRM API');
    }

    return data.customers;  // This is now an array of { companyId, name } objects
  } catch (err) {
    console.error("Error fetching CRM companies:", err);
    throw err;
  }
};

  export const fetchProspectData = async (premiumKey) => {
    try {
      const response = await fetch(`${API_BASE_URL}/prospect/merged-prospect-data/?owner=${premiumKey}`);
      if (!response.ok) throw new Error('Failed to fetch prospect data');
      const data = await response.json();
      console.log("Raw prospect data:", data);  // Log the raw data
  
      if (!data || !data.items) {
        console.error("Unexpected data structure:", data);
        throw new Error('Unexpected data structure from Prospect API');
      }
  
      return data.items.map((item, index) => ({ ...item, id: index }));
    } catch (err) {
      console.error("Error fetching prospect data:", err);
      throw err;
    }
  };

  export const fetchActiveProspectData = async (premiumKey) => {
    try {
      const response = await fetch(`${API_BASE_URL}/prospect/active-merged-prospect-data/?owner=${premiumKey}`);
      if (!response.ok) throw new Error('Failed to fetch prospect data');
      const data = await response.json();
      console.log("Raw prospect data:", data);  // Log the raw data
  
      if (!data || !data.items) {
        console.error("Unexpected data structure:", data);
        throw new Error('Unexpected data structure from Prospect API');
      }
  
      return data.items.map((item, index) => ({ ...item, id: index }));
    } catch (err) {
      console.error("Error fetching prospect data:", err);
      throw err;
    }
  };


export const updateProspectData = async (premiumKey, newData) => {
  try {
    const cleanedData = newData.map(({ id, ...item }) => ({
      companyId: item.companyId,
      projectName: item.projectName,
      site_address: item.site_address,
      status: item.status,
      projectId: item.projectId || "",
    }));
    
    const requestBody = {
      owner_org: premiumKey,
      items: cleanedData
    };

    console.log('newData', newData);
    console.log("Sending data to backend:", requestBody);

    const response = await fetch(`${API_BASE_URL}/prospect/prospect-details/?owner=${premiumKey}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(requestBody),
    });
    
    if (!response.ok) {
      const responseData = await response.json();
      let errorMessage = 'Failed to update prospect data';
      if (responseData.detail) {
        if (Array.isArray(responseData.detail)) {
          errorMessage += ': ' + responseData.detail.map(err => JSON.stringify(err)).join(', ');
        } else {
          errorMessage += ': ' + responseData.detail;
        }
      }
      throw new Error(errorMessage);
    }

    return await response.json();
  } catch (error) {
    console.error("Error updating prospect data:", error);
    throw error;
  }
};
