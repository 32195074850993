import React from 'react';
import { Link } from 'react-router-dom'; // Assuming you're using React Router for navigation

// FAQ component
const FAQ = () => {
  // FAQ data
  const faqData = [
    {
      question: "How does the free trial work?",
      answer: "You get full access to Site Steer for one month, limited to a team of 10. After the trial, purchase licenses based on the number of users."
    },
    {
      question: "How does Site Steer work?",
      answer: "Visit our \"Get Started\" section and watch our intro video for a quick overview.",
      link: "/get-started" // Replace with actual link
    },
    {
      question: "Do you have a Site Steer mobile app?",
      answer: "Currently, we don't have a mobile app. Use the website for field work. A native app is our top priority—stay tuned!"
    },
    {
      question: "How does the subscription work?",
      answer: "Purchase licenses based on your team size and choose between monthly or annual billing."
    },
    {
      question: "Is my data safe?",
      answer: "Yes, we prioritize your data privacy and use top-tier security solutions. We comply with GDPR and will delete any personal data upon request. For more details, see our privacy policy.",
      link: "/privacy-notice" // Replace with actual link
    },
    {
      question: "Who is the Site Steer team?",
      answer: "Our co-founders have over 15 years of combined experience in construction. We're passionate about streamlining workflows so professionals can focus on building."
    }
  ];

  return (
    <section id="faq" className="faq-section">
        <div className="faq-section-content">
            <h2>Frequently asked questions</h2>
            <p className="faq-contact">
                Not finding what you need? Feel free to reach out to the team at{' '}
                <a href="mailto:sitesteer_ai@gmail.com">sitesteer.ai@gmail.com</a>
            </p>
            
            <div className="faq-grid">
                {faqData.map((item, index) => (
                <div key={index} className="faq-item">
                    <h3>{item.question}</h3>
                    <p>
                    {item.answer}
                    {item.link && (
                        <> <Link to={item.link}>[Learn more]</Link></>
                    )}
                    </p>
                </div>
                ))}
            </div>
        </div>
    </section>
  );
};

export default FAQ;