/**
   * Handle deleting a customer, prospect, quote and or invoice.
   * This function is called when the Archive icon is clicked.
   * It performs the archive operation and updates the local state accordingly.
   * @param {string} id - The unique identifier of the customer, prospect, quote and or invoice to delete.
   */
export const deleteEntry = async (premiumKey, id, stage, stageId) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/${stage}/delete/?owner=${premiumKey}&${stageId}=${id}`,
      {
        method: "POST",
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || `Failed to delete ${stage}`);
    }

    console.log(`${stage} with id ${id} delete.`);
    return true; // Indicate success
  } catch (error) {
    console.error(`Error deleting ${stage}:`, error);
    throw error;
  }
};


// DeleteUser.js
export const DeleteUsers = async (users) => {
  const url = `${process.env.REACT_APP_API_BASE_URL}/team/delete-users`;


  try {
    const response = await fetch(`${url}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify( users ),
    });
  
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Receive response from backend
    const responseData = await response.json();
    console.log('Deletion response:', responseData);

    // Return the response data
    return responseData;
  } catch (error) {
    console.error('Deletion error:', error);
    // Rethrow the error to be caught in the calling component
    throw error;
  }
};

// DeleteSlate.js
export const DeleteSlate = async (url, slate_id) => {
  try {
    const response = await fetch(`${url}/${slate_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Receive response from backend
    const responseData = await response.json();
    console.log('Deletion response:', responseData);

    // Return the response data
    return responseData;
  } catch (error) {
    console.error('Deletion error:', error);
    // Rethrow the error to be caught in the calling component
    throw error;
  }
};


// DeleteSlate.js
export const DeleteProject = async (url, project_id, projectName, projectOwner) => {
  try {
    const response = await fetch(`${url}?project_id=${project_id}&projectName=${projectName}&projectOwner=${projectOwner}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Receive response from backend
    const responseData = await response.json();
    console.log('Deletion response:', responseData);

    // Return the response data
    return responseData;
  } catch (error) {
    console.error('Deletion error:', error);
    // Rethrow the error to be caught in the calling component
    throw error;
  }
};

// DeleteAssignedSlate.js
export const DeleteAssignedSlate = async (url, slate_id) => {
  try {
    const response = await fetch(`${url}/${slate_id}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    // Receive response from backend
    const responseData = await response.json();
    console.log('Deletion response:', responseData);

    // Return the response data
    return responseData;
  } catch (error) {
    console.error('Deletion error:', error);
    // Rethrow the error to be caught in the calling component
    throw error;
  }
};