import React, { useState, useRef, useEffect } from 'react';
import { Typography, TextField, Button, Switch, FormControlLabel } from '@mui/material';
import ReactSignatureCanvas from 'react-signature-canvas';

const Signature = React.forwardRef(({ name, label, required, control, setValue, getValues, ...props }, ref) => {
  const [isTypedSignature, setIsTypedSignature] = useState(true);
  const signatureCanvasRef = useRef(null);

  const handleToggle = () => {
    setIsTypedSignature(!isTypedSignature);
  };

  const clearSignature = () => {
    if (signatureCanvasRef.current) {
      signatureCanvasRef.current.clear();
      setValue(name, "");  // Clear the value in the form
    }
  };

  const saveSignature = () => {
    if (signatureCanvasRef.current) {
      const signatureData = signatureCanvasRef.current.getTrimmedCanvas().toDataURL('image/png');
      setValue(name, signatureData);
    }
  };

  useEffect(() => {
    if (!isTypedSignature) {
      saveSignature();  // Save the drawn signature on toggle
    }
  }, [isTypedSignature]);

  return (
    <div className="form-element-signature">
      <Typography variant="subtitle1">{label}</Typography>
      <FormControlLabel
        control={
          <Switch
            checked={isTypedSignature}
            onChange={handleToggle}
            color="primary"
          />
        }
        label={isTypedSignature ? "Typed Signature" : "Drawn Signature"}
      />
      {isTypedSignature ? (
        <TextField
          name={name}
          inputRef={ref}
          placeholder="Type your signature"
          fullWidth
          required={required}
          inputProps={{
            sx: {
              fontStyle: "italic"
            },
          }}
          {...props}
        />
      ) : (
        <div style={{ border: '1px solid black', borderRadius: '5px' }}>
          <ReactSignatureCanvas
            ref={signatureCanvasRef}
            canvasProps={{ width: 500, height: 200, className: 'sigCanvas' }}
            onEnd={saveSignature}
          />
          <Button onClick={clearSignature} variant="outlined" color="secondary" style={{ marginTop: '10px' }}>
            Clear
          </Button>
        </div>
      )}
    </div>
  );
});

export default Signature;



// /////// Typed only signature ////////////
// import React from 'react';
// import { Typography, TextField } from '@mui/material';

// const Signature = React.forwardRef(({ name, label, required, register, ...props }, ref) => {
//   return (
//     <div className="form-element-signature">
//       <Typography variant="subtitle1">{label}</Typography>
//       {/* Render typed signature input field */}
//       <TextField
//         name={name}
//         inputRef={register}
//         placeholder="Type your signature"
//         fullWidth
//         required={required}
//         inputProps={{
//           sx: {
//             fontStyle: "italic"
//           },
//         }}
//         {...props}
//       />
//     </div>
//   );
// });

// export default Signature;

// import React, { useRef, useState } from 'react';
// import SignaturePad from 'react-signature-canvas';
// import { Button, Typography, TextField, FormControlLabel, Radio, RadioGroup } from '@mui/material';

// const Signature = React.forwardRef(({ name, label, required, register, ...props }, ref) => {
//   const [signatureType, setSignatureType] = useState('typed'); // State to manage signature type
//   const [typedSignature, setTypedSignature] = useState(''); // State to store typed signature value
//   const sigPad = useRef({}); // Ref to store the SignaturePad instance

//   // Function to clear the signature
//   const clear = () => {
//     if (sigPad.current && sigPad.current.clear) {
//       sigPad.current.clear();
//       setTypedSignature(''); // Clear the typed signature value when the pad is cleared
//     }
//   };

//   // Function to handle signature type change
//   const handleSignatureTypeChange = (event) => {
//     setSignatureType(event.target.value);
//     if (event.target.value === 'drawn' && sigPad.current && sigPad.current.clear) {
//       clear(); // Clear the signature when switching to drawn type
//     } else {
//       setTypedSignature(''); // Clear the typed signature value when switching to drawn type
//     }
//   };

//   // Function to handle typed signature change
//   const handleTypedSignatureChange = (event) => {
//     setTypedSignature(event.target.value);
//   };

//   return (
//     <div className="form-element-signature">
//       <Typography variant="subtitle1">{label}</Typography>
//       {/* Radio buttons to select signature type */}
//       <RadioGroup row value={signatureType} onChange={handleSignatureTypeChange}>
//         <FormControlLabel value="typed" control={<Radio />} label="Typed" />
//         <FormControlLabel value="drawn" control={<Radio />} label="Drawn" />
//       </RadioGroup>
//       {signatureType === 'typed' ? (
//         // Render typed signature input field
//         <TextField
//         name={name}
//         inputRef={register}
//         placeholder="Type your signature"
//         fullWidth
//         required={required}
//         inputProps={{
//           sx: {
//             fontStyle: "italic"
//           },
//         }}
//         {...props}
//       ) : (
//         // Render drawn signature canvas
//         <div>
//           <div style={{ border: '1px solid black', borderRadius: '5px', width: '100%', height: '200px' }}>
//             <SignaturePad
//               ref={sigPad}
//               canvasProps={{ className: 'sigCanvas' }}
//               onEnd={() => {
//                 setTypedSignature(sigPad.current.toDataURL()); // Set the typed signature value to the drawn signature data URL
//               }}
//             />
//           </div>
//           {/* Button to clear the drawn signature */}
//           <Button className="clear-button" variant="contained" onClick={clear}>
//             Clear
//           </Button>
//         </div>
//       )}
//     </div>
//   );
// });

// export default Signature;