// FileUpload.js
import React from 'react';
import { Button, Typography } from '@mui/material';

const FileUpload = React.forwardRef(({ name, label, required, ...props }, ref) => {
  return (
    <div className="form-element">
      {/* <label htmlFor={name}>{label}</label> */}
      <Typography variant="subtitle1">
          {label}
        </Typography>
      <input
        type="file"
        id={name}
        name={name}
        ref={ref}
        required={required}
        style={{ display: 'none' }}
        {...props}
      />
      <label htmlFor={name}>
        <Button className="clear-button" variant="contained" component="span">
          Upload
        </Button>
      </label>
    </div>
  );
});

export default FileUpload;