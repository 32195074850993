import React from 'react';
import FadeInText from './FadeInText';
import logo from '../images/Sitesteer main logo.png'; // Update this path to your actual logo path
import QueryStatsIcon from '../images/analytics_icon.png';
import AssignmentIcon from '../images/customisable_slates_icon.png'; 
import ScheduleIcon from '../images/scheduleworkflows_icon.png'; 
import NotificationsActiveIcon from '../images/notifications_icon.png'; 

const KeyFeaturesSection = () => {
  // const features = [
  //   { icon: "📄", title: "Customisable Slates", description: "Create digital forms, invoices, and checklists tailored to your specific needs." },
  //   { icon: "📊", title: "Real-Time Audit Center", description: "See a clear picture of your project's compliance status and prioritize overdue tasks at a glance." },
  //   { icon: "🔔", title: "Automated Scheduling & Reminders", description: "Schedule inspections, set deadlines, and receive automatic alerts for everyone involved." },
  //   { icon: "⏱️", title: "Boost Efficiency & Compliance", description: "Reduce rework costs, improve communication, and achieve zero tolerance for missed inspections." },
  // ];
  const features = [
    { imageSrc: QueryStatsIcon, title: "Customisable Slates", description: "Create digital forms, invoices, and checklists tailored to your specific needs." },
    { imageSrc: AssignmentIcon, title: "Real-Time Audit Center", description: "See a clear picture of your project's compliance status and prioritize overdue tasks at a glance." },
    { imageSrc: ScheduleIcon, title: "Automated Scheduling & Reminders", description: "Schedule inspections, set deadlines, and receive automatic alerts for everyone involved." },
    { imageSrc: NotificationsActiveIcon, title: "Boost Efficiency & Compliance", description: "Reduce rework costs, improve communication, and achieve zero tolerance for missed inspections." },
  ];

  return (
    <section id="key-features" className="key-features-section">
      <h2>Key Features</h2>
      <div className="key-features-content">
        {/* <div className="logo-container">
          <img src={logo} alt="Site Steer Logo" className="site-steer-logo" />
        </div> */}
        <div className="features-list">
          {features.map((feature, index) => (
            <FadeInText key={index}>
              <div className="feature-item">
                {/* <span className="feature-icon">{feature.icon}</span> */}
                <img src={feature.imageSrc} className="feature-icon-black" />
                <div className="features-text">
                  <h3>{feature.title}</h3>
                  <p>{feature.description}</p>
                </div>
              </div>
            </FadeInText>
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeyFeaturesSection;