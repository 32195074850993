// DecimalField.js
import React from 'react';
import { TextField, Typography } from '@mui/material';

const DecimalField = React.forwardRef(({ name, label, required, ...props }, ref) => {
  return (
    <div>
      <Typography variant="subtitle1">
        {label}
      </Typography>
      <TextField
        type="number"
        id={name}
        name={name}
        inputRef={ref}
        placeholder='Decimal Number'
        required={required}
        inputProps={{
          min: 0,
          step: 0.01,
        }}
        {...props}
      />
    </div>
  );
});

export default DecimalField;