import React from 'react';
import { Box, Typography, FormControlLabel, Switch } from '@mui/material';

export function ArchiveToggle({ showArchived, onToggle, title }) {
  return (
    <Box sx={{ 
      display: 'flex', 
      justifyContent: 'space-between', 
      alignItems: 'center',
      marginBottom: '1rem'
    }}>
      <Typography variant="h4">{title}</Typography>
      <FormControlLabel
        control={
          <Switch
            checked={showArchived}
            onChange={(e) => onToggle(e.target.checked)}
            color="primary"
          />
        }
        label="Show Archived Items"
        sx={{ marginRight: 0 }}
      />
    </Box>
  );
}