// export default CompanyDetails;
// Import necessary dependencies from React and Material-UI libraries
import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import { UserContext } from "../components/UserContext";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useAuth0 } from '@auth0/auth0-react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { CompanyFetch } from "../components/Backend Routes/fetchCompany";
import CompanyDialog from '../components/Company Profile Components/CompanyDialog';

// Define the columns for the DataGrid component
const columns: GridColDef[] = [
  { field: "companyName", headerName: "Company Name", flex: 1.5, minWidth: 250 },
  { field: "companyAddress", headerName: "Company Address", flex: 3, minWidth: 300 },
  { field: "companyVat", headerName: "VAT Number", flex: 1.5, minWidth: 250 },
  { field: "companyEmail", headerName: "Email", flex: 1.5, minWidth: 300 },
  { field: "companyTelephone", headerName: "Telephone", flex: 1.5, minWidth: 250 },
];

const CompanyDetails = () => {
  // Get authentication and user context
  const { user } = useAuth0();
  const { isPremiumUser, premiumKey } = useContext(UserContext);
  
  // Fetch initial company data
  const { company, loading, error } = CompanyFetch(premiumKey);
  
  // Navigation hook for routing
  const navigate = useNavigate();

  // State for managing local company data
  const [companyData, setCompanyData] = useState(null);

  // State for tracking the submission
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // State for managing the add/edit popup
  const [openAddEditPopup, setOpenAddEditPopup] = useState(false);
  
  // State for managing the form data in the popup
  const [editedCompany, setEditedCompany] = useState({
    owner_org: premiumKey,
    companyName: "",
    companyAddress: "",
    companyVat: "",
    companyEmail: "",
    companyTelephone: "",
  });

  // State for managing snackbar notifications
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });

  // Effect to initialize companyData when company data is fetched
  useEffect(() => {
    if (company) {
      setCompanyData(company);
    }
  }, [company]);

  // Handler for opening the add/edit popup
  const handleAddEdit = () => {
    // Pre-fill form with existing data or empty values
    setEditedCompany(companyData ? 
      { ...companyData, owner_org: premiumKey } : 
      {
        owner_org: premiumKey,
        companyName: "",
        companyAddress: "",
        companyVat: "",
        companyEmail: "",
        companyTelephone: "",
      }
    );
    setOpenAddEditPopup(true);
  };

  // Handler for form field changes
  const handleChange = (field, value) => {
    setEditedCompany(prevCompany => ({
      ...prevCompany,
      [field]: value,
    }));
  };

  // Update handleSubmit
  const handleSubmit = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const companyToSubmit = {
      ...editedCompany,
    };
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/company/details/?owner=${premiumKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(companyToSubmit),
      });

      if (!response.ok) {
        throw new Error('Failed to update company details');
      }

      setCompanyData(companyToSubmit);
      setSnackbar({
        open: true,
        message: "Company details updated successfully",
        severity: "success"
      });
      setOpenAddEditPopup(false);
      
    } catch (error) {
      console.error("Error updating company details:", error);
      setSnackbar({
        open: true,
        message: "Failed to update company details",
        severity: "error"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handler for closing the snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  // Handler for returning to profile page
  const returnProfile = () => {
    navigate('/profile');
  };

  // Prepare rows for DataGrid - now using companyData instead of company
  const rows = companyData ? [{ id: 1, ...companyData }] : [];

  return (
    <div className="page-container">
      {/* Back button with tooltip */}
      <Tooltip title="Back to Company Profile">
        <IconButton onClick={returnProfile}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>

      {/* Page title */}
      <Typography variant="h4">Company Details</Typography>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />

      <div className="company-details-container">
        {/* Show loading spinner while data is being fetched */}
        {loading ? (
          <CircularProgress />
        ) : error ? (
          // Show error message if fetch failed
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            {/* Add/Edit button */}
            <div style={{ marginBottom: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddEdit}
                className="standard-button"
                style={{ marginRight: '15px' }}
              >
                {companyData ? "Edit Company Details" : "Add Company Details"}
              </Button>
            </div>

            {/* DataGrid displaying company details */}
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={1}
                disableSelectionOnClick
                sx={{
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: 'Black',
                    textAlign: 'center',
                    fontSize: '18px',
                    color: 'white',
                  },
                }}
              />
            </div>
          </>
        )}
      </div>

      {/* Add/Edit Company Details Dialog */}
      <CompanyDialog
        open={openAddEditPopup}
        onClose={() => !isSubmitting && setOpenAddEditPopup(false)}
        onSubmit={handleSubmit}
        editedCompany={editedCompany}
        onFieldChange={handleChange}
        isSubmitting={isSubmitting}
        isEdit={!!companyData}
      />

      {/* Snackbar for displaying success/error messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default CompanyDetails;