import React, { useState, useEffect, useContext } from 'react';
import { Button, Snackbar, IconButton, Grid, Tooltip, Typography } from '@mui/material';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import DeleteIcon from '@mui/icons-material/Delete';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import CloseIcon from '@mui/icons-material/Close';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import TableChartIcon from '@mui/icons-material/TableChart';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import RenderEditSlate from './RenderEditSlate';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd'
import TitleDescription from '../Form Components/TitleDescription_edit';
import FormElementDialog from '../Form Components/FormElementDialog';
import SectionBreakDialog from '../Form Components/SectionBreakDialog';
import { UserContext } from "../../components/UserContext";
import TableDialog from '../Form Components/TableDialog';
import '../../App.css';

const EditSlate = () => {
  const { control, register, handleSubmit, reset, setValue, getValues, formState: { errors } } = useForm();
  const { user } = useAuth0();
  const { isPremiumUser, premiumKey } = useContext(UserContext);
  const [formBuilderElements, setFormBuilderElements] = useState([]);
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigate = useNavigate();
  const { templateId } = useParams();
  const location = useLocation();
  const [openDialog, setOpenDialog] = useState(false);
  const [openTableColumnDialog, setOpenTableColumnDialog] = useState(false);
  const [openSectionBreakDialog, setOpenSectionBreakDialog] = useState(false);
  const [openTableDialog, setOpenTableDialog] = useState(false);
  const [selectedFieldType, setSelectedFieldType] = useState(null);
  const [selectedTableElement, setSelectedTableElement] = useState(null);
  useEffect(() => {
    const formData = location.state?.formData;

    if (formData) {
      setTitle(formData.title);
      setDescription(formData.description);
      setFormBuilderElements(formData.fields);

      // Set the default values for the form fields based on the formData
      const defaultValues = {};
      formData.fields.forEach((field) => {
        defaultValues[field.name] = formData.data[field.name] || '';
      });
      reset(defaultValues);
    }
  }, [location.state]);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(formBuilderElements);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFormBuilderElements(items);
  };


  const handleOpenTableColumnDialog = (element) => {
    setSelectedTableElement(element);
    setSelectedFieldType('table-column');
    setOpenTableColumnDialog(true);
  };


  const handleUpdateElement = (updatedElement) => {
    setFormBuilderElements((prevElements) =>
      prevElements.map((el) => (el.name === updatedElement.name ? updatedElement : el))
    );
  };

  const handleAddElement = (element) => {
    setFormBuilderElements((prevElements) => [...prevElements, element]);
  };

  const handleDeleteElement = (index) => {
    setFormBuilderElements((prevElements) => prevElements.filter((_, i) => i !== index));
  };

  const onSubmit = async (formData) => {
    const currentDateTime = new Date().toISOString();

    const submissionData = {
      title: title,
      description: description,
      owner_org: premiumKey,
      last_updated: currentDateTime,
      fields: [
        ...formBuilderElements.map((element) => {
          if (element.field_type === 'table') {
            // Update the field name for the table element
            return {
              name: element.name,
              field_type: element.field_type,
              label: element.label,
              required: false,
              columns: element.columns, // Include the columns array
            };
          } else {
            return element;
          }
        }),
      ],
      data: { ...formData},
      status: false,
    };

    console.log('Submission Data:', submissionData);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/update-slate/${templateId}`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(submissionData),
      });

      if (response.ok) {
        setShowSuccessMessage(true);
        setFormSubmitted(true);
        setTimeout(() => {
          setShowSuccessMessage(false);
          navigate('/slate-templates');
        }, 1000);
      } else {
        throw new Error('Failed to update slate');
      }
    } catch (error) {
      console.error('Submission error:', error);
    }
  };

  const handleTitleChange = (title) => {
    setTitle(title);
    console.log('Title: ', title)
  };

  const handleDescriptionChange = (description) => {
    setDescription(description);
    console.log('Description: ', description)
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  const handleElementClick = (fieldType) => {
    setSelectedFieldType(fieldType);
    if (fieldType === 'table') {
      setOpenTableDialog(true);
    } else if (fieldType === 'section_break') {
      setOpenSectionBreakDialog(true);
    } else {
      setOpenDialog(true);
    }
  };

  const handleDialogSubmit = (data) => {
    setOpenDialog(false);
    handleAddElement(data);
  };

  // const handleTableColumnDialogSubmit = (data) => {
  //   setOpenTableColumnDialog(false);
  //   handleAddElement(data);
  // };
  const handleTableColumnDialogSubmit = (columnData) => {
    setFormBuilderElements((prevElements) => {
      return prevElements.map((el) => {
        if (el === selectedTableElement) {
          return {
            ...el,
            columns: [...el.columns, columnData],
          };
        }
        return el;
      });
    });
    setOpenTableColumnDialog(false);
    setSelectedTableElement(null);
  };

  const handleSectionBreakDialogSubmit = (data) => {
    setOpenSectionBreakDialog(false);
    handleAddElement(data);
  };

  // const handleTableDialogSubmit = (data) => {
  //   setOpenTableDialog(false);
  //   handleAddElement(data);
  // };
  const handleTableDialogSubmit = (data) => {
    console.log('table data:', data)
    setOpenTableDialog(false);
    const tableElement = {
      name: `table_${Date.now()}`, // Generate a unique name
      field_type: 'table',
      label: data,
      columns: data.columns || [], // Ensure columns are included
    };
    handleAddElement(tableElement);
  };

  return (
    <div className="create-slate-panel">
      <div className="header-container">
        <Tooltip title="Back to Templates">
          <IconButton onClick={() => navigate('/slate-templates')}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h4">Update Template</Typography>
      </div>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black', marginRight: '20px' }} />
      <Grid container spacing={0}>
        <Grid className="elements-panel-update" item xs={2.5}>
          <div className="form-elements-panel">
            <Button
              variant="contained"
              startIcon={<TextFieldsIcon />}
              onClick={() => handleElementClick('text')}
              className="form-element-button"
              fullWidth
            >
              Text Input
            </Button>
            <Button
              variant="contained"
              startIcon={<CheckBoxIcon />}
              onClick={() => handleElementClick('checkbox')}
              className="form-element-button"
              fullWidth
            >
              Mutliple Choice
            </Button>
            <Button
              variant="contained"
              startIcon={<CalendarTodayIcon />}
              onClick={() => handleElementClick('date')}
              className="form-element-button"
              fullWidth
            >
              Date Picker
            </Button>
            <Button
              variant="contained"
              startIcon={<BorderColorIcon />}
              onClick={() => handleElementClick('signature')}
              className="form-element-button"
              fullWidth
            >
              Signature
            </Button>
            <Button
              variant="contained"
              startIcon={<RadioButtonCheckedIcon />}
              onClick={() => handleElementClick('radio')}
              className="form-element-button"
              fullWidth
            >
              Single Selection
            </Button>
            <Button
              variant="contained"
              startIcon={<FormatListNumberedIcon />}
              onClick={() => handleElementClick('number')}
              className="form-element-button"
              fullWidth
            >
              Integer Number
            </Button>
            <Button
              variant="contained"
              startIcon={<FormatListNumberedIcon />}
              onClick={() => handleElementClick('decimal')}
              className="form-element-button"
              fullWidth
            >
              Decimal Number
            </Button>
            <Button
              variant="contained"
              onClick={() => handleElementClick('section_break')}
              className="form-element-button"
              fullWidth
            >
              Section Break
            </Button>
            <Button
              variant="contained"
              onClick={() => handleElementClick('description')}
              className="form-element-button"
              fullWidth
            >
              Description
            </Button>
            <Button
              variant="contained"
              startIcon={<TableChartIcon />}
              onClick={() => handleElementClick('table')}
              className="form-element-button"
              fullWidth
            >
              Table
            </Button>
            <Button
              variant="contained"
              startIcon={<AttachFileIcon />}
              onClick={() => handleElementClick('file')}
              className="form-element-button"
              fullWidth
            >
              File Upload
            </Button>
          </div>
        </Grid>
        <Grid item xs={9} style={{ paddingLeft: '10px', paddingRight: '10px' }}>
          <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            {/* <h1 className="form-preview-title">Slate Preview:</h1> */}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-preview" style={{ border: '2px solid black'}}>
            <TitleDescription
              title={title}
              description={description}
              onTitleChange={handleTitleChange}
              onDescriptionChange={handleDescriptionChange}
              control={control}
            />
            <DragDropContext onDragEnd={handleDragEnd}>
              <Droppable droppableId="form-elements">
                {(provided) => (
                  <div {...provided.droppableProps} ref={provided.innerRef}>
                    {formBuilderElements.map((element, index) => (
                      <Draggable key={element.name} draggableId={element.name} index={index}>
                        {(provided) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className="form-element-container"
                          >
                            <div className="form-element">
                              {/* <RenderEditSlate element={element} control={control} register={register} formData={location.state?.formData?.data}/> */}
                              <RenderEditSlate
                                element={element}
                                control={control}
                                register={register}
                                onUpdateElement={handleUpdateElement}
                                onOpenTableColumnDialog={handleOpenTableColumnDialog}
                              />
                            </div>
                            <IconButton className="delete-button" onClick={() => handleDeleteElement(index)}>
                              <DeleteIcon />
                            </IconButton>
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
          <div style={{ display: 'flex', justifyContent: 'flex-end', margin: '10px' }}>
          <Button className="form-element-submit" type="submit" variant="primary">
            Update Slate
          </Button>
        </div>
        </form>
        </Grid>
        <FormElementDialog
          open={openDialog || openTableColumnDialog}
          onClose={() => {
            setOpenDialog(false);
            setOpenTableColumnDialog(false);
          }}
          onSubmit={openTableColumnDialog ? handleTableColumnDialogSubmit : handleDialogSubmit}
          fieldType={selectedFieldType}
        />
        <SectionBreakDialog
          open={openSectionBreakDialog}
          onClose={() => setOpenSectionBreakDialog(false)}
          onSubmit={handleSectionBreakDialogSubmit}
        />
        <TableDialog
          open={openTableDialog}
          onClose={() => setOpenTableDialog(false)}
          onSubmit={handleTableDialogSubmit}
        />
        <Snackbar
          open={showSuccessMessage}
          autoHideDuration={1000}
          onClose={handleCloseSuccessMessage}
          message="Template Successfully Updated"
          action={
            <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
      </Grid>
    </div>
  );
};

export default EditSlate;