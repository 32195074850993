// DashboardDataFetch.js
import { useState, useEffect } from "react";

export const DashboardDataFetch = (url, owner_org) => {
  const [slates, setSlates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchSlates = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${url}?owner_org=${owner_org}`);
      const data = await response.json();
      console.log("project data", data)
      setSlates(data);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchSlates();
  }, [url, owner_org]);

  const refetchSlates = () => {
    fetchSlates();
  };

  return { slates, loading, error, refetchSlates };
};
