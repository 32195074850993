// Dropdown.js
import React from 'react';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const Dropdown = React.forwardRef(({ name, label, options, required, ...props }, ref) => {
  return (
    <div className="form-element">
      <FormControl required={required} {...props}>
        <InputLabel id={`${name}-label`}>{label}</InputLabel>
        <Select
          labelId={`${name}-label`}
          id={name}
          name={name}
          inputRef={ref}
          required={required}
        >
          {options.map((option, index) => (
            <MenuItem key={index} value={option}>{option}</MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  );
});

export default Dropdown;