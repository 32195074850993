// Checkbox.js
// import React from 'react';
// import { FormControlLabel, FormLabel, Checkbox as MuiCheckbox } from '@mui/material';

// const Checkbox = React.forwardRef(({ name, label, options = [], required, ...props }, ref) => {
//   return (
//     <div className="form-element">
//       <FormLabel component="legend">{label}</FormLabel>
//       {options.map((option, index) => (
//         <FormControlLabel
//           key={index}
//           control={
//             <MuiCheckbox
//               name={`${name}[${index}]`}
//               value={option}
//               inputRef={ref}
//               required={required}
//               {...props}
//             />
//           }
//           label={option}
//         />
//       ))}
//     </div>
//   );
// });

// export default Checkbox;

// Checkbox.js
import React, { useState } from 'react';
import { FormControlLabel, Typography, Checkbox as MuiCheckbox } from '@mui/material';

const Checkbox = React.forwardRef(({ name, label, options = [], onChange, required }, ref) => {
  const [checkedState, setCheckedState] = useState(
    new Array(options.length).fill(false)
  );

  const handleOnChange = (position) => {
    const updatedCheckedState = checkedState.map((item, index) =>
      index === position ? !item : item
    );

    setCheckedState(updatedCheckedState);

    // Convert the array of checked states to an array of selected option labels
    const selectedLabels = updatedCheckedState.reduce(
      (acc, currentState, index) => {
        if (currentState) {
          acc.push(options[index]);
        }
        return acc;
      },
      []
    );

    // Pass the array of selected option labels to the parent form's onChange handler
    onChange(selectedLabels);
  };

  return (
    <div className="form-element">
      <Typography variant="subtitle1">
          {label}
        </Typography>
      {options.map((option, index) => (
        <FormControlLabel
          key={index}
          control={
            <MuiCheckbox
              checked={checkedState[index]}
              onChange={() => handleOnChange(index)}
              name={`${name}[${index}]`}
              required={required && checkedState.every((item) => !item)}
              ref={ref} // Pass the ref to the underlying MuiCheckbox component
            />
          }
          label={option}
        />
      ))}
    </div>
  );
});

export default Checkbox;
