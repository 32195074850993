// Invoices.jsx
import React, { useState, useContext, useEffect, useMemo, useRef } from "react";
import {
  CircularProgress,
  Snackbar,
  Alert,
  Button,
  Tooltip,
} from "@mui/material";
import { UserContext } from "../components/UserContext"; // Context for user information
import {
  DataGrid,
  GridActionsCellItem,
  GridColumnMenuFilterItem,
  GridColumnMenuSortItem,
  getGridStringOperators,
} from "@mui/x-data-grid"; // MUI DataGrid components
import { useNavigate, useParams, useLocation } from "react-router-dom"; // Hooks for navigation and accessing location
import { useAuth0 } from "@auth0/auth0-react"; // Auth0 for authentication
import CloseIcon from "@mui/icons-material/Close"; // Icon for closing elements
import DownloadIcon from '@mui/icons-material/Download';
import ArchiveIcon from "@mui/icons-material/Archive"; // Icon for archive action
import EditIcon from "@mui/icons-material/Edit"; // Icon for edit action
import DeleteIcon from "@mui/icons-material/Delete"; // Icon for delete action
import { fetchInvoiceData } from "../components/Backend Routes/InvoiceFetch"; // Backend API functions
import { fetchActiveProspectData } from "../components/Backend Routes/ProspectFetch"; // Backend API functions
import { fetchActiveQuoteData } from "../components/Backend Routes/QuoteFetch"; // Backend API functions
import { deleteEntry } from "../components/Backend Routes/GeneralDelete"; // Backend API functions
import { archiveEntry } from "../components/Backend Routes/GeneralArchive"; // Backend API functions
import { handleDownload as apiHandleDownload } from "../components/Backend Routes/pdfDownload"; // Backend API functions
import InvoiceDialog from '../components/Invoice Components/InvoiceDialog';
import DeleteConfirmationDialog from '../components/General Components/components/DeleteConfirmationDialog';
import ArchiveConfirmationDialog from '../components/General Components/components/ArchiveConfirmationDialog';
import { useArchivedFilter } from '../components/General Components/hooks/useArchivedFilter';
import { useCompanyLookup } from '../components/General Components/hooks/useCompanyLookup';
import { useURLParameters } from '../components/General Components/hooks/useURLParameters';
import { useDeleteOperation } from '../components/General Components/hooks/useDeleteOperation';
import { useArchiveOperation } from '../components/General Components/hooks/useArchiveOperation';
import { ArchiveToggle } from '../components/General Components/components/ArchiveToggle';
import { FilterBubbles } from '../components/General Components/components/FilterBubbles';


const Invoices = () => {
  // Initialize navigation and location hooks
  const navigate = useNavigate();
  const location = useLocation();
  console.log('location:', location.search);

  // Ref flag to indicate programmatic filter updates
  const initialFilterSet = useRef(false);

  // Extract user information from Auth0
  const { user } = useAuth0();

  // Extract premium user information from context
  const { isPremiumUser, premiumKey } = useContext(UserContext);

  // State variables for handling confirmation dialogs, deletions, downloading and archiving
  // const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  // const [openConfirmationArchiveDialog, setOpenConfirmationArchiveDialog] = useState(false);
  // const [confirmDelete, setConfirmDelete] = useState(false);
  // const [confirmArchive, setConfirmArchive] = useState(false);
  // const [itemToDeleteId, setItemToDeleteId] = useState(null);
  // const [itemToArchiveId, setItemToArchiveId] = useState(null); // State for archiving prospects
  const [showArchived, setShowArchived] = useState(false);

  // State for storing invoice templatedata
  const [invoiceTemplate, setInvoiceTemplate] = useState([]);
  
  // State for storing prospect and CRM companies data
  const [invoiceData, setInvoiceData] = useState([]);
  const [quoteData, setQuoteData] = useState([]);
  const [prospectData, setProspectData] = useState([]);


  // State variables for loading and error handling
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [initialCompanyId, setInitialCompanyId] = useState(null);
  const [initialProjectId, setInitialProjectId] = useState(null);
  const [invoiceAction, setinvoiceAction] = useState('');

  // State variables for add/edit popup
  const [openAddEditPopup, setOpenAddEditPopup] = useState(false);
  const [editedItem, setEditedItem] = useState({
    invoiceId: "",
    work_description: "",
    cis_reversal: false,
    invoiceId: "",
    companyId: "",
    projectId: "",
    companyName: "",
    projectName: "",
    terms: "",
    status: "",
    last_updated: new Date(),
  });

  // State for managing the filter model in DataGrid
  const [filterModel, setFilterModel] = useState({ items: [] });

  // Snackbar state variable set and include severity
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success" // Can be "success", "error", "warning", "info"
  });
  
  // Use custom hooks
  const filteredData = useArchivedFilter(invoiceData, showArchived);
  const companyLookup = useCompanyLookup(invoiceData);

  /**
   * Fetching prospect and CRM companies data from the backend API.
   * Ensures that each prospect has a unique 'id' required by DataGrid.
   */
  useEffect(() => {
    const loadData = async () => {
      try {
        const invoices = await fetchInvoiceData(premiumKey);
        const quotes = await fetchActiveQuoteData(premiumKey);
        const prospects = await fetchActiveProspectData(premiumKey);
        console.log('invoices', invoices);
        console.log('prospects', prospects);

        const idSet = new Set();
        
        const mappedInvoices = invoices.map((invoice) => {
          let id = invoice.invoiceId && invoice.invoiceId.trim() !== '' ? invoice.invoiceId : `project-${invoice.projectId}` || `temp-${Math.random().toString(36)}`;
          while (idSet.has(id)) {
            id = `${id}-${Math.random().toString(36)}`;
          }
          idSet.add(id);

          // Find corresponding prospect
          const correspondingProspect = prospects.find(
            prospect => prospect.companyId === invoice.companyId && prospect.projectId === invoice.projectId
          );

          // Find corresponding quote
          const correspondingQuote = quotes.find(
            quote => quote.quoteId === invoice.quoteId
          );

          return {
            ...invoice,
            id,
            companyName: correspondingProspect?.companyName || 'Unknown Company',
            projectName: correspondingProspect?.projectName || 'Unknown Project',
            name: correspondingQuote?.name || 'Unknown Name',
          };
        });

        setInvoiceData(mappedInvoices);
        setQuoteData(quotes);
        setProspectData(prospects);
      } catch (err) {
        setError(err.message);
        setInvoiceData([]);
        console.error("Invoices - Error fetching data:", err);
      } finally {
        setLoading(false);
        setDataLoaded(true);
      }
    };
    loadData();
  }, [premiumKey]);


  /**
   * Extract 'companyId' from URL query parameters and apply it as a filter.
   * Prevents infinite loops by checking if the filter is already set.
   */
    const { resetFilterFlag } = useURLParameters({
      onSetInitialIds: ({ companyId, projectId }) => {
        setInitialCompanyId(companyId);
        setInitialProjectId(projectId);
      },
      onSetFilter: (filterModel) => {
        setFilterModel(filterModel);
        initialFilterSet.current = true;
      },
      companyLookup
    });

     /**
   * If data is loaded and an additional initialCompanyId is set from
   * the URL search paramters it then sets companyId of EditedItem for the popup to prepopulate
   */
     useEffect(() => {
      if (dataLoaded && initialCompanyId && initialProjectId) {
        const prospect = invoiceData.find(c => c.companyId === initialCompanyId);
        if (prospect) {
          setEditedItem({
            invoiceId: "",
            companyId: prospect.companyId,
            projectId: prospect.projectId, 
            name: "",
            status: "",
          });
          setOpenAddEditPopup(true);
        }
        setInitialCompanyId(null); // Reset to prevent reopening
      }
    }, [dataLoaded, initialCompanyId, invoiceData]);

  /**
   * Handle opening the add/edit popup.
   * If an 'id' is provided, populate the form with existing data for editing.
   * Otherwise, reset the form for adding a new prospect.
   * @param {string|null} id - The unique identifier of the prospect to edit, or null for adding.
   */
  const handleAddEdit = (id = null) => {
    console.log("Invoices - Editing item with id:", id);
    if (id !== null && invoiceData) {
      const itemToEdit = invoiceData.find((item) => item.id === id);
      console.log("Invoices - Editing item:", itemToEdit);
      
      // Find the corresponding prospect data
      const correspondingProspect = prospectData.find(
        prospect => prospect.companyId === itemToEdit.companyId && prospect.projectId === itemToEdit.projectId
      );

      // Find corresponding quote
      const correspondingQuote = quoteData.find(
        quote => quote.quoteId === itemToEdit.quoteId
      );

      setEditedItem({
        ...itemToEdit,
        companyName: correspondingProspect ? correspondingProspect.companyName : '',
        projectName: correspondingProspect ? correspondingProspect.projectName : '',
        name: correspondingQuote ? correspondingQuote.name : '',
      });
    } else {
      // Reset form fields for adding a new invoice
      setEditedItem({
        invoiceId: "",
        work_description: "",
        cis_reversal: false,
        invoiceId: "",
        companyId: "",
        terms: "",
        projectId: "",
        companyName: "",
        projectName: "",
        terms: "",
        name: "",
        status: "",
        last_updated: new Date(),
      });
      console.log("Invoices - Adding new invoice.");
    }
    
    // Only open the dialog if the template has been loaded
    if (invoiceTemplate) {
      setOpenAddEditPopup(true);
    } else {
      console.error("Invoice template not loaded yet");
      // Optionally, show an error message to the user
    }
  };

  /**
   * Handle initiating the deletion of a invoice.
   * Opens a confirmation dialog.
   */
  const {
    handleDelete,
    openConfirmationDialog: openDeleteDialog,
    confirmDelete,
    setConfirmDelete,
    handleConfirmDelete,
    handleCloseDialog: handleCloseDeleteDialog,
    isDeleting,
  } = useDeleteOperation({
    itemName: 'invoice',
    onDelete: async (id) => {
      // Logic to delete the item
      try {
        // Remove the item from local state using quoteData
        const updatedData = invoiceData.filter((item) => item.id !== id);

        // Optionally, send a request to your backend to delete the item
        await deleteEntry(premiumKey, id, 'invoice', 'invoiceId');

        setInvoiceData(updatedData);

        // Show success message
        setSnackbar({
          open: true,
          message: 'Invoice successfully deleted',
          severity: 'success',
        });
      } catch (error) {
        // Show error message
        setSnackbar({
          open: true,
          message: `Failed to delete invoice: ${error.message}`,
          severity: 'error',
        });
      }
    },
  });


/**
 * Handle initiating the archiving of a invoice.
 * Opens an archive confirmation dialog.
 */
const {
  handleArchive,
  openConfirmationDialog,
  confirmArchive,
  setConfirmArchive,
  handleConfirmArchive,
  handleCloseDialog,
  isArchiving,
} = useArchiveOperation({
  itemName: 'invoice',
  onArchive: async (id) => {
    // Logic to archive the item
    try {
      // send a request to your backend to archive the item
      await archiveEntry(premiumKey, id, 'invoice', 'invoiceId');

      // Update local state to reflect the change
      setInvoiceData((prevData) =>
        prevData.map((item) =>
          item.id === id ? { ...item, status: "Archived" } : item
        )
      );

      // Show success message
      setSnackbar({
        open: true,
        message: 'Invoice successfully archived',
        severity: 'success',
      });
    } catch (error) {
      // Show error message
      setSnackbar({
        open: true,
        message: `Failed to archive invoice: ${error.message}`,
        severity: 'error',
      });
    }
  },
});


    /**
   * Handle initiating the archiving of an invoice.
   * Opens an download confirmation dialog or performs archiving logic.
   * @param {string} id - The unique identifier of the prospect to archive.
   */
  const handleDownload = async (id) => {
    console.log(`Invoices - Initiating download for id: ${id}`);
    try {
      // Call the API function to handle the download
      const blob = await apiHandleDownload(premiumKey, 'invoice', 'invoiceId', id);
      
      // Create a URL for the blob
      const url = window.URL.createObjectURL(blob);
      
      // Create a temporary anchor element and trigger the download
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = `invoice_${id}.pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      
      setSnackbar({
        open: true,
        message: "Invoice downloaded successfully",
        severity: "success"
      });
    } catch (error) {
      console.error("Error downloading invoice:", error);
      setSnackbar({
        open: true,
        message: "Failed to download Invoice",
        severity: "error"
      });
    }
  };


  /**
   * Handle the submission of the add/edit form.
   * Updates local state and sends request to backend API.
   * @param {Object} formData - The form data from the QuoteDialog
   * @param {string} action - Either 'draft' or 'finalize'
   */
  const handleSubmit = async (formData) => {
    try {
      setinvoiceAction(formData.action)
      // Prepare the new invoice object
      let newInvoice = {
        name: formData.name,
        creator: user.email || "",
        last_updated: new Date().toISOString(),
        issue_date: formData.issue_date ? `${formData.issue_date}T00:00:00` : null,
        work_description: formData.work_description,
        cis_reversal: formData.cis_reversal,
        companyId: formData.companyId,
        projectId: formData.projectId,
        quoteId: formData.quoteId,
        terms: formData.terms,
        status: formData.status,
        lineItems: formData.lineItems,
        invoiceTotal: formData.invoiceTotal,
        invoiceId: formData.invoiceId || `new-${Date.now()}`,
        id: formData.invoiceId || `new-${Date.now()}`, // Add this line
      };

      // Validation for finalized invoices
      if (invoiceAction === 'finalize') {
        const requiredFields = ['companyId', 'projectId', 'quoteId', 'status', 'invoiceTotal', 'lineItems', 'issue_date'];
        for (let field of requiredFields) {
          if (!newInvoice[field]) {
            throw new Error(`${field} is required for finalized invoices`);
          }
        }
        if (newInvoice.lineItems.length === 0) {
          throw new Error('At least one line item is required for finalized invoices');
        }
      }

      // Find corresponding prospect
      const correspondingProspect = prospectData.find(
        prospect => prospect.companyId === newInvoice.companyId && prospect.projectId === newInvoice.projectId
      );

      // Find corresponding quote
      const correspondingQuote = quoteData.find(
        quote => quote.quoteId === newInvoice.quoteId
      );

      console.log("quote of corresponding quote:", correspondingQuote)

      // Add companyName and projectName to the newInvoice
      newInvoice = {
        ...newInvoice,
        companyName: correspondingProspect?.companyName || 'Unknown Company',
        projectName: correspondingProspect?.projectName || 'Unknown Project',
        name: correspondingQuote?.name || 'Unknown Quote'
      };

      let updatedData;
      if (formData.invoiceId) {
        // Editing existing invoice
        updatedData = invoiceData.map(item => 
          item.invoiceId === formData.invoiceId ? { ...newInvoice, id: item.id } : item
        );
        await updateInvoices(updatedData);
        setSnackbar({
          open: true,
          message: "Invoice successfully edited",
          severity: "success"
        });
      } else {
        // Adding new invoice
        updatedData = [...invoiceData, { ...newInvoice, id: newInvoice.invoiceId }];
        await updateInvoices(updatedData);
        setSnackbar({
          open: true,
          message: "New invoice successfully added to Invoices",
          severity: "success"
        });
      }

      // Update local state
      setInvoiceData(updatedData);

      // Close the dialog and show success message
      setOpenAddEditPopup(false);

    } catch (error) {
      console.error("Error updating Invoice data:", error);
      setSnackbar({
        open: true,
        message: "Failed to update invoice entry",
        severity: "error"
      });
    }
  };

  /**
   * Send updated invoice data to the backend API.
   * @param {Array} newData - The updated array of invoice data.
   */
  const updateInvoices = async (newData) => {
    try {
      // Remove the 'id' field and transform data for backend
      const cleanedData = newData.map(({ id, ...item }) => ({
        ...item,
        invoiceId: item.invoiceId || id, // Use the id as invoiceId if invoiceId is empty
      }));
      
      const requestBody = {
        owner_org: premiumKey,
        items: cleanedData
      };

      console.log("Sending data to backend:", requestBody);

      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/invoice/invoice-details/?owner=${premiumKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });
      
      if (!response.ok) {
        throw new Error(`Failed to update invoice data: ${response.statusText}`);
      }

      const responseData = await response.json();
      console.log("Full response from server:", responseData);

      // Note: We don't set invoiceData here as it's already updated in handleSubmit

    } catch (error) {
      console.error("Error updating Invoice data:", error);
      throw error; // Propagate error to be handled in handleSubmit
    }
  };

  /**
 * Handle closing the Snackbar message.
 */
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };


  /**
   * Custom Column Menu for DataGrid.
   * Restricts the column menu to only sort and filter options.
   * @param {object} props - Properties passed to the custom column menu.
   */
  const CustomColumnMenu = (props) => {
    const { hideMenu, currentColumn } = props;
    return (
      <div>
        <GridColumnMenuSortItem onClick={hideMenu} column={currentColumn} />
        <GridColumnMenuFilterItem onClick={hideMenu} column={currentColumn} />
      </div>
    );
  };

  /**
   * Define the columns for the DataGrid, including field names, header names, flex properties, and filter operators.
   * The 'actions' column includes edit, delete, and archive actions for each row.
   */
  const columns = useMemo(
    () => [
      {
        field: "companyName",
        headerName: "Company Name",
        flex: 1,
        minWidth: 150,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "projectName",
        headerName: "Project Name",
        flex: 1,
        minWidth: 150,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "name",
        headerName: "Invoice Name",
        flex: 1,
        minWidth: 150,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      {
        field: "status",
        headerName: "Status",
        flex: 1,
        minWidth: 150,
        filterOperators: getGridStringOperators().filter((op) =>
          ["contains", "equals"].includes(op.value)
        ),
      },
      // {
      //   field: "most_recent",
      //   headerName: "Most Recent Activity",
      //   flex: 1,
      //   minWidth: 150,
      //   filterOperators: getGridStringOperators().filter((op) =>
      //     ["contains", "equals"].includes(op.value)
      //   ),
      // },
      {
        field: "actions", // Special column for action buttons
        type: "actions",
        headerName: "Actions",
        width: 150,
        cellClassName: "actions",
        getActions: ({ id, row}) => {
          const actions =  [
            <Tooltip title="Edit Invoice">
            <GridActionsCellItem
              icon={<EditIcon />}
              label="Edit"
              className="textPrimary"
              onClick={() => handleAddEdit(id)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Delete Invoice">
            <GridActionsCellItem
              icon={<DeleteIcon />}
              label="Delete"
              onClick={() => handleDelete(id)}
              color="inherit"
            />
          </Tooltip>,
          <Tooltip title="Archive Invoice">
            <GridActionsCellItem
              icon={<ArchiveIcon />}
              label="Archive"
              onClick={() => handleArchive(id)}
              color="inherit"
            />
          </Tooltip>
          ];

          // Only add the Download action if the status is not 'Draft'
          if (row.status !== 'Draft') {
            actions.push(
              <Tooltip title="Download Invoice">
                <GridActionsCellItem
                icon={<DownloadIcon />}
                label="Download Invoice"
                onClick={(e) => {
                  e.stopPropagation(); // Prevent event bubbling
                  handleDownload(id);
                }}
                color="inherit"
                />
              </Tooltip>
            );
          }
          return actions;
        },
      },
    ],
    [companyLookup, handleAddEdit, handleDelete, handleArchive, handleDownload] // Dependencies for useMemo
  );

  /**
   * Create a mapping from field names to header names for display in filter bubbles.
   * Utilizes React's useMemo for performance optimization.
   */
  const fieldToHeaderName = useMemo(() => {
    const mapping = {};
    columns.forEach((column) => {
      mapping[column.field] = column.headerName;
    });
    return mapping;
  }, [columns]);

  /**
   * Remove a specific filter based on its index in the filter model.
   * Updates the 'filterModel' state to exclude the removed filter.
   * @param {number} filterIndex - The index of the filter to remove.
   */
  const removeFilter = (filterIndex) => {
    console.log(`Prospects - Removing filter at index: ${filterIndex}`);
    const newItems = [...filterModel.items];
    newItems.splice(filterIndex, 1); // Remove the filter at the specified index
    setFilterModel({ ...filterModel, items: newItems }); // Update the filter model state
  };

  /**
   * Handle changes to the filter model from the DataGrid.
   * Updates the local 'filterModel' state to synchronize with filter bubbles.
   * @param {object} model - The new filter model.
   */
  const handleFilterModelChange = (newModel) => {
    console.log("Prospects - Filter Model Changed:", newModel);
    // Only update if the new model is different from the current one
    setFilterModel((prevModel) => {
      if (JSON.stringify(prevModel) !== JSON.stringify(newModel)) {
        return newModel;
      }
      return prevModel;
    });
  };

        /**
   * Handle double-click event on a DataGrid row.
   * Navigates to the Prospects page and passes the selected company's ID.
   * @param {object} params - Parameters containing row information.
   */
  const handleRowDoubleClick = (params) => {
    console.log('params double click', params)
    const companyName = params.row.companyName;
    // Navigate to Prospects page with companyName as a query parameter
    navigate(`/live-projects?companyName=${companyName}`);
  };

  /**
   * Render the Prospect Details page, including the DataGrid and dialogs for add/edit/delete operations.
   */
  return (
    <div className="page-container">
      {/* Page Title and Archive Toggle */}
      <ArchiveToggle 
        showArchived={showArchived}
        onToggle={setShowArchived}
        title="Invoice Details"
      />

      <hr style={{
        borderTop: "2px solid black",
        borderBottom: "2px solid black",
      }} />

      <FilterBubbles
        filterModel={filterModel}
        fieldToHeaderName={fieldToHeaderName}
        companyLookup={companyLookup}
        onRemoveFilter={removeFilter}
      />

      {/* Add the Delete & Archive ConfirmationDialog component */}
      <DeleteConfirmationDialog
        open={openDeleteDialog}
        onClose={handleCloseDeleteDialog}
        onConfirm={handleConfirmDelete}
        confirmDelete={confirmDelete}
        setConfirmDelete={setConfirmDelete}
        isDeleting={isDeleting}
        itemName="invoice"
      />

      <ArchiveConfirmationDialog
        open={openConfirmationDialog}
        onClose={handleCloseDialog}
        onConfirm={handleConfirmArchive}
        confirmArchive={confirmArchive}
        setConfirmArchive={setConfirmArchive}
        isArchiving={isArchiving}
        itemName="invoice"
      />

      <div className="crm-details-container">
        {loading ? (
          // Show a loading spinner while data is being fetched
          <CircularProgress />
        ) : error ? (
          // Display an error alert if there's an error fetching data
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            {/* Button to Add a New Prospect */}
            <div style={{ marginBottom: "16px" }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleAddEdit()}
                className="standard-button"
                style={{ marginRight: "15px" }}
              >
                Add Invoice
              </Button>
            </div>

            {/* DataGrid Displaying Prospect Data */}
            <div style={{ height: 500, width: "100%" }}>
              <DataGrid
                rows={filteredData} // Rows data
                columns={columns} // Columns configuration
                pageSize={10} // Number of rows per page
                rowsPerPageOptions={[5, 10, 20]} // Options for rows per page
                disableSelectionOnClick // Disable row selection on click
                filterModel={filterModel} // Controlled filter model
                onRowDoubleClick={handleRowDoubleClick} // Handle row double-click
                onFilterModelChange={handleFilterModelChange} // Handle filter model changes
                components={{
                  // Use custom column menu to limit options
                  ColumnMenu: CustomColumnMenu,
                }}
                sx={{
                  // Styling for the DataGrid
                  "& .MuiDataGrid-columnHeader": {
                    backgroundColor: "#DDD6CC",
                    textAlign: "center",
                    fontSize: "18px",
                    color: "black",
                  },
                  "& .MuiDataGrid-menuIcon": {
                    color: "black",
                  },
                  "& .MuiDataGrid-iconButtonContainer": {
                    color: "black",
                  },
                }}
              />
            </div>
          </>
        )}
      </div>

      {/* Dialog for Adding/Editing a invoice */}
      <InvoiceDialog
      open={openAddEditPopup}
      onClose={() => setOpenAddEditPopup(false)}
      template={invoiceTemplate}
      editedItem={editedItem}
      // handleChange={handleChange}
      handleSubmit={handleSubmit}
      prospectData={prospectData}
      quoteData={quoteData}
    />

      {/* Snackbar for Displaying Messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default Invoices;