// // // hooks/useDeleteOperation.js
// // import { useState } from 'react';
// // import { 
// //   Dialog, 
// //   DialogTitle, 
// //   DialogContent, 
// //   DialogActions, 
// //   Button, 
// //   Typography, 
// //   FormControlLabel, 
// //   Checkbox 
// // } from '@mui/material';
// // import { deleteItem } from '../../Backend Routes/PlatformOperations';

// // export function useDeleteOperation({ 
// //   premiumKey, 
// //   itemName, 
// //   onSuccess, 
// //   setSnackbar 
// // }) {
// //   const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
// //   const [confirmDelete, setConfirmDelete] = useState(false);
// //   const [itemToDeleteId, setItemToDeleteId] = useState(null);

// //   const handleDelete = (id) => {
// //     setItemToDeleteId(id);
// //     setConfirmDelete(false);
// //     setOpenConfirmationDialog(true);
// //   };

// //   const handleConfirmDelete = async () => {
// //     if (confirmDelete && itemToDeleteId !== null) {
// //       try {
// //         await deleteItem(premiumKey, itemToDeleteId, itemName);
        
// //         // Call the success callback (for updating local state)
// //         if (onSuccess) {
// //           onSuccess(itemToDeleteId);
// //         }

// //         // Show success message
// //         if (setSnackbar) {
// //           setSnackbar({
// //             open: true,
// //             message: `${itemName} successfully deleted`,
// //             severity: "success"
// //           });
// //         }
// //       } catch (error) {
// //         // Show error message
// //         if (setSnackbar) {
// //           setSnackbar({
// //             open: true,
// //             message: `Failed to delete ${itemName}`,
// //             severity: "error"
// //           });
// //         }
// //       } finally {
// //         setOpenConfirmationDialog(false);
// //         setItemToDeleteId(null);
// //         setConfirmDelete(false);
// //       }
// //     }
// //   };

// //   const DeleteConfirmationDialog = ({ 
// //     onClose = () => setOpenConfirmationDialog(false) 
// //   }) => (
// //     <Dialog
// //       open={openConfirmationDialog}
// //       onClose={onClose}
// //     >
// //       <DialogTitle>Confirm Deletion</DialogTitle>
// //       <DialogContent>
// //         <Typography>
// //           Are you sure you want to delete this {itemName} entry?
// //         </Typography>
// //         <FormControlLabel
// //           control={
// //             <Checkbox
// //               checked={confirmDelete}
// //               onChange={(e) => setConfirmDelete(e.target.checked)}
// //             />
// //           }
// //           label={`Yes, I want to delete this ${itemName}`}
// //         />
// //       </DialogContent>
// //       <DialogActions>
// //         <Button onClick={onClose}>Cancel</Button>
// //         <Button
// //           onClick={handleConfirmDelete}
// //           color="error"
// //           disabled={!confirmDelete}
// //         >
// //           Delete
// //         </Button>
// //       </DialogActions>
// //     </Dialog>
// //   );

// //   return {
// //     handleDelete,
// //     DeleteConfirmationDialog,
// //     isConfirmationOpen: openConfirmationDialog,
// //     closeConfirmation: () => setOpenConfirmationDialog(false)
// //   };
// // }

// // hooks/useDeleteOperation.js
// import { useState, useCallback } from 'react';

// export function useDeleteOperation({ 
//   itemName, 
//   onDelete 
// }) {
//   const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
//   const [confirmDelete, setConfirmDelete] = useState(false);
//   const [itemToDeleteId, setItemToDeleteId] = useState(null);

//   const handleDelete = useCallback((id) => {
//     setItemToDeleteId(id);
//     setConfirmDelete(false);
//     setOpenConfirmationDialog(true);
//   }, []);

//   const handleConfirmDelete = useCallback(async () => {
//     if (confirmDelete && itemToDeleteId !== null) {
//       await onDelete(itemToDeleteId);
//       setOpenConfirmationDialog(false);
//       setItemToDeleteId(null);
//       setConfirmDelete(false);
//     }
//   }, [confirmDelete, itemToDeleteId, onDelete]);

//   const handleCloseDialog = useCallback(() => {
//     setOpenConfirmationDialog(false);
//     setItemToDeleteId(null);
//     setConfirmDelete(false);
//   }, []);

//   return {
//     handleDelete,
//     openConfirmationDialog,
//     confirmDelete,
//     setConfirmDelete,
//     handleConfirmDelete,
//     handleCloseDialog,
//   };
// }

import { useState, useCallback } from 'react';

export function useDeleteOperation({ 
  onDelete 
}) {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [itemToDeleteId, setItemToDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = useCallback((id) => {
    setItemToDeleteId(id);
    setConfirmDelete(false);
    setOpenConfirmationDialog(true);
  }, []);

  const handleConfirmDelete = useCallback(async () => {
    if (confirmDelete && itemToDeleteId !== null) {
      setIsDeleting(true);
      try {
        await onDelete(itemToDeleteId);
      } finally {
        setIsDeleting(false);
        setOpenConfirmationDialog(false);
        setItemToDeleteId(null);
        setConfirmDelete(false);
      }
    }
  }, [confirmDelete, itemToDeleteId, onDelete]);

  const handleCloseDialog = useCallback(() => {
    if (!isDeleting) {
      setOpenConfirmationDialog(false);
      setItemToDeleteId(null);
      setConfirmDelete(false);
    }
  }, [isDeleting]);

  return {
    handleDelete,
    openConfirmationDialog,
    confirmDelete,
    setConfirmDelete,
    handleConfirmDelete,
    handleCloseDialog,
    isDeleting,
  };
}