import React, { useState, useEffect, useRef } from 'react';
import { Button, Grid, IconButton, Typography } from '@mui/material';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import BorderColorIcon from '@mui/icons-material/BorderColor';
import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
import TableChartIcon from '@mui/icons-material/TableChart';
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import { useForm, Controller } from 'react-hook-form';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import TitleDescription from './TitleDescription_create';
import TextInput from './TextInput';
import Checkbox from './Checkbox';
import DatePicker from './DatePicker';
import Signature from './Signature';
import RadioButton from './RadioButton';
import Table from './Table_Create';
import NumberField from './NumberField';
import DecimalField from './DecimalField';
import Dropdown from './Dropdown';
import FileUpload from './FileUpload';
import SectionBreak from './SectionBreak';
import Description from './Description';
import FormElementDialog from './FormElementDialog';
import TableDialog from './TableDialog';
import SectionBreakDialog from './SectionBreakDialog';
import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

const FormBuilder = ({ onTitleChange, onDescriptionChange, onSubmit, control, resetForm, register}) => {
  const { setValue, getValues } = useForm();
  const navigate = useNavigate();
  const [formElements, setFormElements] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedFieldType, setSelectedFieldType] = useState('')
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [openSectionBreakDialog, setOpenSectionBreakDialog] = useState(false);
  const [openTableDialog, setOpenTableDialog] = useState(false);

  const [openTableColumnDialog, setOpenTableColumnDialog] = useState(false);
  const [selectedTableElement, setSelectedTableElement] = useState(null);


  const [scrollPosition, setScrollPosition] = useState(0);
  const formPreviewRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (formPreviewRef.current) {
        const position = formPreviewRef.current.scrollTop;
        setScrollPosition(position);
      }
    };

    const formPreviewElement = formPreviewRef.current;
    if (formPreviewElement) {
      formPreviewElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (formPreviewElement) {
        formPreviewElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(formElements);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFormElements(items);
    onSubmit(items);
  };

  const returnTemplates = () => {
    navigate('/slate-templates');
  };


//useEffect called to reset the form values visually
  useEffect(() => {
    if (resetForm) {
      setTitle('');
      setDescription('');
      setFormElements([]);
      // setResetTriggered(false);
    }
  }, [resetForm]);

  // Manages the change of the title and sets the title variable value as per the user input
  const handleTitleChange = (event) => {
    setTitle(event.target.value);
    onTitleChange(event.target.value); // Pass the title value to the parent component
  };

  // Manages the change of the description and sets the description variable value as per the user input
  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    onDescriptionChange(event.target.value); // Pass the description value to the parent component
  };


// Function for opening the SectionBreakDialog
  const handleSectionBreakDialogSubmit = (element) => {
    setFormElements((prevElements) => {
      const newElements = [...prevElements, element];
      onSubmit(newElements);
      return newElements;
    });
    setOpenSectionBreakDialog(false);
  };

  // Function readjusts the form if the user deletes any form elements. 
  // Takes the index of the form element to be deleted. It creates a new array updatedElements 
  // by filtering out the element at the specified index using the filter method. 
  const handleDeleteElement = (index) => {
    // Remove the form element at the specified index
    const updatedElements = formElements.filter((_, i) => i !== index);
    setFormElements(updatedElements);
    onSubmit(updatedElements); // Pass the updated elements back to the parent
  };

  // Function that handles the submission of the Table Dialog
  const handleTableColumnDialogSubmit = (element) => {
    setFormElements((prevElements) => {
      const newElements = prevElements.map((el) => {
        if (el === selectedTableElement) {
          return {
            ...el,
            columns: [...el.columns, element],
          };
        }
        return el;
      });
      onSubmit(newElements);
      return newElements;
    });
    setOpenTableColumnDialog(false);
    setSelectedTableElement(null);
    setSelectedFieldType('');
  };

  //Function that is called and sets the table properties when a table is added to the form
  const handleTableDialogSubmit = (label) => {
    const element = {
      name: label.replace(/\s+/g, '_').toLowerCase(), //for the name of the element set it to the lowercase of the label and substitute spaces with underscore
      field_type: 'table',
      label: label,
      columns: [],
    };
    setFormElements((prevElements) => {
      const newElements = [...prevElements, element];
      onSubmit(newElements);
      return newElements;
    });
    setOpenTableDialog(false);
  };

  const handleElementClick = (field_type) => {
    if (field_type === 'section_break') {
      setOpenSectionBreakDialog(true);
    } else if (field_type === 'description' || field_type === 'signature') {
      const baseName = field_type;
      let counter = 1;
      let elementName = baseName;
  
      while (formElements.some((el) => el.name === elementName)) {
        elementName = `${baseName}_${counter}`;
        counter++;
      }
  
      const element = {
        name: elementName,
        field_type,
        label: field_type === 'signature' ? 'Signature' : '',
        required: field_type === 'signature',
      };
  
      setFormElements((prevElements) => {
        const newElements = [...prevElements, element];
        onSubmit(newElements);
        return newElements;
      });
    } else if (field_type === 'table') {
      setOpenTableDialog(true);
    } else {
      setSelectedFieldType(field_type);
      setOpenDialog(true);
    }
  };

  // Function that handles the submission of the dialog for adding elements to the form builder
  const handleDialogSubmit = (element) => {
    setFormElements((prevElements) => {
      const newElements = [...prevElements];
  
      if (element.field_type === 'description' || element.field_type === 'signature') {
        // For description and signature elements, append a unique identifier to the name
        const baseName = element.field_type;
        let counter = 1;
  
        while (newElements.some((el) => el.name === element.name)) {
          element.name = `${baseName}_${counter}`;
          counter++;
        }
      } else {
        // For other elements, check for duplicate labels and append a number to the name
        const duplicateIndex = newElements.findIndex((el) => el.label.toLowerCase() === element.label.toLowerCase());
  
        if (duplicateIndex !== -1) {
          // If a duplicate label exists, append a number to the element name
          const baseName = element.name;
          let counter = 1;
  
          while (newElements.some((el) => el.name === element.name)) {
            element.name = `${baseName}_${counter}`;
            counter++;
          }
        }
      }
  
      newElements.push(element);
  
      console.log('FormBuilder - Element from Dialog:', element);
      console.log('FormBuilder - Updated Form Elements:', newElements);
      onSubmit(newElements); // Pass the updated elements back to the parent immediately
      return newElements;
    });
    setOpenDialog(false);
  };

  //Function for rendering form elements
  const renderFormElement = (element, index) => {
    switch (element.field_type) {
      case 'email':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            rules={{
              pattern: {
                value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                message: "Please enter a valid email"
              }
            }}
            render={({ field }) => (
              <TextInput 
                {...field} 
                type="email"
                label={element.label} 
                placeholder="Enter the assignee email" 
              />
            )}
          />
        );
      case 'text':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            render={({ field }) => <TextInput {...field} label={element.label} />}
          />
        );
      case 'checkbox':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue={[]}
            render={({ field: { onChange, value, ...field } }) => (
              <Checkbox
                {...field}
                label={element.label}
                options={element.options || []}
                onChange={onChange}
                value={value}
              />
            )}
          />
        );
      case 'section_break':
        return <SectionBreak name={element.name} label={element.label} />;
      case 'description':
        return <Description name={element.name} label={element.label} register={register}/>;   // Passing register to Description
      case 'date':
        return (
          <Controller 
            name={element.name}
            control={control}
            defaultValue=""
            render={({ field }) => <DatePicker {...field} label={element.label} />}
          />
        );
        case 'signature':
          return (
            <Controller
              key={index}
              name={element.name}
              control={control}
              render={({ field }) => (
                <Signature
                  {...field}
                  label={element.label}

                  setValue={setValue}
                  getValues={getValues}
                />
              )}
            />
          );
      case 'radio':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <RadioButton {...field} label={element.label} options={element.options || []} />
            )}
          />
        );
      case 'number':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            render={({ field }) => <NumberField {...field} label={element.label} />}
          />
        );
      case 'decimal':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            render={({ field }) => <DecimalField {...field} label={element.label} />}
          />
        );
      case 'table':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Table
                {...field}
                label={element.label}
                columns={element.columns}
                register={register} // Pass the register function here
                onAddColumnClick={() => { //Onclick function called upon the selection of the add row. 
                  setSelectedTableElement(element);
                  setSelectedFieldType('table-column');
                  setOpenTableColumnDialog(true);
                }}
                onUpdateColumns={(updatedColumns) => {
                  setFormElements((prevElements) => {
                    const newElements = prevElements.map((el) => {
                      if (el === element) {
                        return {
                          ...el,
                          columns: updatedColumns,
                        };
                      }
                      return el;
                    });
                    onSubmit(newElements);
                    return newElements;
                  });
                }}
              />
            )}
          />
        );
      case 'dropdown':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            render={({ field }) => (
              <Dropdown {...field} label={element.label} options={element.options || []} />
            )}
          />
        );
      case 'file':
        return (
          <Controller
            name={element.name}
            control={control}
            defaultValue=""
            render={({ field }) => <FileUpload {...field} label={element.label} />}
          />
        );
      default:
        return null;
    }
  };

  return (
    // ... code for rendering form elements panel. Each time a form Element is added it called the handleElementClick function
    <div className="create-slate-panel">
      <div className="header-container">
        <Tooltip title="Back to Templates">
          <IconButton onClick={() => returnTemplates()}>
            <ArrowBackIcon />
          </IconButton>
        </Tooltip>
        <Typography variant="h4">Build Template</Typography>
      </div>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black', marginLeft: '20px' , marginRight: '20px' }} />
    {/* <Grid container spacing={0}>
      <Grid className="elements-panel" item xs={2.5}> */}
      <Grid container spacing={0}>
        <Grid 
          className="elements-panel" 
          item 
          xs={2.5} 
          style={{ 
            top: `${Math.max(100 - scrollPosition, 20)}px`,
            transition: 'top 0.3s'
          }}
        >
        <div className="form-elements-panel">
          <Button
            variant="contained"
            startIcon={<TextFieldsIcon />}
            onClick={() => handleElementClick('text')}
            className="form-element-button"
            fullWidth
          >
            Text Input
          </Button>
          <Button
            variant="contained"
            startIcon={<CheckBoxIcon />}
            onClick={() => handleElementClick('checkbox')}
            className="form-element-button"
            fullWidth
          >
            Mutliple Choice
          </Button>
          <Button
            variant="contained"
            startIcon={<CalendarTodayIcon />}
            onClick={() => handleElementClick('date')}
            className="form-element-button"
            fullWidth
          >
            Date Picker
          </Button>
          <Button
            variant="contained"
            startIcon={<BorderColorIcon />}
            onClick={() => handleElementClick('signature')}
            className="form-element-button"
            fullWidth
          >
            Signature
          </Button>
          <Button
            variant="contained"
            startIcon={<RadioButtonCheckedIcon />}
            onClick={() => handleElementClick('radio')}
            className="form-element-button"
            fullWidth
          >
            Single Selection
          </Button>
          <Button
            variant="contained"
            startIcon={<FormatListNumberedIcon />}
            onClick={() => handleElementClick('number')}
            className="form-element-button"
            fullWidth
          >
            Integer Number
          </Button>
          <Button
            variant="contained"
            startIcon={<FormatListNumberedIcon />}
            onClick={() => handleElementClick('decimal')}
            className="form-element-button"
            fullWidth
          >
            Decimal Number
          </Button>
          <Button
          variant="contained"
          onClick={() => handleElementClick('section_break')}
          className="form-element-button"
          fullWidth
        >
          Section Break
        </Button>
        <Button
          variant="contained"
          onClick={() => handleElementClick('description')}
          className="form-element-button"
          fullWidth
        >
          Description
        </Button>
          <Button
            variant="contained"
            startIcon={<TableChartIcon />}
            onClick={() => handleElementClick('table')}
            className="form-element-button"
            fullWidth
          >
            Table
          </Button>
          {/* <Button
            variant="contained"
            startIcon={<ArrowDropDownCircleIcon />}
            onClick={() => handleElementClick('dropdown')}
            className="form-element-button"
            fullWidth
          >
            Dropdown
          </Button> */}
          <Button
            variant="contained"
            startIcon={<AttachFileIcon />}
            onClick={() => handleElementClick('file')}
            className="form-element-button"
            fullWidth
          >
            File Upload
          </Button>
        </div>
      </Grid>
      <Grid item xs={9} style={{ paddingLeft: '10px', paddingRight: '10px'}}>
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
      {/* <h1 className="form-preview-title">Slate Preview:</h1> */}
      </div>
        <div className="form-preview" ref={formPreviewRef} style={{ height: 'calc(100vh - 100px)', overflowY: 'auto' }}> 
        <TitleDescription
            title={title}
            description={description}
            onTitleChange={handleTitleChange}
            onDescriptionChange={handleDescriptionChange}
            control={control}
          />
          <DragDropContext onDragEnd={handleDragEnd}>
            <Droppable droppableId="form-elements">
              {(provided) => (
                <div {...provided.droppableProps} ref={provided.innerRef}>
                  {formElements.map((element, index) => (
                    <Draggable key={element.name} draggableId={element.name} index={index}>
                      {(provided) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          className="form-element-container"
                        >
                          <div className="form-element">{renderFormElement(element, index)}</div>
                          <IconButton
                            className="delete-button"
                            onClick={() => handleDeleteElement(index)}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      </Grid>
        <FormElementDialog
        open={openDialog || openTableColumnDialog}
        onClose={() => {
          setOpenDialog(false);
          setOpenTableColumnDialog(false);
        }}
        onSubmit={openTableColumnDialog ? handleTableColumnDialogSubmit : handleDialogSubmit}
        fieldType={selectedFieldType}
      />
        <SectionBreakDialog
        open={openSectionBreakDialog}
        onClose={() => setOpenSectionBreakDialog(false)}
        onSubmit={handleSectionBreakDialogSubmit}
      />
        <TableDialog
        open={openTableDialog}
        onClose={() => setOpenTableDialog(false)}
        onSubmit={handleTableDialogSubmit}
      />
    </Grid>
    </div>
  );
};

export default FormBuilder;

// import React, { useState, useEffect } from 'react';
// import { Button, Grid, IconButton, Typography } from '@mui/material';
// import TextFieldsIcon from '@mui/icons-material/TextFields';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
// import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
// import BorderColorIcon from '@mui/icons-material/BorderColor';
// import RadioButtonCheckedIcon from '@mui/icons-material/RadioButtonChecked';
// import TableChartIcon from '@mui/icons-material/TableChart';
// import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered';
// import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
// import AttachFileIcon from '@mui/icons-material/AttachFile';
// import DeleteIcon from '@mui/icons-material/Delete';
// import { useForm, Controller } from 'react-hook-form';
// import ArrowBackIcon from '@mui/icons-material/ArrowBack';
// import { useNavigate } from 'react-router-dom';
// import Tooltip from '@mui/material/Tooltip';
// import TitleDescription from './TitleDescription';
// import TextInput from './TextInput';
// import Checkbox from './Checkbox';
// import DatePicker from './DatePicker';
// import Signature from './Signature';
// import RadioButton from './RadioButton';
// import Table from './Table_Create';
// import NumberField from './NumberField';
// import DecimalField from './DecimalField';
// import Dropdown from './Dropdown';
// import FileUpload from './FileUpload';
// import SectionBreak from './SectionBreak';
// import Description from './Description';
// import FormElementDialog from './FormElementDialog';
// import TableDialog from './TableDialog';
// import SectionBreakDialog from './SectionBreakDialog';
// import { DragDropContext, Droppable, Draggable } from '@hello-pangea/dnd';

// const FormBuilder = ({ onTitleChange, onDescriptionChange, onSubmit, control, resetForm, register}) => {
//   const { setValue, getValues } = useForm();
//   const navigate = useNavigate();
//   const [formElements, setFormElements] = useState([]);
//   const [openDialog, setOpenDialog] = useState(false);
//   const [selectedFieldType, setSelectedFieldType] = useState('')
//   const [title, setTitle] = useState('');
//   const [description, setDescription] = useState('');
//   const [openSectionBreakDialog, setOpenSectionBreakDialog] = useState(false);
//   const [openTableDialog, setOpenTableDialog] = useState(false);

//   const [openTableColumnDialog, setOpenTableColumnDialog] = useState(false);
//   const [selectedTableElement, setSelectedTableElement] = useState(null);


//   const handleDragEnd = (result) => {
//     if (!result.destination) return;

//     const items = Array.from(formElements);
//     const [reorderedItem] = items.splice(result.source.index, 1);
//     items.splice(result.destination.index, 0, reorderedItem);

//     setFormElements(items);
//     onSubmit(items);
//   };

//   const returnTemplates = () => {
//     navigate('/slate-templates');
//   };


// //useEffect called to reset the form values visually
//   useEffect(() => {
//     if (resetForm) {
//       setTitle('');
//       setDescription('');
//       setFormElements([]);
//       // setResetTriggered(false);
//     }
//   }, [resetForm]);

//   // Manages the change of the title and sets the title variable value as per the user input
//   const handleTitleChange = (event) => {
//     setTitle(event.target.value);
//     onTitleChange(event.target.value); // Pass the title value to the parent component
//   };

//   // Manages the change of the description and sets the description variable value as per the user input
//   const handleDescriptionChange = (event) => {
//     setDescription(event.target.value);
//     onDescriptionChange(event.target.value); // Pass the description value to the parent component
//   };


// // Function for opening the SectionBreakDialog
//   const handleSectionBreakDialogSubmit = (element) => {
//     setFormElements((prevElements) => {
//       const newElements = [...prevElements, element];
//       onSubmit(newElements);
//       return newElements;
//     });
//     setOpenSectionBreakDialog(false);
//   };

//   // Function readjusts the form if the user deletes any form elements. 
//   // Takes the index of the form element to be deleted. It creates a new array updatedElements 
//   // by filtering out the element at the specified index using the filter method. 
//   const handleDeleteElement = (index) => {
//     // Remove the form element at the specified index
//     const updatedElements = formElements.filter((_, i) => i !== index);
//     setFormElements(updatedElements);
//     onSubmit(updatedElements); // Pass the updated elements back to the parent
//   };

//   const handleTableColumnDialogSubmit = (element) => {
//     setFormElements((prevElements) => {
//       const newElements = prevElements.map((el) => {
//         if (el === selectedTableElement) {
//           return {
//             ...el,
//             columns: [...el.columns, element],
//           };
//         }
//         return el;
//       });
//       onSubmit(newElements);
//       return newElements;
//     });
//     setOpenTableColumnDialog(false);
//     setSelectedTableElement(null);
//     setSelectedFieldType('');
//   };

//   //Function that is called and sets the table properties when a table is added to the form
//   const handleTableDialogSubmit = (label) => {
//     const element = {
//       name: label.replace(/\s+/g, '_').toLowerCase(), //for the name of the element set it to the lowercase of the label and substitute spaces with underscore
//       field_type: 'table',
//       label: label,
//       columns: [],
//     };
//     setFormElements((prevElements) => {
//       const newElements = [...prevElements, element];
//       onSubmit(newElements);
//       return newElements;
//     });
//     setOpenTableDialog(false);
//   };

//   const handleElementClick = (field_type) => {
//     if (field_type === 'section_break') {
//       setOpenSectionBreakDialog(true);
//     } else if (field_type === 'description' || field_type === 'signature') {
//       const baseName = field_type;
//       let counter = 1;
//       let elementName = baseName;
  
//       while (formElements.some((el) => el.name === elementName)) {
//         elementName = `${baseName}_${counter}`;
//         counter++;
//       }
  
//       const element = {
//         name: elementName,
//         field_type,
//         label: field_type === 'signature' ? 'Signature' : '',
//         required: field_type === 'signature',
//       };
  
//       setFormElements((prevElements) => {
//         const newElements = [...prevElements, element];
//         onSubmit(newElements);
//         return newElements;
//       });
//     } else if (field_type === 'table') {
//       setOpenTableDialog(true);
//     } else {
//       setSelectedFieldType(field_type);
//       setOpenDialog(true);
//     }
//   };

//   const handleDialogSubmit = (element) => {
//     setFormElements((prevElements) => {
//       const newElements = [...prevElements];
  
//       if (element.field_type === 'description' || element.field_type === 'signature') {
//         // For description and signature elements, append a unique identifier to the name
//         const baseName = element.field_type;
//         let counter = 1;
  
//         while (newElements.some((el) => el.name === element.name)) {
//           element.name = `${baseName}_${counter}`;
//           counter++;
//         }
//       } else {
//         // For other elements, check for duplicate labels and append a number to the name
//         const duplicateIndex = newElements.findIndex((el) => el.label.toLowerCase() === element.label.toLowerCase());
  
//         if (duplicateIndex !== -1) {
//           // If a duplicate label exists, append a number to the element name
//           const baseName = element.name;
//           let counter = 1;
  
//           while (newElements.some((el) => el.name === element.name)) {
//             element.name = `${baseName}_${counter}`;
//             counter++;
//           }
//         }
//       }
  
//       newElements.push(element);
  
//       console.log('FormBuilder - Element from Dialog:', element);
//       console.log('FormBuilder - Updated Form Elements:', newElements);
//       onSubmit(newElements); // Pass the updated elements back to the parent immediately
//       return newElements;
//     });
//     setOpenDialog(false);
//   };

//   //Function for rendering form elements
//   const renderFormElement = (element, index) => {
//     switch (element.field_type) {
//       case 'email':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             rules={{
//               pattern: {
//                 value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
//                 message: "Please enter a valid email"
//               }
//             }}
//             render={({ field }) => (
//               <TextInput 
//                 {...field} 
//                 type="email"
//                 label={element.label} 
//                 placeholder="Enter the assignee email" 
//               />
//             )}
//           />
//         );
//       case 'text':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             render={({ field }) => <TextInput {...field} label={element.label} />}
//           />
//         );
//       case 'checkbox':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue={[]}
//             render={({ field: { onChange, value, ...field } }) => (
//               <Checkbox
//                 {...field}
//                 label={element.label}
//                 options={element.options || []}
//                 onChange={onChange}
//                 value={value}
//               />
//             )}
//           />
//         );
//       case 'section_break':
//         return <SectionBreak name={element.name} label={element.label} />;
//       case 'description':
//         return <Description name={element.name} label={element.label} register={register}/>;   // Passing register to Description
//       case 'date':
//         return (
//           <Controller 
//             name={element.name}
//             control={control}
//             defaultValue=""
//             render={({ field }) => <DatePicker {...field} label={element.label} />}
//           />
//         );
//         case 'signature':
//           return (
//             <Controller
//               key={index}
//               name={element.name}
//               control={control}
//               render={({ field }) => (
//                 <Signature
//                   {...field}
//                   label={element.label}

//                   setValue={setValue}
//                   getValues={getValues}
//                 />
//               )}
//             />
//           );
//       case 'radio':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             render={({ field }) => (
//               <RadioButton {...field} label={element.label} options={element.options || []} />
//             )}
//           />
//         );
//       case 'number':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             render={({ field }) => <NumberField {...field} label={element.label} />}
//           />
//         );
//       case 'decimal':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             render={({ field }) => <DecimalField {...field} label={element.label} />}
//           />
//         );
//       case 'table':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             render={({ field }) => (
//               <Table
//                 {...field}
//                 label={element.label}
//                 columns={element.columns}
//                 register={register} // Pass the register function here
//                 onAddColumnClick={() => { //Onclick function called upon the selection of the add row. 
//                   setSelectedTableElement(element);
//                   setSelectedFieldType('table-column');
//                   setOpenTableColumnDialog(true);
//                 }}
//               />
//             )}
//           />
//         );
//       case 'dropdown':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             render={({ field }) => (
//               <Dropdown {...field} label={element.label} options={element.options || []} />
//             )}
//           />
//         );
//       case 'file':
//         return (
//           <Controller
//             name={element.name}
//             control={control}
//             defaultValue=""
//             render={({ field }) => <FileUpload {...field} label={element.label} />}
//           />
//         );
//       default:
//         return null;
//     }
//   };

//   return (
//     // ... code for rendering form elements panel. Each time a form Element is added it called the handleElementClick function
//     <div className="create-slate-panel">
//       <div className="header-container">
//         <Tooltip title="Back to Templates">
//           <IconButton onClick={() => returnTemplates()}>
//             <ArrowBackIcon />
//           </IconButton>
//         </Tooltip>
//         <Typography variant="h4">Build Template</Typography>
//       </div>
//       <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black', marginLeft: '20px' , marginRight: '20px' }} />
//     <Grid container spacing={0}>
//       <Grid className="elements-panel" item xs={2.5}>
//         <div className="form-elements-panel">
//           <Button
//             variant="contained"
//             startIcon={<TextFieldsIcon />}
//             onClick={() => handleElementClick('text')}
//             className="form-element-button"
//             fullWidth
//           >
//             Text Input
//           </Button>
//           <Button
//             variant="contained"
//             startIcon={<CheckBoxIcon />}
//             onClick={() => handleElementClick('checkbox')}
//             className="form-element-button"
//             fullWidth
//           >
//             Mutliple Choice
//           </Button>
//           <Button
//             variant="contained"
//             startIcon={<CalendarTodayIcon />}
//             onClick={() => handleElementClick('date')}
//             className="form-element-button"
//             fullWidth
//           >
//             Date Picker
//           </Button>
//           <Button
//             variant="contained"
//             startIcon={<BorderColorIcon />}
//             onClick={() => handleElementClick('signature')}
//             className="form-element-button"
//             fullWidth
//           >
//             Signature
//           </Button>
//           <Button
//             variant="contained"
//             startIcon={<RadioButtonCheckedIcon />}
//             onClick={() => handleElementClick('radio')}
//             className="form-element-button"
//             fullWidth
//           >
//             Single Selection
//           </Button>
//           <Button
//             variant="contained"
//             startIcon={<FormatListNumberedIcon />}
//             onClick={() => handleElementClick('number')}
//             className="form-element-button"
//             fullWidth
//           >
//             Integer Number
//           </Button>
//           <Button
//             variant="contained"
//             startIcon={<FormatListNumberedIcon />}
//             onClick={() => handleElementClick('decimal')}
//             className="form-element-button"
//             fullWidth
//           >
//             Decimal Number
//           </Button>
//           <Button
//           variant="contained"
//           onClick={() => handleElementClick('section_break')}
//           className="form-element-button"
//           fullWidth
//         >
//           Section Break
//         </Button>
//         <Button
//           variant="contained"
//           onClick={() => handleElementClick('description')}
//           className="form-element-button"
//           fullWidth
//         >
//           Description
//         </Button>
//           <Button
//             variant="contained"
//             startIcon={<TableChartIcon />}
//             onClick={() => handleElementClick('table')}
//             className="form-element-button"
//             fullWidth
//           >
//             Table
//           </Button>
//           {/* <Button
//             variant="contained"
//             startIcon={<ArrowDropDownCircleIcon />}
//             onClick={() => handleElementClick('dropdown')}
//             className="form-element-button"
//             fullWidth
//           >
//             Dropdown
//           </Button> */}
//           <Button
//             variant="contained"
//             startIcon={<AttachFileIcon />}
//             onClick={() => handleElementClick('file')}
//             className="form-element-button"
//             fullWidth
//           >
//             File Upload
//           </Button>
//         </div>
//       </Grid>
//       <Grid item xs={9} style={{ paddingLeft: '10px', paddingRight: '10px'}}>
//       <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
//       {/* <h1 className="form-preview-title">Slate Preview:</h1> */}
//       </div>
//         <div className="form-preview">
//         <TitleDescription
//             title={title}
//             description={description}
//             onTitleChange={handleTitleChange}
//             onDescriptionChange={handleDescriptionChange}
//             control={control}
//           />
//           <DragDropContext onDragEnd={handleDragEnd}>
//             <Droppable droppableId="form-elements">
//               {(provided) => (
//                 <div {...provided.droppableProps} ref={provided.innerRef}>
//                   {formElements.map((element, index) => (
//                     <Draggable key={element.name} draggableId={element.name} index={index}>
//                       {(provided) => (
//                         <div
//                           ref={provided.innerRef}
//                           {...provided.draggableProps}
//                           {...provided.dragHandleProps}
//                           className="form-element-container"
//                         >
//                           <div className="form-element">{renderFormElement(element, index)}</div>
//                           <IconButton
//                             className="delete-button"
//                             onClick={() => handleDeleteElement(index)}
//                           >
//                             <DeleteIcon />
//                           </IconButton>
//                         </div>
//                       )}
//                     </Draggable>
//                   ))}
//                   {provided.placeholder}
//                 </div>
//               )}
//             </Droppable>
//           </DragDropContext>
//         </div>
//       </Grid>
//         <FormElementDialog
//         open={openDialog || openTableColumnDialog}
//         onClose={() => {
//           setOpenDialog(false);
//           setOpenTableColumnDialog(false);
//         }}
//         onSubmit={openTableColumnDialog ? handleTableColumnDialogSubmit : handleDialogSubmit}
//         fieldType={selectedFieldType}
//       />
//         <SectionBreakDialog
//         open={openSectionBreakDialog}
//         onClose={() => setOpenSectionBreakDialog(false)}
//         onSubmit={handleSectionBreakDialogSubmit}
//       />
//         <TableDialog
//         open={openTableDialog}
//         onClose={() => setOpenTableDialog(false)}
//         onSubmit={handleTableDialogSubmit}
//       />
//     </Grid>
//     </div>
//   );
// };

// export default FormBuilder;