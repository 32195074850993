// Import necessary dependencies from React and Material-UI libraries
import React, { useState, useContext, useEffect } from "react";
import {
  Typography,
  CircularProgress,
  Snackbar,
  Alert,
  IconButton,
  Button,
  Tooltip,
} from "@mui/material";
import { UserContext } from "../components/UserContext";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useAuth0 } from '@auth0/auth0-react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate } from 'react-router-dom';
import { PaymentFetch } from "../components/Backend Routes/fetchPayment";
import PaymentDialog from '../components/Company Profile Components/PaymentDialog';

// Define the columns for the DataGrid component
const columns: GridColDef[] = [
  { field: "bank", headerName: "Bank Name", flex: 1.5, minWidth: 250 },
  { field: "bank_address", headerName: "Bank Address", flex: 3, minWidth: 300 },
  { field: "sort_code", headerName: "Sort Code", flex: 1.5, minWidth: 250 },
  { field: "account_number", headerName: "Account Number", flex: 1.5, minWidth: 300 },
  { field: "terms", headerName: "Payment Terms", flex: 1.5, minWidth: 250 },
];

const PaymentDetails = () => {
  // Get authentication and user context
  const { user } = useAuth0();
  const { isPremiumUser, premiumKey } = useContext(UserContext);
  
  // Fetch initial payment data
  const { payment, loading, error } = PaymentFetch(premiumKey);
  
  // Navigation hook for routing
  const navigate = useNavigate();

  // State for managing local payment data
  const [paymentData, setPaymentData] = useState(null);
  
  // State for managing the add/edit popup
  const [openAddEditPopup, setOpenAddEditPopup] = useState(false);

  // Add isSubmitting state
  const [isSubmitting, setIsSubmitting] = useState(false);
  
  // State for managing the form data in the popup
  const [editedPayment, setEditedPayment] = useState({
    owner_org: premiumKey,
    bank: "",
    bank_address: "",
    sort_code: "",
    account_number: "",
    terms: "",
  });

  // State for managing snackbar notifications
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    severity: "success"
  });

  // Effect to initialize paymentData when payment data is fetched
  useEffect(() => {
    if (payment) {
      setPaymentData(payment);
    }
  }, [payment]);

  // Handler for opening the add/edit popup
  const handleAddEdit = () => {
    // Pre-fill form with existing data or empty values
    setEditedPayment(paymentData ? 
      { ...paymentData, owner_org: premiumKey } : 
      {
        owner_org: premiumKey,
        bank: "",
        bank_address: "",
        sort_code: "",
        account_number: "",
        terms: "",
      }
    );
    setOpenAddEditPopup(true);
  };

  // Handler for form field changes
  const handleChange = (field, value) => {
    setEditedPayment(prevPayment => ({
      ...prevPayment,
      [field]: value,
    }));
  };

  // Handler for form submission
  const handleSubmit = async () => {
    if (isSubmitting) return;

    setIsSubmitting(true);
    const paymentToSubmit = {
      ...editedPayment,
    };
    
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/company/payment-details/?owner=${premiumKey}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(paymentToSubmit),
      });

      if (!response.ok) {
        throw new Error('Failed to update payment details');
      }

      setPaymentData(paymentToSubmit);
      setSnackbar({
        open: true,
        message: "Payment details updated successfully",
        severity: "success"
      });
      setOpenAddEditPopup(false);
      
    } catch (error) {
      console.error("Error updating payment details:", error);
      setSnackbar({
        open: true,
        message: "Failed to update payment details",
        severity: "error"
      });
    } finally {
      setIsSubmitting(false);
    }
  };

  // Handler for closing the snackbar
  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  // Handler for returning to profile page
  const returnProfile = () => {
    navigate('/profile');
  };

  // Prepare rows for DataGrid - now using paymentData instead of payment
  const rows = paymentData ? [{ id: 1, ...paymentData }] : [];

  return (
    <div className="page-container">
      {/* Back button with tooltip */}
      <Tooltip title="Back to Payment Profile">
        <IconButton onClick={returnProfile}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>

      {/* Page title */}
      <Typography variant="h4">Payment Details</Typography>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />

      <div className="payment-details-container">
        {/* Show loading spinner while data is being fetched */}
        {loading ? (
          <CircularProgress />
        ) : error ? (
          // Show error message if fetch failed
          <Alert severity="error">{error}</Alert>
        ) : (
          <>
            {/* Add/Edit button */}
            <div style={{ marginBottom: '16px' }}>
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddEdit}
                className="standard-button"
                style={{ marginRight: '15px' }}
              >
                {paymentData ? "Edit Payment Details" : "Add Payment Details"}
              </Button>
            </div>

            {/* DataGrid displaying payment details */}
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={1}
                disableSelectionOnClick
                sx={{
                  '& .MuiDataGrid-columnHeader': {
                    backgroundColor: 'Black',
                    textAlign: 'center',
                    fontSize: '18px',
                    color: 'white',
                  },
                }}
              />
            </div>
          </>
        )}
      </div>

      {/* Add/Edit Payment Details Dialog */}
      <PaymentDialog
        open={openAddEditPopup}
        onClose={() => !isSubmitting && setOpenAddEditPopup(false)}
        onSubmit={handleSubmit}
        editedPayment={editedPayment}
        onFieldChange={handleChange}
        isSubmitting={isSubmitting}
        isEdit={!!paymentData}
      />

      {/* Snackbar for displaying success/error messages */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={4000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert 
          onClose={handleCloseSnackbar} 
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default PaymentDetails;