// DatePicker.js
import React from 'react';
import { TextField, Typography} from '@mui/material';

const DatePicker = React.forwardRef(({ name, label, required, ...props }, ref) => {
  return (
    <div>
      <Typography variant="subtitle1" className="form-element-label">
          {label}
      </Typography>
      <TextField
        type="date"
        id={name}
        name={name}
        inputRef={ref}
        required={required}
        InputLabelProps={{
          shrink: true,
        }}
        {...props}
      />
    </div>
  );
});

export default DatePicker;

// // DatePicker.js
// import React from 'react';

// const DatePicker = React.forwardRef(({ name, label, required, ...props }, ref) => {
//   return (
//     <div className="form-element">
//       <label htmlFor={name}>{label}</label>
//       <input type="date" id={name} name={name} ref={ref} required={required} {...props} />
//     </div>
//   );
// });

// export default DatePicker;