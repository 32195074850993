import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { Box, Container, Typography, Grid } from '@mui/material';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

const Footer = () => {
  const sections = ['Key Features', 'FAQ', 'Pricing'];
  const sectionLinks = ['#key-features', '#faq', '#pricing'];
  const currentYear = new Date().getFullYear();

  return (
    <Box component="footer" sx={{ bgcolor: '#F4C50B', py: 2 }}>
      <Container maxWidth="lg">
        <Grid container spacing={4} justifyContent="space-between">
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              SiteSteer
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Your Ultimate Compliance Zone
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Quick Links
            </Typography>
            <Box>
              {sections.map((section, index) => (
                <Link
                  key={section}
                  to={sectionLinks[index]}
                  style={{ color: 'inherit', textDecoration: 'none', display: 'block', marginBottom: '0.5rem' }}
                >
                  {section}
                </Link>
              ))}
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Legal
            </Typography>
            <Link to="/privacy-notice" style={{ color: 'inherit', textDecoration: 'none', display: 'block', marginBottom: '0.5rem' }}>
              Privacy Policy
            </Link>
            <Link to="/terms" style={{ color: 'inherit', textDecoration: 'none', display: 'block', marginBottom: '0.5rem' }}>
              Terms of Service
            </Link>
          </Grid>
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h6" color="text.primary" gutterBottom>
              Follow Us
            </Typography>
            <Box>
              {/* <Link to="https://twitter.com/sitesteer" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit', marginRight: '1rem' }}>
                <TwitterIcon />
              </Link> */}
              <Link to="https://www.linkedin.com/company/sitesteer" target="_blank" rel="noopener noreferrer" style={{ color: 'inherit' }}>
                <LinkedInIcon />
              </Link>
            </Box>
          </Grid>
        </Grid>
        <Box mt={0}>
          <Typography variant="body2" color="text.secondary" align="center">
            {"Copyright © "}
            {currentYear}
            {" SiteSteer AI. All rights reserved."}
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;