// hooks/useCompanyLookup.js
/**
 * Create a mapping from companyId to companyName for quick lookup.
 * Utilizes React's useMemo for performance optimization.
 */
import { useMemo } from 'react';

export function useCompanyLookup(data) {
  return useMemo(() => {
    return data.reduce((acc, item) => {
      if (item.companyId && item.companyName) {
        acc[item.companyId] = item.companyName;
      }
      return acc;
    }, {});
  }, [data]);
}