import React, { useState } from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { useNavigate } from 'react-router-dom';

const PricingSection = () => {
  // State to track if monthly or yearly pricing is selected
  const [isMonthly, setIsMonthly] = useState(true);
  const navigate = useNavigate();

  const registerInterest = () => {
    navigate('/early-signup');
  };

  // Dummy pricing data
  const pricingPlans = [
    {
      name: 'Free',
      description: "For companies to discover Site Steer's value.",
      price: { monthly: 'Free trial', yearly: 'N/A' },
      features: [
        'A maximum 10 team members',
        'Unlimited Projects',
        'Umlimited Slate Templates',
        'Audit Hub Dashboard',
      ],
      buttonText: 'Try now',
      buttonAction: () => registerInterest(),
    },
    {
      name: 'Team',
      description: 'For small-medium contractors to embed Site Steer into their workflow',
      price: { monthly: 8, yearly: 80 },
      features: [
        'Team size dependent on number of licenses',
        'Unlimited Projects',
        'Umlimited Slate Templates',
        'Audit Hub Dashboard',
      ],
      buttonText: 'Sign up',
      buttonAction: () => console.log('Team plan selected'),
      mostPopular: true,
    },
    {
      name: 'Enterprise',
      description: 'For large contractors who require a large amount of licenses',
      price: { monthly: 'Custom', yearly: 'Custom' },
      features: [
        'Team size dependent on number of licenses',
        'Unlimited Projects',
        'Umlimited Slate Templates',
        'Audit Hub Dashboard',
      ],
      buttonText: 'Contact sales',
      buttonAction: () => console.log('Enterprise plan selected'),
    },
  ];

  return (
    <section id="pricing" className="pricing-section">
        <div className="pricing-section-content">
            <h2 className="pricing-title">Pricing Plan</h2>
            <p className="pricing-description">
                Choose the plan that best suits your organization. If you're unsure try Site Steer for 1 month for free!
            </p>

            {/* Pricing toggle */}
            <div className="pricing-toggle">
                <button
                className={`toggle-button ${isMonthly ? 'active' : ''}`}
                onClick={() => setIsMonthly(true)}
                >
                Monthly
                </button>
                <button
                className={`toggle-button ${!isMonthly ? 'active' : ''}`}
                onClick={() => setIsMonthly(false)}
                >
                Annually
                </button>
            </div>

            {/* Pricing plans */}
            <div className="pricing-plans">
                {pricingPlans.map((plan, index) => (
                <div
                    key={index}
                    // className={`pricing-plan ${plan.mostPopular ? 'most-popular' : ''}`}
                    className={`pricing-plan ${!isMonthly && index === 0 ? 'disabled' : ''}`}
                >
                    <h3>{plan.name}</h3>
                    <p>{plan.description}</p>
                    <div className="plan-price">
                    {typeof plan.price[isMonthly ? 'monthly' : 'yearly'] === 'number' ? (
                        <>
                        <span className="currency">£</span>
                        <span className="amount">
                            {plan.price[isMonthly ? 'monthly' : 'yearly']}
                        </span>
                        <span className="period">/{isMonthly ? 'user monthly' : 'user yearly'}</span>
                        </>
                    ) : (
                        <span className="amount">
                        {plan.price[isMonthly ? 'monthly' : 'yearly']}
                        </span>
                    )}
                    </div>
                    <button
                    className="plan-button"
                    onClick={plan.buttonAction}
                    >
                    {plan.buttonText}
                    </button>
                    <ul className="feature-list">
                    {plan.features.map((feature, featureIndex) => (
                        <li key={featureIndex}>
                        <CheckIcon className="check-icon" />
                        {feature}
                        </li>
                    ))}
                    </ul>
                </div>
                ))}
            </div>
        </div>
    </section>
  );
};

export default PricingSection;