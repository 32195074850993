// Profile.jsx
import React, { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Button, Grid, Snackbar, IconButton, Typography, Box } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import InfoIcon from '@mui/icons-material/Info';
import ContactPageIcon from '@mui/icons-material/ContactPage';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DescriptionIcon from '@mui/icons-material/Description';
import { useNavigate } from 'react-router-dom';

const ProfilePage = () => {
  const { control, handleSubmit, reset } = useForm();
  const { user } = useAuth0();
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const url = `${process.env.REACT_APP_API_BASE_URL}/${user.sub}/`;
  const navigate = useNavigate();

  const iconButtons = [
    { icon: <InfoIcon />, text: 'Company Details', path: '/company-details' },
    { icon: <AccountBalanceIcon />, text: 'Payment Details', path: '/payment-details' },
    { icon: <ContactPageIcon />, text: 'Manage Team', path: '/manage-team' },
    // { icon: <PriceCheckIcon />, text: 'Standard Pricing', path: '/pricing' },
    // { icon: <DescriptionIcon />, text: 'Slate Templates', path: '/slate-templates' },
    // { icon: <PictureAsPdfIcon />, text: 'Documentation Templates', path: '/doc-templates' },
    
  ];

  const handleNavigate = (path) => {
    navigate(path);
  };

  return (
    <Box sx={{ padding: 4 }}>
      <Typography variant="h4">Company Profile</Typography>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black',  marginBottom: '16px' }} />

      <Grid container spacing={4} justifyContent="center">
        {iconButtons.map((item, index) => (
          <Grid item xs={12} sm={4} key={index} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Button
              onClick={() => handleNavigate(item.path)}
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                padding: 2,
                borderRadius: 2,
                transition: 'background-color 0.3s',
                '&:hover': {
                  backgroundColor: 'rgba(0, 0, 0, 0.1)',
                },
                color: 'black', // Set text color to black
              }}
            >
              <Box sx={{ fontSize: 80, mb: 1, color: 'black' }}>
                {React.cloneElement(item.icon, { fontSize: 'inherit' })}
              </Box>
              <Typography sx={{ fontSize: '1.2rem', fontWeight: 'bold' }}>{item.text}</Typography>
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default ProfilePage;