// // SectionBreak.js
// import React from 'react';
// import { TextField } from '@mui/material';

// const SectionBreak = ({ name, label, control, register, required }) => {
//   return (
//     <div style={{ marginBottom: '16px' }}>
//       <TextField
//         name={name}
//         label={label}
//         variant="outlined"
//         fullWidth
//         InputProps={{
//           readOnly: true,
//         }}
//         {...register(name, { required })}
//       />
//       <hr style={{ borderTop: '2px solid black', margin: '8px 0' }} />
//     </div>
//   );
// };

// export default SectionBreak;

// SectionBreak.js
import React from 'react';
import { Typography } from '@mui/material';

const SectionBreak = ({ label }) => {
  return (
    <div >
      <Typography variant="h6" component="div" style={{ marginBottom: '8px' }}>
        {label}
      </Typography>
      <hr style={{ borderTop: '2px solid black' }} />
    </div>
  );
};

export default SectionBreak;