// ProjectFetch.js
import { useState, useEffect } from "react";

export const ProjectFetch = (url, owner) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchProjects = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${url}?owner=${owner}`);
      const data = await response.json();
      // console.log("project data", data)
      setProjects(data.user_projects || []);
      setError(null);
    } catch (err) {
      setError(err.message);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProjects();
  }, [url, owner]);

  const refetchProjects = () => {
    fetchProjects();
  };

  return { projects, loading, error, refetchProjects };
};
