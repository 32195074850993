// import React, { useState, useEffect } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

// // Set up the worker
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// const PrivacyNotice = () => {
//   const [numPages, setNumPages] = useState(null);
//   const [pdfUrl, setPdfUrl] = useState(null);
//   const [loading, setLoading] = useState(true);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchPdfUrl = async () => {
//       try {
//         setLoading(true);
//         const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/privacy-notice-url`);
//         // console.log(response)
//         if (!response.ok) {
//           throw new Error('Failed to fetch PDF URL');
//         }
//         const data = await response.json();
//         console.log(data.url)
//         setPdfUrl(data.url);
//         setLoading(false);
//       } catch (error) {
//         console.error('Error fetching PDF URL:', error);
//         setError(error.message);
//       } finally {
//         setLoading(false);
//       }
//     };

//     fetchPdfUrl();
//   }, []);

//   function onDocumentLoadSuccess({ numPages }) {
//     setNumPages(numPages);
//   }

//   if (loading) return <div>Loading...</div>;
//   if (error) return <div>Error: {error}</div>;

//   return (
//     <div>
//       <h1>Privacy Notice</h1>
//       {pdfUrl && (
//         <Document
//           file={pdfUrl}
//           onLoadSuccess={onDocumentLoadSuccess}
//           onLoadError={(error) => console.error('Error loading PDF:', error)}
//         >
//           {Array.from(new Array(numPages), (el, index) => (
//             <Page key={`page_${index + 1}`} pageNumber={index + 1} />
//           ))}
//         </Document>
//       )}
//     </div>
//   );
// };

// export default PrivacyNotice;
import React, { useState, useEffect } from 'react';

const PrivacyNotice = () => {
  const [pdfUrl, setPdfUrl] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchPdfUrl = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/privacy-notice-url`);
        if (!response.ok) {
          throw new Error('Failed to fetch PDF URL');
        }
        const data = await response.json();
        setPdfUrl(data.url);
      } catch (error) {
        console.error('Error fetching PDF URL:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchPdfUrl();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error: {error}</div>;

  return (
    <div style={{ width: '100%', height: '100vh' }}>
      {pdfUrl && (
        <iframe
          src={pdfUrl}
          width="100%"
          height="90%"
          style={{ border: 'none' }}
          title="Privacy Notice PDF"
        >
          Your browser does not support iframes. Please <a href={pdfUrl}>click here to view the PDF</a>.
        </iframe>
      )}
    </div>
  );
};

export default PrivacyNotice;