// TemplatePreview.js
import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { FormProvider, useForm } from 'react-hook-form';
import RenderSlatePreview from './RenderSlatePreview'; // Extract the renderFormElement function from YourForms

const TemplatePreview = ({ open, onClose, template }) => {
  const methods = useForm();
  const { register } = methods; // Destructure the register function from the methods object

  const getInitialRows = (element) => {
    if (element.field_type === 'table' && template.data[element.name]?.length) {
      return template.data[element.name].length;
    }
    return 1;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent dividers style={{ maxHeight: '70vh', overflow: 'auto' }}>
        {template && (
          <div className="form-element-container">
            <Typography className="form-title" variant="h4" gutterBottom>
              {template.title}
            </Typography>
            <Typography className="form-description" variant="subtitle1" gutterBottom>
              {template.description}
            </Typography>
            <FormProvider {...methods}>
              {/* {template.fields.map((element, index) => ( */}
              {template.fields.map((element, index) => (  
                <div key={element.name} className="form-element">
                  {console.log('element:', element)}
                  {RenderSlatePreview(element, index, methods.control, register, template.data, getInitialRows(element))}
                </div>
              ))}
            </FormProvider>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TemplatePreview;