import React, { useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Button } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import Signature from './Signature';

const SignatureDialog = ({ open, onClose, onSubmit }) => {
  const { control, setValue, getValues, reset } = useForm();

  useEffect(() => {
    if (open) {
      reset({ signature: '' });
    }
  }, [open, reset]);

  const handleSave = () => {
    const signatureData = getValues("signature");
    console.log('signatureData', signatureData)
    onSubmit(signatureData);
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Controller
          name="signature"
          control={control}
          render={({ field }) => (
            <Signature
              {...field}
              setValue={setValue}
              getValues={getValues}
              label="Signature"
              required
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">Cancel</Button>
        <Button onClick={handleSave} color="primary">Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default SignatureDialog;



// import React, { useState } from 'react';
// import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
// import Signature from './Signature';

// const SignatureDialog = ({ open, onClose, onSubmit }) => {
//   const [signatureData, setSignatureData] = useState(''); // State to store the signature data


//   // Function to handle signature change
//   const handleSignatureChange = (data) => {
//     setSignatureData(data);
//   };

//   // Function to handle form submission
//   const handleSubmit = () => {
//     onSubmit(signatureData);
//   };

//   return (
//     <Dialog open={open} onClose={onClose}>
//       <DialogTitle>Signature</DialogTitle>
//       <DialogContent>
//         <Signature onSignatureChange={handleSignatureChange} />
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose}>Cancel</Button>
//         <Button onClick={handleSubmit} variant="contained" color="primary">
//           Submit
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default SignatureDialog;
