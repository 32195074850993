import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress
} from "@mui/material";

const ProspectsDialog = ({
  open,
  onClose,
  onSubmit,
  editedItem,
  onFieldChange,
  crmCompanies,
  isSubmitting
}) => {
  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      disableEscapeKeyDown={isSubmitting}
    >
      <DialogTitle>
        {editedItem && editedItem.id !== undefined
          ? "Edit Prospect"
          : "Add Prospect"}
      </DialogTitle>
      <DialogContent>
        {/* Company Name Selection */}
        <FormControl fullWidth margin="normal">
          <InputLabel>Company Name</InputLabel>
          <Select
            value={editedItem.companyId || ""}
            onChange={(e) => onFieldChange("companyId", e.target.value)}
            label="Company Name"
            required
            disabled={isSubmitting}
          >
            {Array.isArray(crmCompanies)
              ? crmCompanies.map((company) => (
                  <MenuItem
                    key={company.companyId}
                    value={company.companyId}
                  >
                    {company.customer_name}
                  </MenuItem>
                ))
              : null}
          </Select>
        </FormControl>

        {/* Project Name Input */}
        <TextField
          label="Project Name"
          value={editedItem?.projectName || ""}
          onChange={(e) => onFieldChange("projectName", e.target.value)}
          fullWidth
          margin="normal"
          required
          disabled={isSubmitting}
        />

        {/* Project Address Input */}
        <TextField
          label="Project Address"
          value={editedItem?.site_address || ""}
          onChange={(e) => onFieldChange("site_address", e.target.value)}
          fullWidth
          margin="normal"
          required
          disabled={isSubmitting}
        />

        {/* Status Input */}
        <TextField
          select
          label="Status"
          value={editedItem?.status || ""}
          onChange={(e) => onFieldChange("status", e.target.value)}
          fullWidth
          margin="normal"
          required
          disabled={isSubmitting}
        >
          <MenuItem value="No Quotes">No Quotes</MenuItem>
          <MenuItem value="Quoted">Quoted</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          color="primary"
          disabled={isSubmitting}
          sx={{ 
            minWidth: 80,
            position: 'relative'
          }}
        >
          {isSubmitting ? (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                left: '50%',
                marginLeft: '-12px'
              }}
            />
          ) : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProspectsDialog;