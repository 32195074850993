// export default Root;
import React from 'react';
import { HashLink as Link } from 'react-router-hash-link';
import { useAuth0 } from "@auth0/auth0-react";
import FadeInText from './Homepage/FadeInText';
import KeyFeaturesSection from './Homepage/KeyFeaturesSection'; // Import the new component
import CustomisableSlatesSection from './Homepage/CustomisableSlatesSection';
import PushNotificationsSection from './Homepage/PushNotificationsSection';
import AuditHubDashboard from './Homepage/AuditHub';
import FAQ from './Homepage/FAQ';
import Pricing from './Homepage/Pricing';
import Footer from './Homepage/Footer';
import { useNavigate } from 'react-router-dom';
import logo from './images/Sitesteer main logo.png'; 
import constructionImage from './images/jobsite_sample.jpeg'; 
import QueryStatsIcon from './images/analytics_icon.png';
import AssignmentIcon from './images/customisable_slates_icon.png'; 
import ScheduleIcon from './images/scheduleworkflows_icon.png'; 
import NotificationsActiveIcon from './images/notifications_icon.png'; 
import './App.css';

const Feature = ({ imageSrc, title, description }) => (
  <div className="feature">
    <div className="feature-text">
      <h3>{title}</h3>
      <p>{description}</p>
    </div>
    <img src={imageSrc} className="feature-icon" />
  </div>
);


const Root = () => {
  const { isAuthenticated } = useAuth0();
  const navigate = useNavigate();
  
  const features = [
    { imageSrc: QueryStatsIcon, title: 'Audit Hub', description: 'Centralized audit management' },
    { imageSrc: AssignmentIcon, title: 'Custom Slates', description: 'Tailored compliance forms' },
    { imageSrc: ScheduleIcon, title: 'Schedule Work', description: 'Automated task management' },
    { imageSrc: NotificationsActiveIcon, title: 'Push Notifications', description: 'Real-time updates' },
  ];

  if (isAuthenticated) {
    return (
      <div className="root-container">
        <div className="loggedin-content">
          <h1>Welcome to SiteSteer</h1>
          <p>You are logged in. Please use the navigation menu to access different parts of the application.</p>
        </div>
      </div>
    );
  }

  const registerInterest = () => {
    navigate('/early-signup');
  };

  return (
    <div className="root-container">
      {/* hero sub-section 1 */}
      <section className="hero-section-colour">
        <div className="hero-section">
        <hr style={{ borderBottom: '2px black' }} />
        <h1>Embrace the Future of Construction!</h1>
        <h2>Your Ultimate Compliance Zone</h2>
        <p>Simplify the overview of how site is performing</p>
        <hr style={{ borderBottom: '2px black'}} />
        </div>
      </section>
      <div className="features-wrapper">
        <section className="features-section">
          <h2>Features</h2>
          <div className="features-grid">
            {features.map((feature, index) => (
              <Feature key={index} {...feature} />
            ))}
          </div>
        </section>
      </div>
      <div className="register-button-wrapper">
        <button className="register-button" onClick={() => registerInterest()}>Register Interest</button>
      </div>
    {/* hero sub-section 2 */}
    <section className="software-solution-section">
    <div className="software-solution-content">
      <div className="software-solution-text">
        <FadeInText>
          <h2>A simple but powerful Software Solution</h2>
        </FadeInText>
        <FadeInText>
          <p>"It's your all-in-one solution for managing mobile fleets, ensuring timely inspections, and achieving zero tolerance for missed tasks."</p>
        </FadeInText>
        <img src={logo} alt="Site Steer Logo" className="site-steer-logo" />
        <FadeInText>
          <p>"SITE STEER empowers construction companies to stay compliant and efficient by streamlining inspections, automating follow-ups, and prioritizing what needs to be done."</p>
        </FadeInText>
      </div>
      <div className="software-solution-image">
        <img src={constructionImage} alt="Construction Site" />
      </div>
    </div>
    </section>
    <KeyFeaturesSection />
    <CustomisableSlatesSection />
    <AuditHubDashboard />
    <PushNotificationsSection />
    <FAQ />
    <Pricing />
    <Footer />
  </div>
  );
};

export default Root;
