import React from "react";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { Navigate } from "react-router-dom";

const AdminRoute = ({ component, ...args }) => {
  const { isLoading, isAuthenticated, user } = useAuth0();

  if (isLoading || !isAuthenticated) {
    return <div>Loading...</div>;
  }

  const roles = user && user["https://dev-w4whsk5o7v5u5s56.us.auth0.com$0/roles"];

  if (!roles) {
    return <Navigate to="/unauthorized" />;
  }

  const Component = withAuthenticationRequired(component, args);

  return roles.includes("SiteSteer Full Admin") ? (
    <Component />
  ) : (
    <Navigate to="/unauthorized" />
  );
};

export default AdminRoute;
