// SectionBreakDialog.js
import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

const SectionBreakDialog = ({ open, onClose, onSubmit }) => {
  const [label, setLabel] = useState('');

  useEffect(() => {
    if (open) {
      // Reset form fields when the dialog is opened
      setLabel('');
    }
  }, [open]);

  const handleLabelChange = (e) => {
    setLabel(e.target.value);
  };

  const handleSubmit = () => {
    const name = label.replace(/\s+/g, '_').toLowerCase();
    const newElement = {
      name,
      field_type: 'section_break',
      label,
      required: false,
    };
    onSubmit(newElement);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Section Break</DialogTitle>
      <DialogContent>
        <TextField
          label="Label"
          value={label}
          onChange={handleLabelChange}
          fullWidth
          required
        />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={onClose}>Cancel</Button>
        <Button className='standard-button' onClick={handleSubmit} variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SectionBreakDialog;