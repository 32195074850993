// // hooks/useArchiveOperation.js
// import { useState } from 'react';
// import { 
//   Dialog, 
//   DialogTitle, 
//   DialogContent, 
//   DialogActions, 
//   Button, 
//   Typography, 
//   FormControlLabel, 
//   Checkbox 
// } from '@mui/material';
// import { archiveItem } from '../../Backend Routes/PlatformOperations';

// export function useArchiveOperation({ 
//   premiumKey, 
//   itemName, 
//   onSuccess, 
//   setSnackbar 
// }) {
//   const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
//   const [confirmArchive, setConfirmArchive] = useState(false);
//   const [itemToArchiveId, setItemToArchiveId] = useState(null);

//   const handleArchive = (id) => {
//     setItemToArchiveId(id);
//     setConfirmArchive(false);
//     setOpenConfirmationDialog(true);
//   };

//   const handleConfirmArchive = async () => {
//     if (confirmArchive && itemToArchiveId !== null) {
//       try {
//         await archiveItem(premiumKey, itemToArchiveId, itemName);
        
//         // Call the success callback (for updating local state)
//         if (onSuccess) {
//           onSuccess(itemToArchiveId);
//         }

//         // Show success message
//         if (setSnackbar) {
//           setSnackbar({
//             open: true,
//             message: `${itemName} successfully archived`,
//             severity: "success"
//           });
//         }
//       } catch (error) {
//         // Show error message
//         if (setSnackbar) {
//           setSnackbar({
//             open: true,
//             message: `Failed to archive ${itemName}`,
//             severity: "error"
//           });
//         }
//       } finally {
//         setOpenConfirmationDialog(false);
//         setItemToArchiveId(null);
//         setConfirmArchive(false);
//       }
//     }
//   };
  
//   const ArchiveConfirmationDialog = ({ 
//     onClose = () => setOpenConfirmationDialog(false) 
//   }) => (
//     <Dialog
//       open={openConfirmationDialog}
//       onClose={onClose}
//     >
//       <DialogTitle>Confirm Archiving</DialogTitle>
//       <DialogContent>
//         <Typography>
//           Are you sure you want to archive this {itemName} entry?
//         </Typography>
//         <FormControlLabel
//           control={
//             <Checkbox
//               checked={confirmArchive}
//               onChange={(e) => setConfirmArchive(e.target.checked)}
//             />
//           }
//           label={`Yes, I want to archive this ${itemName}`}
//         />
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose}>Cancel</Button>
//         <Button
//           onClick={handleConfirmArchive}
//           color="error"
//           disabled={!confirmArchive}
//         >
//           Archive
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );

//   return {
//     handleArchive,
//     ArchiveConfirmationDialog,
//     isConfirmationOpen: openConfirmationDialog,
//     closeConfirmation: () => setOpenConfirmationDialog(false)
//   };
// }


// hooks/useArchiveOperation.js

import { useState, useCallback } from 'react';

/**
 * useArchiveOperation Hook
 *
 * Manages the archiving process for an item, including state for the confirmation dialog.
 *
 * Parameters:
 * - itemName (string): Name of the item being archived (e.g., "prospect").
 * - onArchive (function): Callback function to execute when the archive action is confirmed.
 *
 * Returns:
 * - handleArchive (function): Initiates the archive process.
 * - openConfirmationDialog (boolean): Controls the visibility of the confirmation dialog.
 * - confirmArchive (boolean): Indicates whether the user has checked the confirmation checkbox.
 * - setConfirmArchive (function): Updates the confirmArchive state.
 * - handleConfirmArchive (function): Handles the confirmation action.
 * - handleCloseDialog (function): Closes the confirmation dialog.
 */
export function useArchiveOperation({ itemName, onArchive }) {
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmArchive, setConfirmArchive] = useState(false);
  const [itemToArchiveId, setItemToArchiveId] = useState(null);
  const [isArchiving, setIsArchiving] = useState(false);

  /**
   * Initiates the archive process by setting the item ID and opening the dialog.
   * Wrapped with useCallback to prevent unnecessary re-creations.
   */
  const handleArchive = useCallback((id) => {
    setItemToArchiveId(id);
    setConfirmArchive(false);
    setOpenConfirmationDialog(true);
  }, []);

  /**
   * Handles the confirmation action when the user confirms archiving.
   * Calls the onArchive callback and resets state.
   */
  const handleConfirmArchive = useCallback(async () => {
    if (confirmArchive && itemToArchiveId !== null) {
      setIsArchiving(true);
      try {
        await onArchive(itemToArchiveId);

        // Reset state after successful archiving
        setOpenConfirmationDialog(false);
        setItemToArchiveId(null);
        setConfirmArchive(false);
      } catch (error) {
        // Handle error if necessary
        console.error(`Failed to archive ${itemName}:`, error);
      }
    }
  }, [confirmArchive, itemToArchiveId, onArchive, itemName]);

  /**
   * Closes the confirmation dialog and resets relevant state.
   */
  const handleCloseDialog = useCallback(() => {
    if (!isArchiving) {
      setOpenConfirmationDialog(false);
      setItemToArchiveId(null);
      setConfirmArchive(false);
    }
  }, [isArchiving]);

  return {
    handleArchive,
    openConfirmationDialog,
    confirmArchive,
    setConfirmArchive,
    handleConfirmArchive,
    handleCloseDialog,
    isArchiving,
  };
}
