import React from 'react';
import { useState, useContext } from 'react';
import {
  Button,
  Snackbar,
  Grid,
  Typography,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  FormControlLabel,
  Checkbox,
  Tooltip,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useNavigate } from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import PreviewIcon from '@mui/icons-material/Preview';
import { useFetchFormData } from '../components/Backend Routes/DataFetch';
import { DeleteSlate } from "../components/Backend Routes/GeneralDelete"; // Backend API functions
import TemplatePreview from '../components/Template Components/TemplatePreview';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from "../components/UserContext";
import '../App.css';
import { DataGrid } from '@mui/x-data-grid';

const Templates = () => {
  const navigate = useNavigate();
  const { user } = useAuth0();
  const [previewOpen, setPreviewOpen] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [selectedTemplateId, setSelectedTemplateId] = useState(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const { isPremiumUser, premiumKey } = useContext(UserContext);
  const fetch_url = `${process.env.REACT_APP_API_BASE_URL}/slates/FormData/`;
  const edit_url = `${process.env.REACT_APP_API_BASE_URL}/slates/edit-slate`;
  const delete_url = `${process.env.REACT_APP_API_BASE_URL}/slates/delete-slate`;
  const { formFields, loading, error, refetchFormData } = useFetchFormData(
    fetch_url,
    premiumKey,
    'false'
  );

  console.log('formFields:', formFields)
  const handleCreateNewTemplate = () => {
    navigate('/create-slate');
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  const columns = [
    {
      field: 'name',
      headerName: 'Slate Name',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      cellClassName: 'slate-name-cell',
    },
    {
      field: 'actions',
      headerName: 'Actions',
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params) => (
        <div>
          <Tooltip title="Preview Template">
            <IconButton onClick={() => handlePreview(params.row.id)}>
              <PreviewIcon/>
          </IconButton>
          </Tooltip>
          <Tooltip title="Edit Template">
            <IconButton onClick={() => handleEdit(params.row.id)}>
            ✏️
            </IconButton>
          </Tooltip>
          <Tooltip title="Delete Template">
            <IconButton onClick={() => handleDelete(params.row.id)}>
            🗑️
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
  ];

  const rows = formFields.map((form, index) => ({
    id: index,
    name: form.title,
  }));

  const handleEdit = (id) => {
    const selectedForm = formFields[id];
    navigate(`/edit-slate/${selectedForm.database_id}`, { state: { formData: selectedForm } });
    // console.log('selectedForm:', selectedForm)
  };

  const handlePreview = (id) => {
    const template = formFields[id];
    console.log('chosen template: ', template)
    setSelectedTemplate(template);
    setPreviewOpen(true);
  };

  const handleDelete = (id) => {
    setSelectedTemplateId(formFields[id].database_id);
    setOpenConfirmationDialog(true);
  };

  const handleConfirmDelete = async () => {
    if (confirmDelete) {
      try {
        // Call the DeleteSlate function and await the response
        const response = await DeleteSlate(delete_url, selectedTemplateId);

        // Show success message
        setShowSuccessMessage(true);

        // Refetch the form data after successful deletion
        refetchFormData();

        // Close the confirmation dialog
        setOpenConfirmationDialog(false);
        setConfirmDelete(false);
      } catch (error) {
        console.error('Error deleting template:', error);
        // Handle the error, e.g., show an error message
      }
    }
  };

  const handleCloseSuccessMessage = () => {
    setShowSuccessMessage(false);
  };

  const returnProfile = () => {
    navigate('/profile');
  };

  return (
    <div className="templates-container">
      <Tooltip title="Back to Company Profile">
        <IconButton onClick={() => returnProfile()}>
          <ArrowBackIcon />
        </IconButton>
      </Tooltip>
      <Typography variant="h4">Your Templates</Typography>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black' }} />
      <Grid container spacing={2} alignItems="center" justifyContent="space-between">
        <Grid item>
          <Button
            variant="contained"
            startIcon={<AddIcon />}
            onClick={handleCreateNewTemplate}
            className="create-new-template-button standard-button"
          >
            Create New Template
          </Button>
        </Grid>
      </Grid>
      <div style={{ height: 400, width: '100%', marginTop: '20px' }}>
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          rowsPerPageOptions={[10]}
          sx={{
            '& .MuiDataGrid-columnHeader': {
              backgroundColor: '#EFECE6',
              textAlign: 'center',
              fontSize: '18px',
            },
            '& .slate-name-cell': {
              fontSize: '16px',
            },
            '& .MuiDataGrid-cell': {
              justifyContent: 'center',
            },
          }}
        />
      </div>
      <TemplatePreview
        open={previewOpen}
        onClose={() => setPreviewOpen(false)}
        template={selectedTemplate}
      />
      <Dialog open={openConfirmationDialog} onClose={() => setOpenConfirmationDialog(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this slate?</Typography>
          <FormControlLabel
          control={<Checkbox checked={confirmDelete} onChange={(e) => setConfirmDelete(e.target.checked)} />}
          label="Yes"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenConfirmationDialog(false)}>Cancel</Button>
          <Button onClick={handleConfirmDelete} color="error">Delete</Button>
        </DialogActions>
      </Dialog>
      {/* Snackbar for displaying success message */}
      <Snackbar
        open={showSuccessMessage}
        autoHideDuration={2000} //disappears after 3s
        onClose={handleCloseSuccessMessage}
        message="Slate Template Deleted"
        action={
          <IconButton size="small" color="inherit" onClick={handleCloseSuccessMessage}>
            <CloseIcon fontSize="small" />
          </IconButton>
        }
      />
    </div>
  );
};

export default Templates;