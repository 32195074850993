// PaymentFetch.jsx
import { useState, useEffect } from "react";

export const PaymentFetch = (owner) => {
  const [payment, setPayment] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const fetchPayment = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/company/payment-details/?owner=${owner}`);
      if (response.ok) {
        const data = await response.json();
        setPayment(data);
        setLoading(false);
      } else {
        setError('Failed to fetch user profiles');
        setLoading(false);
      }
    } catch (error) {
      setError('Error: ' + error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPayment();
  }, []);

  return { payment, loading, error, refetchPayment: fetchPayment };  // Include premiumKey in the return value
};
