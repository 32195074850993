import React, { useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button } from '@mui/material';

const TableDialog = ({ open, onClose, onSubmit }) => {
  const [label, setLabel] = useState('');
  const [labelError, setLabelError] = useState(false);

  const handleLabelChange = (e) => {
    setLabel(e.target.value);
    setLabelError(false); // Reset the label error when the user starts typing
  };

  const handleSubmit = () => {
    if (label.trim() === '') {
      setLabelError(true);
      return;
    }
    onSubmit(label);
    setLabel('');
    onClose();
  };

  const handleCancel = () => {
    setLabel('');
    setLabelError(false); // Reset the label error when the user cancels
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Add Table</DialogTitle>
      <DialogContent>
        <TextField
          label="Label"
          value={label}
          onChange={handleLabelChange}
          fullWidth
          required
          error={labelError}
          helperText={labelError && 'Please enter a label for the table.'}
        />
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleCancel}>Cancel</Button>
        <Button className='standard-button' onClick={handleSubmit} variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default TableDialog;