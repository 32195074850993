// EditProjectPopup.js
import React, { useEffect, useContext } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, MenuItem, Grid } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';

const EditProjectPopup = ({ open, onClose, project, onUpdate, org_owner }) => {
  const { control, handleSubmit, watch, setValue } = useForm();
  const status = watch('status');
  const { user } = useAuth0();

  // Set the owner and due_date data of the popup to the value previously set by the user
  useEffect(() => {
    if (project) {
      setValue('status', project.status);
      setValue('estimated_date', project.estimated_date.slice(0, 10));
      setValue('completion_date', project.completion_date?.slice(0, 10) || '');
    }
  }, [project, setValue]);

    // If the value isn't completed make sure to set the completion_date to empty
  useEffect(() => {
    if (status !== 'Completed') {
      setValue('completion_date', '');
    }
  }, [status, setValue]);

  const onSubmit = async (formData) => {
    try {
      // Send a request to the backend to update the project data
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/edit-project`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...formData,
          owner: org_owner,
          database_id: project.database_id,
          completion_date: formData.completion_date || null, // Set completion_date to null if it's not provided
        }),
      });

      console.log('formData:', formData) // Set completion_date to null if it's not provided)
      console.log('database_id:', project.database_id)

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      // Refetch the projects after successful update
      onUpdate();

      // Close the popup
      onClose();
    } catch (error) {
      console.error('Error updating project:', error);
      // Handle the error, e.g., show an error message
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Edit Project</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} marginTop={'10px'}>
              <Controller
                name="projectName"
                control={control}
                defaultValue={project?.projectName || ''}
                render={({ field }) => (
                  <TextField {...field} label="Project Name" fullWidth required />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6} marginTop={'10px'}>
              <Controller
                name="projectType"
                control={control}
                defaultValue={project?.projectType || ''}
                render={({ field }) => (
                  <TextField {...field} label="Project Type" select fullWidth required>
                    <MenuItem value="Major Works">Major Works</MenuItem>
                    <MenuItem value="Small Works">Small Works</MenuItem>
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="projectLead"
                control={control}
                defaultValue={project?.projectLead || ''}
                render={({ field }) => (
                  <TextField {...field} label="Project Lead" fullWidth required />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="currency"
                control={control}
                defaultValue={project?.currency || ''}
                render={({ field }) => (
                  <TextField {...field} label="Currency" select fullWidth required>
                    <MenuItem value="USD">USD</MenuItem>
                    <MenuItem value="GBP">GBP</MenuItem>
                    <MenuItem value="EUR">EUR</MenuItem>
                    {/* Add more currencies as needed */}
                  </TextField>
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="estimated_date"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <TextField
                    {...field}
                    label="Estimated Due Date"
                    type="date"
                    fullWidth
                    required
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="status"
                control={control}
                defaultValue={project?.status || ''}
                render={({ field }) => (
                  <TextField {...field} label="Status" select fullWidth required>
                    <MenuItem value="Active">Active</MenuItem>
                    <MenuItem value="Completed">Completed</MenuItem>
                    {/* Add more status options as needed */}
                  </TextField>
                )}
              />
            </Grid>
            {status === 'Completed' && (
              <Grid item xs={12} md={6}>
                <Controller
                  name="completion_date"
                  control={control}
                  defaultValue=""
                  render={({ field }) => (
                    <TextField
                      {...field}
                      label="Completion Date"
                      type="date"
                      fullWidth
                      required
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Controller
                name="address"
                control={control}
                defaultValue={project?.address || ''}
                render={({ field }) => (
                  <TextField {...field} label="Project Address" fullWidth required />
                )}
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={onClose}>Cancel</Button>
            <Button type="submit" color="primary">Update Project</Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default EditProjectPopup;