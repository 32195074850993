/**
 * Archives a specific entry.
 * @param {string} premiumKey - The premium key for API authentication.
 * @param {string} id - The unique identifier of the entry to archive.
 * @returns {Promise<boolean>} A promise that resolves to true if archiving was successful.
 */
export const archiveEntry = async (premiumKey, id, stage, stageId) => {
  console.log(`Archiving ${stage} with id: ${id}`);
  try {
    // Make API request to archive the invoice
    const response = await fetch(
      `${process.env.REACT_APP_API_BASE_URL}/${stage}/archive/?owner=${premiumKey}&${stageId}=${id}`,
      {
        method: "POST",
      }
    );

    // Handle potential errors from the API
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.detail || `Failed to archive ${stage}`);
    }

    console.log(`${stage} with id ${id} archived.`);
    return true; // Indicate success
  } catch (error) {
    console.error(`Error archiving ${stage}:`, error);
    throw error;  // Re-throw the error for handling in the calling function
  }
};