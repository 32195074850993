import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress
} from "@mui/material";

const PaymentDialog = ({ 
  open, 
  onClose, 
  onSubmit, 
  editedPayment, 
  onFieldChange,
  isSubmitting = false,
  isEdit = false
}) => {
  // State for validation errors
  const [validationErrors, setValidationErrors] = useState({
    sort_code: '',
    account_number: ''
  });

  // Handle sort code change with validation
  const handleSortCodeChange = (value) => {
    const numbersOnly = value.replace(/[^\d]/g, '');
    if (value !== numbersOnly) {
      setValidationErrors(prev => ({ 
        ...prev, 
        sort_code: 'Please enter numbers only' 
      }));
    } else if (numbersOnly.length > 6) {
      setValidationErrors(prev => ({ 
        ...prev, 
        sort_code: 'Sort code must be 6 digits' 
      }));
    } else {
      setValidationErrors(prev => ({ ...prev, sort_code: '' }));
    }
    onFieldChange("sort_code", numbersOnly.slice(0, 6));
  };

  // Handle account number change with validation
  const handleAccountNumberChange = (value) => {
    const numbersOnly = value.replace(/[^\d]/g, '');
    if (value !== numbersOnly) {
      setValidationErrors(prev => ({ 
        ...prev, 
        account_number: 'Please enter numbers only' 
      }));
    } else if (numbersOnly.length > 8) {
      setValidationErrors(prev => ({ 
        ...prev, 
        account_number: 'Account number must be 8 digits' 
      }));
    } else {
      setValidationErrors(prev => ({ ...prev, account_number: '' }));
    }
    onFieldChange("account_number", numbersOnly.slice(0, 8));
  };

  // Handle form submission with validation
  const handleSubmit = () => {
    // Validate required fields
    const errors = {};
    
    if (!editedPayment.bank?.trim()) {
      errors.bank = 'Bank name is required';
    }
    if (!editedPayment.sort_code || editedPayment.sort_code.length !== 6) {
      errors.sort_code = 'Valid 6-digit sort code is required';
    }
    if (!editedPayment.account_number || editedPayment.account_number.length !== 8) {
      errors.account_number = 'Valid 8-digit account number is required';
    }

    setValidationErrors(prev => ({
      ...prev,
      ...errors
    }));

    // Only submit if there are no validation errors
    if (Object.keys(errors).length === 0) {
      onSubmit();
    }
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      disableEscapeKeyDown={isSubmitting}
    >
      <DialogTitle>
        {isEdit ? "Edit Payment Details" : "Add Payment Details"}
      </DialogTitle>
      <DialogContent>
        <TextField
          label="Bank"
          value={editedPayment?.bank || ""}
          onChange={(e) => onFieldChange("bank", e.target.value)}
          fullWidth
          margin="normal"
          required
          disabled={isSubmitting}
          error={!!validationErrors.bank}
          helperText={validationErrors.bank}
        />
        <TextField
          label="Bank Address"
          value={editedPayment?.bank_address || ""}
          onChange={(e) => onFieldChange("bank_address", e.target.value)}
          fullWidth
          margin="normal"
          required
          disabled={isSubmitting}
        />
        <TextField
          label="Sort Code"
          value={editedPayment?.sort_code || ""}
          onChange={(e) => handleSortCodeChange(e.target.value)}
          fullWidth
          margin="normal"
          required
          disabled={isSubmitting}
          error={!!validationErrors.sort_code}
          helperText={validationErrors.sort_code}
          inputProps={{ maxLength: 6 }}
        />
        <TextField
          label="Account Number"
          value={editedPayment?.account_number || ""}
          onChange={(e) => handleAccountNumberChange(e.target.value)}
          fullWidth
          margin="normal"
          required
          disabled={isSubmitting}
          error={!!validationErrors.account_number}
          helperText={validationErrors.account_number}
          inputProps={{ maxLength: 8 }}
        />
        <TextField
          label="Payment Terms"
          value={editedPayment?.terms || ""}
          onChange={(e) => onFieldChange("terms", e.target.value)}
          fullWidth
          margin="normal"
          required
          disabled={isSubmitting}
        />
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          color="primary"
          disabled={isSubmitting || Object.values(validationErrors).some(error => !!error)}
          sx={{ 
            minWidth: 80,
            position: 'relative'
          }}
        >
          {isSubmitting ? (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                left: '50%',
                marginLeft: '-12px'
              }}
            />
          ) : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentDialog;