// import React from 'react';
// import {
//   Dialog,
//   DialogTitle,
//   DialogContent,
//   DialogActions,
//   TextField,
//   Button,
// } from "@mui/material";

// const CRMDialog = ({ 
//   open, 
//   onClose, 
//   onSubmit, 
//   editedItem, 
//   onFieldChange, 
//   editEmailError 
// }) => {
//   return (
//     <Dialog open={open} onClose={onClose}>
//       <DialogTitle>{editedItem && editedItem.id ? "Edit Customer" : "Add Customer"}</DialogTitle>
//       <DialogContent>
//         <TextField
//           label="Customer Name"
//           value={editedItem?.customer_name || ""}
//           onChange={(e) => onFieldChange("customer_name", e.target.value)}
//           fullWidth
//           margin="normal"
//           required
//         />
//         <TextField
//           label="Company Address"
//           value={editedItem?.customer_address || ""}
//           onChange={(e) => onFieldChange("customer_address", e.target.value)}
//           fullWidth
//           margin="normal"
//           required
//         />
//         <TextField
//           label="Contact Person"
//           value={editedItem?.contact || ""}
//           onChange={(e) => onFieldChange("contact", e.target.value)}
//           fullWidth
//           margin="normal"
//           required
//         />
//         <TextField
//           label="Email"
//           value={editedItem?.email || ""}
//           onChange={(e) => onFieldChange("email", e.target.value)}
//           fullWidth
//           margin="normal"
//           required
//           error={!!editEmailError}
//           helperText={editEmailError}
//         />
//         <TextField
//           label="Contact Number"
//           value={editedItem?.telephone || ""}
//           onChange={(e) => onFieldChange("telephone", e.target.value)}
//           fullWidth
//           margin="normal"
//           required
//         />
//         <TextField
//           label="VAT Number"
//           value={editedItem?.vat_number || ""}
//           onChange={(e) => onFieldChange("vat_number", e.target.value)}
//           fullWidth
//           margin="normal"
//           required
//         />
//         <TextField
//           label="Company Number"
//           value={editedItem?.company_number || ""}
//           onChange={(e) => onFieldChange("company_number", e.target.value)}
//           fullWidth
//           margin="normal"
//           required
//         />
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose}>Cancel</Button>
//         <Button onClick={onSubmit} color="primary">Save</Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default CRMDialog;

import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  CircularProgress
} from "@mui/material";

const CRMDialog = ({ 
  open, 
  onClose, 
  onSubmit, 
  editedItem, 
  onFieldChange,
  isSubmitting = false
}) => {
  // Email validation
  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // State for validation errors
  const [validationErrors, setValidationErrors] = useState({
    email: '',
    telephone: ''
  });

  // Handle email change with validation
  const handleEmailChange = (value) => {
    const error = value && !validateEmail(value) ? 'Please enter a valid email address' : '';
    setValidationErrors(prev => ({ ...prev, email: error }));
    onFieldChange("email", value);
  };

  // Handle phone number change with validation
  const handlePhoneChange = (value) => {
    const numbersOnly = value.replace(/[^\d]/g, '');
    if (value !== numbersOnly) {
      setValidationErrors(prev => ({ 
        ...prev, 
        telephone: 'Please enter numbers only' 
      }));
    } else {
      setValidationErrors(prev => ({ ...prev, telephone: '' }));
    }
    onFieldChange("telephone", numbersOnly);
  };

  // Handle form submission with validation
  const handleSubmit = () => {
    // Validate all fields before submission
    const emailError = editedItem.email && !validateEmail(editedItem.email) 
      ? 'Please enter a valid email address' 
      : '';
    
    setValidationErrors(prev => ({
      ...prev,
      email: emailError
    }));

    // Only submit if there are no validation errors
    if (!emailError && !validationErrors.telephone) {
      onSubmit();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{editedItem && editedItem.id ? "Edit Customer" : "Add Customer"}</DialogTitle>
      <DialogContent>
        <TextField
          label="Customer Name"
          value={editedItem?.customer_name || ""}
          onChange={(e) => onFieldChange("customer_name", e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Company Address"
          value={editedItem?.customer_address || ""}
          onChange={(e) => onFieldChange("customer_address", e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Contact Person"
          value={editedItem?.contact || ""}
          onChange={(e) => onFieldChange("contact", e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Email"
          value={editedItem?.email || ""}
          onChange={(e) => handleEmailChange(e.target.value)}
          error={!!validationErrors.email}
          helperText={validationErrors.email}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Contact Number"
          value={editedItem?.telephone || ""}
          onChange={(e) => handlePhoneChange(e.target.value)}
          error={!!validationErrors.telephone}
          helperText={validationErrors.telephone}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="VAT Number"
          value={editedItem?.vat_number || ""}
          onChange={(e) => onFieldChange("vat_number", e.target.value)}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Company Number"
          value={editedItem?.company_number || ""}
          onChange={(e) => onFieldChange("company_number", e.target.value)}
          fullWidth
          margin="normal"
          required
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} disabled={isSubmitting}>Cancel</Button>
        <Button 
          onClick={handleSubmit} 
          color="primary" 
          disabled={isSubmitting || !!validationErrors.email || !!validationErrors.telephone}
        >
          {isSubmitting ? <CircularProgress size={24} /> : 'Save'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CRMDialog;