import React, { useState, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, TextField, MenuItem } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import DatePicker from '../../components/Form Components/DatePicker'
import { UsersFetch } from "../Backend Routes/fetchUsers";
import dayjs from 'dayjs';

const EditAssignedPopup = ({ open, onClose, onUpdate, slate }) => {
  const { user } = useAuth0();
  const fetch_url = `${process.env.REACT_APP_API_BASE_URL}/FormData/`;
  const { users, refetchUsers } = UsersFetch(user.email);

  const [assignee, setAssignee] = useState('');
  const [status, setStatus] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [emailError, setEmailError] = useState('');

  console.log('slate to modify:', slate)
// Set the owner and due_date data of the popup to the value previously set by the user
  useEffect(() => {
    if (slate) {
      setAssignee(slate.assignee);
      setDueDate(slate.due_date.slice(0, 10));
      setStatus(slate.status);
    }
  }, [slate]);

  const handleSave = async () => {
    const updatedSlate = {
      ...slate,
      status: status === "true", // Convert string to boolean
      assignee: assignee,
      due_date: dayjs(dueDate).format('YYYY-MM-DD'), // Convert to YYYY-MM-DD format
      last_updated: new Date().toISOString(),
    };

    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/edit-assigned-slate/${slate.database_id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedSlate),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const responseData = await response.json();
      console.log('Update response:', responseData);
      onUpdate(); // Refresh project details after update
      onClose();
    } catch (error) {
      console.error('Update error:', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Edit Assigned Slate</DialogTitle>
      <DialogContent>
        <TextField
              label="Assignee"
              value={assignee}
              onChange={(e) => setAssignee(e.target.value)}
              fullWidth
              margin="normal"
              select
              required
            >
              {users.map((user) => (
                <MenuItem key={user.email} value={user.email}>
                  {user.name} - {user.email}
                </MenuItem>
              ))}
        </TextField>
        <DatePicker
          name="due_date"
          label="Due Date"
          value={dueDate}
          onChange={(e) => setDueDate(e.target.value)}
          fullWidth
          required
        />
        <TextField
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
              fullWidth
              margin="normal"
              select
              required
            >
            <MenuItem value="false">Active</MenuItem>
            <MenuItem value="true">Completed</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSave} color="primary" disabled={!assignee || !dueDate || !!emailError}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditAssignedPopup;