import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, MenuItem, FormControl, Select, InputLabel, Checkbox, FormControlLabel } from '@mui/material';


// FormElementDialog is a functional component receiving several props:
// open: Boolean to control the visibility of the dialog.
// onClose: Function to call when the dialog needs to be closed.
// onSubmit: Function to handle the submission of the form data.
// fieldType: String indicating the type of form element to add.
// columnIndex: Index of the column (used when the form element is part of a table).
const FormElementDialog = ({ open, onClose, onSubmit, fieldType, columnIndex }) => {
  const [label, setLabel] = useState('');
  const [required, setRequired] = useState(false);
  const [options, setOptions] = useState('');
  const [dataType, setDataType] = useState('text');
  const [labelError, setLabelError] = useState(false);

  useEffect(() => {
    if (open) {
      // Reset form fields when the dialog is opened
      setLabel('');
      setRequired(false);
      setOptions('');
      setDataType('text');
    }
  }, [open]);

  const handleLabelChange = (e) => {
    const value = e.target.value.replace(/[^a-zA-Z0-9-. ]/g, ''); //Filters out non-alphanumeric characters except dash and fullstop
    setLabel(value);
  };

  const handleRequiredChange = (event) => {
    setRequired(event.target.checked);
  };

  const handleDataTypeChange = (event) => {
    setDataType(event.target.value);
  };

  const handleSubmit = () => {
    if (label.trim() === '') {
      // Display an error message or prevent submission
      // Set the labelError state to true if the label is empty
      setLabelError(true);
      return;
    }
    const name = label.replace(/\s+/g, '_').toLowerCase(); //for the name of the element set it to the lowercase of the label and substitute spaces with underscore
    const newElement = {
      name,
      field_type: fieldType,
      label,
      required,
      options: fieldType === 'dropdown' || fieldType === 'checkbox' || fieldType === 'radio' ? options.split(',').map((option) => option.trim()) : [],
      dataType: fieldType === 'table-column' ? dataType : undefined,
    };
    
    if (fieldType === 'table-column') {
      newElement.dataType = dataType;
    }
    console.log("FormElementDialog - NewElement:", newElement)
    setLabelError(false); //reset the LabelError variable back to false
    onSubmit(newElement);
  };

  const handleCancel = () => {
    setLabelError(false); // Reset the label error when the user cancels
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Add Form Element</DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
        <TextField label="Label" value={label} margin="normal" onChange={handleLabelChange} fullWidth required  error={labelError} helperText={labelError && 'Please enter a name for the form element.'}/>
        {fieldType === 'table-column' && (
          <TextField
            label="Data Type"
            value={dataType}
            onChange={handleDataTypeChange}
            fullWidth
            select
          >
            <MenuItem value="text">Text</MenuItem>
            <MenuItem value="number">Number</MenuItem>
            <MenuItem value="decimal">Decimal</MenuItem>
            <MenuItem value="date">Date</MenuItem>
            <MenuItem value="signature">Signature</MenuItem>
            <MenuItem value="colour">Colour Dropdown</MenuItem>
            <MenuItem value="description">Description</MenuItem>
            <MenuItem value="picture">Picture</MenuItem>
          </TextField>
        )}
        <FormControlLabel
          control={<Checkbox checked={required} onChange={handleRequiredChange} />}
          label="Required"
        />
        {(fieldType === 'dropdown' || fieldType === 'checkbox' || fieldType === 'radio') && (
          <TextField label="Options (comma-separated)" value={options} onChange={(e) => setOptions(e.target.value)} fullWidth />
        )}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleCancel}>Cancel</Button>
        <Button className='standard-button' onClick={handleSubmit} variant="contained">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FormElementDialog;

// import React, { useState, useEffect } from 'react';
// import { Dialog, DialogTitle, DialogContent, DialogActions, TextField, Button, MenuItem, FormControl, Select, InputLabel } from '@mui/material';

// const FormElementDialog = ({ open, onClose, onSubmit, fieldType }) => {
//   const [label, setLabel] = useState('');
//   const [required, setRequired] = useState(false);
//   const [options, setOptions] = useState('');

//   useEffect(() => {
//     if (open) {
//       // Reset form fields when the dialog is opened
//       setLabel('');
//       setRequired(false);
//       setOptions('');
//     }
//   }, [open]);

//   const handleLabelChange = (e) => {
//     const value = e.target.value.replace(/[^a-zA-Z0-9 ]/g, '');
//     setLabel(value);
//   };

//   const handleSubmit = () => {
//     const name = label.replace(/\s+/g, '_').toLowerCase();
//     const newElement = {
//       name,
//       field_type: fieldType,
//       label,
//       required,
//       options: fieldType === 'dropdown' || fieldType === 'checkbox' || fieldType === 'radio' ? options.split(',').map((option) => option.trim()) : [],
//     };
//     // console.log('New Element:', newElement);
//     onSubmit(newElement);
//     onClose();
//   };

//   return (
//     <Dialog open={open} onClose={onClose}>
//       <DialogTitle>Add Form Element</DialogTitle>
//       <DialogContent style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
//         <TextField
//           label="Label"
//           value={label}
//           onChange={handleLabelChange}
//           fullWidth
//           required
//         />
//         <FormControl fullWidth>
//           <InputLabel>Required</InputLabel>
//           <Select
//             value={required}
//             onChange={(e) => setRequired(e.target.value)}
//             fullWidth
//           >
//             <MenuItem value={false}>False</MenuItem>
//             <MenuItem value={true}>True</MenuItem>
//           </Select>
//         </FormControl>
//         {(fieldType === 'dropdown' || fieldType === 'checkbox' || fieldType === 'radio') && (
//           <TextField
//             label="Options (comma-separated)"
//             value={options}
//             onChange={(e) => setOptions(e.target.value)}
//             fullWidth
//           />
//         )}
//       </DialogContent>
//       <DialogActions>
//         <Button onClick={onClose}>Cancel</Button>
//         <Button onClick={handleSubmit} variant="contained">
//           Add
//         </Button>
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default FormElementDialog;