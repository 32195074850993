import React, { useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  MenuItem,
  CircularProgress
} from "@mui/material";

const AddUserDialog = ({ 
  open, 
  onClose, 
  onSubmit, 
  newUser, 
  onFieldChange,
  isSubmitting = false 
}) => {
  // Email validation
  const validateEmail = (email) => {
    const pattern = /^[^@ ]+@[^@ .]+\.[^@ .]{2,}$/;
    return pattern.test(email);
  };

  // State for validation errors
  const [validationErrors, setValidationErrors] = useState({
    name: '',
    email: '',
    subscription_tier: ''
  });

  // Handle email change with validation
  const handleEmailChange = (value) => {
    const error = value && !validateEmail(value) ? 'Please enter a valid email address' : '';
    setValidationErrors(prev => ({ ...prev, email: error }));
    onFieldChange("email", value);
  };

  // Handle name change with validation
  const handleNameChange = (value) => {
    const error = !value.trim() ? 'Name is required' : '';
    setValidationErrors(prev => ({ ...prev, name: error }));
    onFieldChange("name", value);
  };

  // Check if form is valid before allowing submission
  const isFormValid = () => {
    return (
      newUser.name?.trim() &&
      newUser.email &&
      validateEmail(newUser.email) &&
      newUser.subscription_tier &&
      !Object.values(validationErrors).some(error => !!error)
    );
  };

  return (
    <Dialog 
      open={open} 
      onClose={onClose}
      disableEscapeKeyDown={isSubmitting}
    >
      <DialogTitle>Add User</DialogTitle>
      <DialogContent>
        <TextField
          label="Name"
          value={newUser?.name || ""}
          onChange={(e) => handleNameChange(e.target.value)}
          fullWidth
          margin="normal"
          required
          disabled={isSubmitting}
          error={!!validationErrors.name}
          helperText={validationErrors.name}
        />
        <TextField
          label="Email"
          value={newUser?.email || ""}
          onChange={(e) => handleEmailChange(e.target.value)}
          fullWidth
          margin="normal"
          error={!!validationErrors.email}
          helperText={validationErrors.email}
          required
          disabled={isSubmitting}
        />
        <TextField
          label="Subscription Tier"
          value={newUser?.subscription_tier || ""}
          onChange={(e) => onFieldChange("subscription_tier", e.target.value)}
          fullWidth
          margin="normal"
          variant="outlined"
          select
          required
          disabled={isSubmitting}
        >
          <MenuItem value="Basic">Basic</MenuItem>
          <MenuItem value="Premium User">Premium User</MenuItem>
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button 
          onClick={onClose}
          disabled={isSubmitting}
        >
          Cancel
        </Button>
        <Button
          onClick={onSubmit}
          color="primary"
          disabled={isSubmitting || !isFormValid()}
          sx={{ 
            minWidth: 80,
            position: 'relative'
          }}
        >
          {isSubmitting ? (
            <CircularProgress
              size={24}
              sx={{
                position: 'absolute',
                left: '50%',
                marginLeft: '-12px'
              }}
            />
          ) : 'Add'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddUserDialog;