import React, { useState, useEffect, useContext } from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Radio, List, ListItem, ListItemText, CircularProgress, Typography, TextField, MenuItem } from '@mui/material';
import { useAuth0 } from '@auth0/auth0-react';
import { useFetchFormData } from '../components/Backend Routes/DataFetch';
import { UsersFetch } from "../components/Backend Routes/fetchUsers";
import { UserContext } from "../components/UserContext";
import DatePicker from '../components/Form Components/DatePicker';
import dayjs from 'dayjs';

const TemplateSelectionDialog = ({ open, onClose, onImport, projectId }) => {
  const { user } = useAuth0();
  const fetch_url = `${process.env.REACT_APP_API_BASE_URL}/FormData/`;
  const { isPremiumUser, premiumKey } = useContext(UserContext);
  const { users, refetchUsers } = UsersFetch(user.email);
  const { formFields, loading, error, refetchFormData } = useFetchFormData(
    fetch_url,
    premiumKey,
    'false'
  );

  console.log('users fetched:', users);

  const [selectedTemplates, setSelectedTemplates] = useState([]);
  const [step, setStep] = useState(1);
  const [assignee, setAssignee] = useState('');
  const [dueDate, setDueDate] = useState('');
  const [emailError, setEmailError] = useState('');

  const handleToggle = (template) => {
    setSelectedTemplates([template]);
  };

  const handleNext = () => {
    setStep(2);
  };

  const handleBack = () => {
    setStep(1);
  };

  const handleImport = async () => {
    for (const template of selectedTemplates) {
      const submissionData = {
        ...template,
        assignee: assignee,
        owner_org: premiumKey,
        due_date: dayjs(dueDate).format('YYYY-MM-DD'), // Convert to YYYY-MM-DD format
        assigned_date: new Date().toISOString(),
        last_updated: new Date().toISOString(),
        status: false,
        database_id: null,
        projectId: projectId, // Adding the project name to the submission data
      };

      console.log('Importing templates with data:', submissionData);

      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/assign-slate/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(submissionData),
        });
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const responseData = await response.json();
        console.log('Submission response:', responseData);
      } catch (error) {
        console.error('Submission error:', error);
      }
    }

    setSelectedTemplates([]);
    setAssignee('');
    setDueDate('');
    onClose();
    onImport(selectedTemplates); // Refresh project details after import
  };

  useEffect(() => {
    if (open) {
      refetchFormData();
      setStep(1);
    }
  }, [open]);

  const validateEmail = (email) => {
    const pattern = /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/;
    return pattern.test(email);
  };

  const handleAssigneeChange = (e) => {
    const value = e.target.value;
    setAssignee(value.toLowerCase()); // Convert the assignee email to lowercase
    if (!validateEmail(value)) {
      setEmailError("Please enter a valid email address");
    } else {
      setEmailError("");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>Select Templates to Import</DialogTitle>
      <DialogContent>
        {loading ? (
          <CircularProgress />
        ) : error ? (
          <Typography color="error">Error: {error.message}</Typography>
        ) : step === 1 ? (
          <List>
            {formFields.map((template) => (
              <ListItem key={template.id} ButtonBase onClick={() => handleToggle(template)}>
                <Radio
                  // checked={selectedTemplates.indexOf(template) !== -1}
                  checked={selectedTemplates.length === 1 && selectedTemplates[0] === template}
                  tabIndex={-1}
                  disableRipple
                />
                <ListItemText primary={template.title} />
              </ListItem>
            ))}
          </List>
        ) : (
          <div>
            <TextField
              label="Assignee"
              value={assignee}
              onChange={(e) => setAssignee(e.target.value)}
              fullWidth
              margin="normal"
              select
            >
              {users.map((user) => (
                <MenuItem key={user.email} value={user.email}>
                  {user.name} - {user.email}
                </MenuItem>
              ))}
            </TextField>
            <DatePicker
              name="due_date"
              label="Due Date"
              value={dueDate}
              onChange={(e) => setDueDate(e.target.value)}
              fullWidth
              required
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        {step === 1 ? (
          <>
            <Button onClick={onClose} color="secondary">Cancel</Button>
            <Button onClick={handleNext} color="primary" disabled={selectedTemplates.length === 0}>Next</Button>
          </>
        ) : (
          <>
            <Button onClick={handleBack} color="secondary">Back</Button>
            <Button onClick={handleImport} color="primary" disabled={!assignee || !dueDate || !!emailError}>Import</Button>
          </>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default TemplateSelectionDialog;


// import React, { useState, useEffect } from 'react';
// import { Dialog, DialogActions, DialogContent, DialogTitle, Button, Radio, List, ListItem, ListItemText, CircularProgress, Typography, TextField } from '@mui/material';
// import { useAuth0 } from '@auth0/auth0-react';
// import { useFetchFormData } from '../components/DataFetch';
// import DatePicker from '../components/Form Components/DatePicker';
// import dayjs from 'dayjs';

// const TemplateSelectionDialog = ({ open, onClose, onImport, projectName }) => {
//   const { user } = useAuth0();
//   const { formFields, loading, error, refetchFormData } = useFetchFormData(
//     'http://localhost:8000/FormData/',
//     user.email,
//     'false'
//   );

//   const [selectedTemplates, setSelectedTemplates] = useState([]);
//   const [step, setStep] = useState(1);
//   const [assignee, setAssignee] = useState('');
//   const [dueDate, setDueDate] = useState('');
//   const [emailError, setEmailError] = useState('');

//   const handleToggle = (template) => {
//     const currentIndex = selectedTemplates.indexOf(template);
//     const newSelected = [...selectedTemplates];

//     if (currentIndex === -1) {
//       newSelected.push(template);
//     } else {
//       newSelected.splice(currentIndex, 1);
//     }

//     setSelectedTemplates(newSelected);
//   };

//   const handleNext = () => {
//     setStep(2);
//   };

//   const handleBack = () => {
//     setStep(1);
//   };

//   const handleImport = async () => {
//     for (const template of selectedTemplates) {
//       const submissionData = {
//         ...template,
//         owner: assignee,
//         project: projectName,
//         due_date: dayjs(dueDate).toISOString(), // Convert to YYYY-MM-DD format
//         last_updated: new Date().toISOString(),
//         status: false,
//       };

//       console.log('Importing templates with data:', submissionData);

//       try {
//         const response = await fetch('http://localhost:8000/assign-slate/', {
//           method: 'POST',
//           headers: {
//             'Content-Type': 'application/json',
//           },
//           body: JSON.stringify(submissionData),
//         });
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         const responseData = await response.json();
//         console.log('Submission response:', responseData);
//       } catch (error) {
//         console.error('Submission error:', error);
//       }
//     }

//     setSelectedTemplates([]);
//     setAssignee('');
//     setDueDate('');
//     onClose();
//   };

//   useEffect(() => {
//     if (open) {
//       refetchFormData();
//       setStep(1);
//     }
//   }, [open]);

//   const validateEmail = (email) => {
//     const pattern = /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/;
//     return pattern.test(email);
//   };

//   const handleAssigneeChange = (e) => {
//     const value = e.target.value;
//     setAssignee(value);
//     if (!validateEmail(value)) {
//       setEmailError("Please enter a valid email address");
//     } else {
//       setEmailError("");
//     }
//   };

//   return (
//     <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
//       <DialogTitle>Select Templates to Import</DialogTitle>
//       <DialogContent>
//         {loading ? (
//           <CircularProgress />
//         ) : error ? (
//           <Typography color="error">Error: {error.message}</Typography>
//         ) : step === 1 ? (
//           <List>
//             {formFields.map((template) => (
//               <ListItem key={template.id} button onClick={() => handleToggle(template)}>
//                 <Radio
//                   checked={selectedTemplates.indexOf(template) !== -1}
//                   tabIndex={-1}
//                   disableRipple
//                 />
//                 <ListItemText primary={template.title} />
//               </ListItem>
//             ))}
//           </List>
//         ) : (
//           <div>
//             <TextField
//               label="Assignee"
//               value={assignee}
//               onChange={handleAssigneeChange}
//               placeholder="Please enter assignee's email address"
//               fullWidth
//               margin="normal"
//               error={!!emailError}
//               helperText={emailError}
//             />
//             <DatePicker
//               name="due_date"
//               label="Due Date"
//               value={dueDate}
//               onChange={(e) => setDueDate(e.target.value)}
//               fullWidth
//               required
//             />
//           </div>
//         )}
//       </DialogContent>
//       <DialogActions>
//         {step === 1 ? (
//           <>
//             <Button onClick={onClose} color="secondary">Cancel</Button>
//             <Button onClick={handleNext} color="primary" disabled={selectedTemplates.length === 0}>Next</Button>
//           </>
//         ) : (
//           <>
//             <Button onClick={handleBack} color="secondary">Back</Button>
//             <Button onClick={handleImport} color="primary" disabled={!assignee || !dueDate || !!emailError}>Import</Button>
//           </>
//         )}
//       </DialogActions>
//     </Dialog>
//   );
// };

// export default TemplateSelectionDialog;
