import React, { useState, useRef, useEffect } from 'react';
import { Button, Dialog, DialogContent, DialogActions } from '@mui/material';
import { Camera, CameraType } from 'react-camera-pro';
import styled from 'styled-components';

const Wrapper = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Control = styled.div`
  position: absolute;
  bottom: 20px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  z-index: 10;
`;

const StyledButton = styled.button`
  outline: none;
  color: white;
  opacity: 1;
  background: transparent;
  padding: 0;
  cursor: pointer;
  z-index: 2;
  border: none;

  &:hover {
    opacity: 0.7;
  }
`;

const TakePhotoButton = styled(StyledButton)`
  background: url('https://img.icons8.com/ios/50/000000/compact-camera.png');
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  &:hover {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const TorchButton = styled(StyledButton)`
  background: url('https://img.icons8.com/ios/50/000000/light.png');
  background-position: center;
  background-size: 50px;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
  border: solid 4px black;
  border-radius: 50%;
  &.toggled {
    background-color: rgba(0, 0, 0, 0.3);
  }
`;

const ChangeFacingCameraButton = styled(StyledButton)`
  background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
  background-position: center;
  background-size: 40px;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
  padding: 40px;
  &:disabled {
    opacity: 0;
    cursor: default;
    padding: 60px;
  }
  @media (max-width: 400px) {
    padding: 40px 5px;
    &:disabled {
      padding: 40px 25px;
    }
  }
`;

const ImagePreview = styled.div`
  width: 120px;
  height: 120px;
  ${({ image }) => (image ? `background-image:  url(${image});` : '')}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 400px) {
    width: 50px;
    height: 120px;
  }
`;

const FullScreenImagePreview = styled.div`
  width: 100%;
  height: 100%;
  z-index: 100;
  position: absolute;
  background-color: black;
  ${({ image }) => (image ? `background-image:  url(${image});` : '')}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`;

const PictureCapture = ({ open, onClose, onCapture }) => {
  const [image, setImage] = useState(null);
  const [showImage, setShowImage] = useState(false);
  const [numberOfCameras, setNumberOfCameras] = useState(0);
  const [devices, setDevices] = useState([]);
  const [activeDeviceId, setActiveDeviceId] = useState(undefined);
  const [torchToggled, setTorchToggled] = useState(false);
  const camera = useRef(null);

  useEffect(() => {
    (async () => {
      const devices = await navigator.mediaDevices.enumerateDevices();
      const videoDevices = devices.filter((i) => i.kind === 'videoinput');
      setDevices(videoDevices);
    })();
  }, []);

  const handleCapture = () => {
    if (camera.current) {
      const photo = camera.current.takePhoto();
      setImage(photo);
      setShowImage(true);
    }
  };

  const handleSave = () => {
    onCapture(image);
    setImage(null);
    setShowImage(false);
    onClose();
  };

  const handleRetake = () => {
    setImage(null);
    setShowImage(false);
  };

  return (
    // <Dialog open={open} onClose={onClose} fullScreen>
    //   <DialogContent style={{ padding: 0 }}>
    //     {showImage ? (
    //       <FullScreenImagePreview image={image} />
    <Dialog open={open} onClose={onClose} fullScreen>
    <DialogContent style={{ padding: 0, display: 'flex', flexDirection: 'column', height: '100%' }}>
      {showImage ? (
        <>
          <FullScreenImagePreview image={image} style={{ flex: 1 }} />
          <DialogActions style={{ position: 'absolute', bottom: 0, left: 0, right: 0, backgroundColor: 'rgba(0,0,0,0.5)', zIndex: 101 }}>
            <Button onClick={handleRetake} style={{ color: 'white' }}>Retake</Button>
            <Button onClick={handleSave} style={{ color: 'white' }}>Save</Button>
          </DialogActions>
        </>
        ) : (
          <Wrapper>
            <Camera
              ref={camera}
              aspectRatio="cover"
              facingMode="environment"
              numberOfCamerasCallback={(i) => setNumberOfCameras(i)}
              videoSourceDeviceId={activeDeviceId}
              errorMessages={{
                noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
                permissionDenied: 'Permission denied. Please refresh and give camera permission.',
                switchCamera: 'It is not possible to switch camera to different one because there is only one video device accessible.',
                canvas: 'Canvas is not supported.',
              }}
              videoReadyCallback={() => {
                console.log('Video feed ready.');
              }}
            />
            <Control>
              <select
                onChange={(event) => {
                  setActiveDeviceId(event.target.value);
                }}
              >
                {devices.map((d) => (
                  <option key={d.deviceId} value={d.deviceId}>
                    {d.label}
                  </option>
                ))}
              </select>
              <TakePhotoButton onClick={handleCapture} />
              {camera.current?.torchSupported && (
                <TorchButton
                  className={torchToggled ? 'toggled' : ''}
                  onClick={() => {
                    if (camera.current) {
                      setTorchToggled(camera.current.toggleTorch());
                    }
                  }}
                />
              )}
              <ChangeFacingCameraButton
                disabled={numberOfCameras <= 1}
                onClick={() => {
                  if (camera.current) {
                    camera.current.switchCamera();
                  }
                }}
              />
            </Control>
          </Wrapper>
        )}
      </DialogContent>
      {showImage && (
        <DialogActions>
          <Button onClick={handleRetake}>Retake</Button>
          <Button onClick={handleSave}>Save</Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default PictureCapture;


// import React, { useState, useRef, useEffect } from 'react';
// import { Button, Dialog, DialogContent, DialogActions } from '@mui/material';
// import { Camera, CameraType } from 'react-camera-pro';
// import styled from 'styled-components';

// const Wrapper = styled.div`
//   position: fixed;
//   width: 100%;
//   height: 100%;
//   top: 0;
//   left: 0;
//   z-index: 1000;
//   background: rgba(0, 0, 0, 0.9);
//   display: flex;
//   align-items: center;
//   justify-content: center;
// `;

// const Control = styled.div`
//   position: absolute;
//   bottom: 20px;
//   width: 100%;
//   display: flex;
//   justify-content: space-around;
//   z-index: 10;
// `;

// const StyledButton = styled.button`
//   outline: none;
//   color: white;
//   opacity: 1;
//   background: transparent;
//   padding: 0;
//   cursor: pointer;
//   z-index: 2;
//   border: none;

//   &:hover {
//     opacity: 0.7;
//   }
// `;

// const TakePhotoButton = styled(StyledButton)`
// background: url('https://img.icons8.com/ios/50/000000/compact-camera.png');
// background-position: center;
// background-size: 50px;
// background-repeat: no-repeat;
// width: 80px;
// height: 80px;
// border: solid 4px black;
// border-radius: 50%;
// &:hover {
//   background-color: rgba(0, 0, 0, 0.3);
// }
// `;

// const TorchButton = styled(StyledButton)`
// background: url('https://img.icons8.com/ios/50/000000/light.png');
// background-position: center;
// background-size: 50px;
// background-repeat: no-repeat;
// width: 80px;
// height: 80px;
// border: solid 4px black;
// border-radius: 50%;
// &.toggled {
//   background-color: rgba(0, 0, 0, 0.3);
// }
// `;

// const ChangeFacingCameraButton = styled(StyledButton)`
// background: url(https://img.icons8.com/ios/50/000000/switch-camera.png);
// background-position: center;
// background-size: 40px;
// background-repeat: no-repeat;
// width: 40px;
// height: 40px;
// padding: 40px;
// &:disabled {
//   opacity: 0;
//   cursor: default;
//   padding: 60px;
// }
// @media (max-width: 400px) {
//   padding: 40px 5px;
//   &:disabled {
//     padding: 40px 25px;
//   }
// }
// `;

// const ImagePreview = styled.div`
// width: 120px;
// height: 120px;
// ${({ image }) => (image ? `background-image:  url(${image});` : '')}
// background-size: contain;
// background-repeat: no-repeat;
// background-position: center;
// @media (max-width: 400px) {
//   width: 50px;
//   height: 120px;
// }
// `;

// const FullScreenImagePreview = styled.div`
// width: 100%;
// height: 100%;
// z-index: 100;
// position: absolute;
// background-color: black;
// ${({ image }) => (image ? `background-image:  url(${image});` : '')}
// background-size: contain;
// background-repeat: no-repeat;
// background-position: center;
// `;

// const PictureCapture = ({ open, onClose, onCapture }) => {
// const [image, setImage] = useState(null);
// const [showImage, setShowImage] = useState(false);
// const [numberOfCameras, setNumberOfCameras] = useState(0);
// const [devices, setDevices] = useState([]);
// const [activeDeviceId, setActiveDeviceId] = useState(undefined);
// const [torchToggled, setTorchToggled] = useState(false);
// const camera = useRef(null);

// useEffect(() => {
//   (async () => {
//     const devices = await navigator.mediaDevices.enumerateDevices();
//     const videoDevices = devices.filter((i) => i.kind === 'videoinput');
//     setDevices(videoDevices);
//   })();
// }, []);

// const handleCapture = () => {
//   if (camera.current) {
//     const photo = camera.current.takePhoto();
//     setImage(photo);
//     setShowImage(true);
//   }
// };

// const handleSave = () => {
//   onCapture(image);
//   onClose();
// };

// return (
//   <Dialog open={open} onClose={onClose} fullScreen>
//     <DialogContent style={{ padding: 0 }}>
//       {showImage ? (
//         <FullScreenImagePreview image={image} onClick={() => setShowImage(!showImage)} />
//       ) : (
//         <Wrapper>
//           <Camera
//             ref={camera}
//             aspectRatio="cover"
//             facingMode="environment"
//             numberOfCamerasCallback={(i) => setNumberOfCameras(i)}
//             videoSourceDeviceId={activeDeviceId}
//             errorMessages={{
//               noCameraAccessible: 'No camera device accessible. Please connect your camera or try a different browser.',
//               permissionDenied: 'Permission denied. Please refresh and give camera permission.',
//               switchCamera: 'It is not possible to switch camera to different one because there is only one video device accessible.',
//               canvas: 'Canvas is not supported.',
//             }}
//             videoReadyCallback={() => {
//               console.log('Video feed ready.');
//             }}
//           />
//           <Control>
//             <select
//               onChange={(event) => {
//                 setActiveDeviceId(event.target.value);
//               }}
//             >
//               {devices.map((d) => (
//                 <option key={d.deviceId} value={d.deviceId}>
//                   {d.label}
//                 </option>
//               ))}
//             </select>
//             <TakePhotoButton onClick={handleCapture} />
//             {camera.current?.torchSupported && (
//               <TorchButton
//                 className={torchToggled ? 'toggled' : ''}
//                 onClick={() => {
//                   if (camera.current) {
//                     setTorchToggled(camera.current.toggleTorch());
//                   }
//                 }}
//               />
//             )}
//             <ChangeFacingCameraButton
//               disabled={numberOfCameras <= 1}
//               onClick={() => {
//                 if (camera.current) {
//                   camera.current.switchCamera();
//                 }
//               }}
//             />
//           </Control>
//         </Wrapper>
//       )}
//     </DialogContent>
//     {showImage && (
//       <DialogActions>
//         <Button onClick={() => setShowImage(false)}>Retake</Button>
//         <Button onClick={handleSave}>Save</Button>
//       </DialogActions>
//     )}
//   </Dialog>
// );
// };

// export default PictureCapture;