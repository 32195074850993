// // NumberField.js
// import React from 'react';
// import { TextField, Typography } from '@mui/material';

// const NumberField = React.forwardRef(({ name, label, required, step = "1",...props }, ref) => {
//   return (
//     <div className="form-element">
//       <Typography variant="subtitle1">
//           {label}
//       </Typography>
//       <TextField
//         type="number"
//         id={name}
//         name={name}
//         inputRef={ref}
//         required={required}
//         min="0" // Add this line to set the minimum value
//         step={step} // Set the step for integer increments
//         {...props}
//       />
//     </div>
//   );
// });

// export default NumberField;

// NumberField.js
import React from 'react';
import { TextField, Typography } from '@mui/material';

const NumberField = React.forwardRef(({ name, label, required, ...props }, ref) => {
  return (
    <div>
      <Typography variant="subtitle1" className="form-element-label">
        {label}
      </Typography>
      <TextField
        type="number"
        id={name}
        name={name}
        inputRef={ref}
        required={required}
        placeholder='Integer Number'
        inputProps={{
          min: 0,
          step: 1,
        }}
        {...props}
      />
    </div>
  );
});

export default NumberField;