import React, { useContext, useEffect, useState, useMemo } from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TablePagination from '@mui/material/TablePagination';
import Paper from '@mui/material/Paper';
import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import InfoIcon from '@mui/icons-material/Info';
import { SlatesFetch } from '../components/Backend Routes/OrgSlatesFetch';
import { DashboardDataFetch } from '../components/Backend Routes/DashboardDataFetch';
import { Grid, Typography, TextField, Card, CardContent, Select, MenuItem, IconButton, Checkbox, ListItemText, } from '@mui/material';
import TooltipMui from '@mui/material/Tooltip';
import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { BarChart, Bar } from 'recharts';
import { v4 as uuidv4 } from 'uuid';
import { UserContext } from "../components/UserContext";


const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

function createData(
  id: string,
  project_name: string,
  project_type: string,
  slate_name: string,
  status: string,
  assignee: string,
  assigned_date: Date,
  due_date: Date,
) {
  return { id, project_name, project_type, slate_name, status, assignee, assigned_date, due_date };
}

export default function CustomizedTables() {
  const [startDate, setStartDate] = React.useState('');
  const [endDate, setEndDate] = React.useState('');
  const [complianceStatus, setComplianceStatus] = React.useState([]);
  const [status, setStatus] = React.useState([]);
  const [slateName, setSlateName] = React.useState([]);
  const [assignee, setAssignee] = React.useState([]);
  const [projectType, setProjectType] = React.useState([]);
  const [projectName, setProjectName] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const { isPremiumUser, premiumKey } = useContext(UserContext);
  console.log('isPremiumUser:', isPremiumUser)
  const slates_url = `${process.env.REACT_APP_API_BASE_URL}/dashboard/dashboard-data`;
  const { slates } = DashboardDataFetch(slates_url, premiumKey);

  console.log('slates:', slates)
  const [rows, setRows] = useState([]);

  const isOverdue = (row) => {
    return row.status === 'Active' && new Date(row.due_date) < new Date();
  };

  const isDueSoon = (row) => {
    const twoDaysFromNow = new Date();
    twoDaysFromNow.setDate(twoDaysFromNow.getDate() + 2);
    return row.status === 'Active' && new Date(row.due_date) <= twoDaysFromNow && new Date(row.due_date) > new Date();
  };  

  // Updated function to calculate overdue slates by assignee
  const calculateOverdueSlatesByAssignee = () => {
    const overdueSlates = {};
    filteredRows.forEach((row) => {
      if (isOverdue(row)) {
        overdueSlates[row.assignee] = (overdueSlates[row.assignee] || 0) + 1;
      }
    });
    return Object.entries(overdueSlates).map(([name, overdue]) => ({ name, overdue }));
  };

  // New function to calculate overdue slates by project
  const calculateOverdueSlatesByProject = () => {
    const overdueSlates = {};
    filteredRows.forEach((row) => {
      if (isOverdue(row)) {
        overdueSlates[row.project_name] = (overdueSlates[row.project_name] || 0) + 1;
      }
    });
    return Object.entries(overdueSlates).map(([name, overdue]) => ({ name, overdue }));
  };

  // Updated function to calculate total overdue slates
  const calculateTotalOverdueSlates = () => {
    return filteredRows.filter(isOverdue).length;
  };

  useEffect(() => {
    if (slates) {
      const formattedSlates = slates.map(slate => createData(
        slate.database_id,
        slate.project_name,
        slate.project_type || 'N/A', // Assuming project_type is not in your current data
        slate.slate_name,
        slate.status === 'Completed' ? 'Completed' : 'Active',
        slate.assignee_name,
        new Date(slate.assigned_date),
        new Date(slate.due_date)
      ));
      setRows(formattedSlates);
    }
  }, [slates]);

  // Filter the data based on the current filter values
  const filteredRows = rows.filter((row) => {
    const isProjectNameMatch = projectName.length === 0 || projectName.includes(row.project_name);
    const isProjectTypeMatch = projectType.length === 0 || projectType.includes(row.project_type);
    const isSlateNameMatch = slateName.length === 0 || slateName.includes(row.slate_name);
    const isStatusMatch = status.length === 0 || status.includes(row.status);
    const isAssigneeMatch = assignee.length === 0 || assignee.includes(row.assignee);
    const isStartDateMatch = startDate ? new Date(row.due_date) >= new Date(startDate) : true;
    const isEndDateMatch = endDate ? new Date(row.due_date) <= new Date(endDate) : true;
    const rowIsOverdue = isOverdue(row);
    const isComplianceStatusMatch = complianceStatus.length === 0 || 
    (complianceStatus.includes('Overdue') && isOverdue) || 
    (complianceStatus.includes('Not Overdue') && !isOverdue);

    return (
      isProjectNameMatch &&
      isProjectTypeMatch &&
      isSlateNameMatch &&
      isStatusMatch &&
      isComplianceStatusMatch &&
      isAssigneeMatch &&
      isStartDateMatch &&
      isEndDateMatch
    );
  });



  // RenderValue Function for filter buttons
  const renderValue = (selected, placeholder) => {
    if (selected.length === 0) {
      return <Typography>{placeholder}</Typography>;
    }
    else if (selected.length === 1) {
      return <Typography>{selected}</Typography>;
    }
    return <Typography>{`${selected.length} selected`}</Typography>;
  };

    // Function to calculate average overdue slates
    const calculateAverageOverdue = useMemo(() => {
      const currentDate = new Date();
      const overdueSlates = filteredRows.filter(row => 
        row.status === 'Active' && new Date(row.due_date) < currentDate
      );
      
      if (overdueSlates.length === 0) return 0;
  
      const totalOverdueDays = overdueSlates.reduce((sum, row) => {
        const dueDate = new Date(row.due_date);
        const overdueDays = Math.floor((currentDate - dueDate) / (1000 * 60 * 60 * 24));
        return sum + overdueDays;
      }, 0);
  
      return Math.round(totalOverdueDays / overdueSlates.length);
    }, [filteredRows]);

  const AverageOverdue = ({ averageOverdue, percentageChange }) => (
    <Card style={{ position: 'relative' }}>
      <CardContent>
        <div style={{ position: 'absolute', top: 8, right: 8 }}>
          <TooltipMui title="The average days slates are overdue for the organization.">
            <InfoIcon />
          </TooltipMui>
        </div>
        <Typography variant="h6">Average Overdue</Typography>
        <Typography variant="h4">{calculateAverageOverdue} days</Typography>
      </CardContent>
    </Card>
  );

  
  const calculateProjectHealth = () => {
    const completedCount = filteredRows.filter((row) => row.status === 'Completed').length;
    const totalCount = rows.length;
    return totalCount > 0 ? Math.round((completedCount / totalCount) * 100) : 0;
  };

  // Calculate the project health
  const projectHealth = calculateProjectHealth();
  // Calculate the total count of overdue slates
  const totalOverdueSlates = calculateTotalOverdueSlates();
  // Calculate the count of overdue slates by assignee
  const overdueSlatesByAssignee = calculateOverdueSlatesByAssignee();

  const ProjectHealth = ({ projectHealth }) => (
    <Card style={{ position: 'relative' }}>
      <CardContent>
        <div style={{ position: 'absolute', top: 8, right: 8 }}>
          <TooltipMui title="% of assigned slates which are completed.">
            <InfoIcon />
          </TooltipMui>
        </div>
        <Typography variant="h6">Completion Rate</Typography>
        <Typography variant="h4">{projectHealth}%</Typography>
      </CardContent>
    </Card>
  );


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  // Function to extract unique project names, project type, slate name, assignees and status
  const getUniqueProjectNames = () => [...new Set(rows.map((row) => row.project_name))];
  const getUniqueProjectTypes = () => [...new Set(rows.map((row) => row.project_type))];
  const getUniqueSlateNames = () => [...new Set(rows.map((row) => row.slate_name))];
  const getUniqueAssignees = () => [...new Set(rows.map((row) => row.assignee))];
  const getuniqueStatus = () => [...new Set(rows.map((row) => row.status))];

  // Get unique values for each filter category
  const uniqueProjectNames = getUniqueProjectNames();
  const uniqueProjectTypes = getUniqueProjectTypes();
  const uniqueSlateNames = getUniqueSlateNames();
  const uniqueAssignees = getUniqueAssignees();
  const uniqueStatus = getuniqueStatus();
  const uniqueComplianceStatus = ['Overdue','Not Overdue']


  // Sort the filtered rows to display overdue and overduesoon slates at the top
  // Updated sorting function
  const sortedRows = React.useMemo(() => {
    return filteredRows.sort((a, b) => {
      // First, sort by overdue status
      if (isOverdue(a) && !isOverdue(b)) return -1;
      if (!isOverdue(a) && isOverdue(b)) return 1;

      // If both are overdue or both are not overdue, sort by due soon status
      if (isDueSoon(a) && !isDueSoon(b)) return -1;
      if (!isDueSoon(a) && isDueSoon(b)) return 1;

      // If neither of the above conditions apply, sort by active status
      if (a.status === 'Active' && b.status !== 'Active') return -1;
      if (a.status !== 'Active' && b.status === 'Active') return 1;

      // If all else is equal, sort by due date
      return new Date(a.due_date) - new Date(b.due_date);
    });
  }, [filteredRows]);

  // Format date in European format (DD-MM-YYYY)
  const formatDate = (date: Date) => {
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const generateTicks = (data) => {
    const maxValue = Math.max(...data.map(item => item.overdue));
    return Array.from({length: maxValue + 1}, (_, i) => i);
  };

  return (
    <div className="dashboard-container">
      <Typography variant="h4">Compliance Audit Dashboard</Typography>
      <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black',  marginBottom: '16px' }} />

      <Grid container spacing={2} style={{ marginBottom: '16px' }}>
        <Grid item>
          <TextField
            label="Due start date"
            type="date"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: { fontSize: 14 },
              max: endDate,
            }}
          />
        </Grid>
        <Grid item>
          <TextField
            label="Due end date"
            type="date"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            inputProps={{
              style: { fontSize: 14 },
              min: startDate,
            }}
          />
        </Grid>
        <Grid item>
          <Select
            className="filter-dropdown"
            multiple
            value={complianceStatus}
            onChange={(e) => setComplianceStatus(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'status' }}
            // renderValue={(selected) => (selected.length === 0 ? 'Overdue Status' : selected.join(', '))}
            renderValue={(selected) => renderValue(selected, 'Overdue Status')}
          >
            {uniqueComplianceStatus.map((state) => (
            <MenuItem key={state} value={state}>
                <Checkbox checked={complianceStatus.indexOf(state) > -1} />
                <ListItemText primary={state} />
            </MenuItem>
          ))}
          </Select>
        </Grid>
        <Grid item>
          <Select
            className="filter-dropdown"
            multiple
            value={status}
            onChange={(e) => setStatus(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'status' }}
            renderValue={(selected) => renderValue(selected, 'Completion Status')}
          >
            {uniqueStatus.map((state) => (
            <MenuItem key={state} value={state}>
                <Checkbox checked={status.indexOf(state) > -1} />
                <ListItemText primary={state} />
            </MenuItem>
          ))}
          </Select>
        </Grid>
        <Grid item>
          <Select
            className="filter-dropdown"
            multiple
            value={slateName}
            onChange={(e) => setSlateName(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'slate name' }}
            renderValue={(selected) => renderValue(selected, 'Slate Name')}
          >
            {uniqueSlateNames.map((name) => (
            <MenuItem key={name} value={name}>
                <Checkbox checked={slateName.indexOf(name) > -1} />
                <ListItemText primary={name} />
            </MenuItem>
          ))}
          </Select>
        </Grid>
        <Grid item>
          <Select
            className="filter-dropdown"
            multiple
            value={assignee}
            onChange={(e) => setAssignee(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'assignee' }}
            renderValue={(selected) => renderValue(selected, 'Assignee')}
          >
            {uniqueAssignees.map((user) => (
              <MenuItem key={user} value={user}>
                <Checkbox checked={assignee.indexOf(user) > -1} />
                <ListItemText primary={user} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <Select
            multiple
            value={projectType}
            onChange={(e) => setProjectType(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'project type' }}
            renderValue={(selected) => renderValue(selected, 'Project Type')}
          >
            {uniqueProjectTypes.map((type) => (
              <MenuItem key={type} value={type}>
                <Checkbox checked={projectType.indexOf(type) > -1} />
                <ListItemText primary={type} />
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item>
          <Select
            multiple
            value={projectName}
            onChange={(e) => setProjectName(e.target.value)}
            displayEmpty
            inputProps={{ 'aria-label': 'project name' }}
            renderValue={(selected) => (selected.length === 0 ? 'Project Name' : selected.join(', '))}
          >
            {uniqueProjectNames.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={projectName.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
          <TooltipMui title="Refresh Filters">
            <IconButton
              onClick={() => {
                setStartDate('');
                setEndDate('');
                setComplianceStatus([]);
                setStatus([]);
                setSlateName([]);
                setAssignee([]);
                setProjectType([]);
                setProjectName([]);
              }}
            >
              <RestartAltIcon />
            </IconButton>
          </TooltipMui>
        </Grid>
      </Grid>

      <Grid container spacing={3} style={{ marginBottom: '20px' }}>
        <Grid item xs={12} sm={2} container spacing={2}>
          <Grid item xs={12}>
            <AverageOverdue/>
          </Grid>
          
          <Grid item xs={12}>
            <ProjectHealth projectHealth={projectHealth} />
          </Grid>
          <Grid item xs={12}>
          <Card style={{ position: 'relative' }}>
              <CardContent>
                <div style={{ position: 'absolute', top: 8, right: 8 }}>
                  <TooltipMui title="Total number of overdue slates as per the selected filters">
                    <InfoIcon />
                  </TooltipMui>
                </div>
                <Typography variant="h6">Overdue Slates</Typography>
                <Typography variant="h4">{totalOverdueSlates}</Typography>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Card style={{ position: 'relative' }}>
            <CardContent>
              <div style={{ position: 'absolute', top: 8, right: 8 }}>
                <TooltipMui title="Count of overdue slates per project as per the selected filters">
                  <InfoIcon />
                </TooltipMui>
              </div>
              <Typography variant="h6">Count of Overdue Slates by Project</Typography>
              <ResponsiveContainer width="100%" height={320}>
                <BarChart data={calculateOverdueSlatesByProject()} layout="vertical">
                  <XAxis type="number" ticks={generateTicks(calculateOverdueSlatesByProject())}/>
                  <YAxis
                    type="category"
                    dataKey="name"
                    width={110}
                    label={{
                      // value: 'Assignee',
                      angle: -90,
                      position: 'insideLeft',
                      style: { textAnchor: 'middle', fontSize: '14px' },
                    }}
                    style={{ fontSize: '14px' }}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="overdue" name="Count" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={5}>
          <Card style={{ position: 'relative' }}>
            <CardContent>
              <div style={{ position: 'absolute', top: 8, right: 8 }}>
                <TooltipMui title="Count of overdue slates per assignee as per the selected filters">
                  <InfoIcon />
                </TooltipMui>
              </div>
              <Typography variant="h6">Count of Overdue Slates by Assignee</Typography>
              <ResponsiveContainer width="100%" height={320}>
                <BarChart data={overdueSlatesByAssignee} layout="vertical">
                <XAxis 
                    type="number" 
                    ticks={generateTicks(overdueSlatesByAssignee)}
                  />
                  <YAxis
                    type="category"
                    dataKey="name"
                    width={110}
                    // margin={{ left: 20, right: 20, top: 20, bottom: 20 }}
                    label={{
                      // value: 'Assignee',
                      angle: -90,
                      position: 'insideLeft',
                      style: { textAnchor: 'middle', fontSize: '14px' },
                    }}
                    style={{ fontSize: '14px' }}
                  />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="overdue" name="Count" fill="#8884d8" />
                </BarChart>
              </ResponsiveContainer>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      <TableContainer component={Paper} sx={{ marginTop:  '20px', marginBottom: '20px'}}>
        <Table sx={{ minWidth: '100%'}} aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Project Name</StyledTableCell>
              <StyledTableCell align="right">Project Type</StyledTableCell>
              <StyledTableCell align="right">Slate Name</StyledTableCell>
              <StyledTableCell align="right">Completion Status</StyledTableCell>
              <StyledTableCell align="right">Assignee</StyledTableCell>
              <StyledTableCell align="right">Assigned Date</StyledTableCell>
              <StyledTableCell align="right">Due Date</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
          {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
          <StyledTableRow key={row.id}>
            <StyledTableCell component="th" scope="row">
              {row.project_name}
            </StyledTableCell>
            <StyledTableCell align="right">{row.project_type}</StyledTableCell>
            <StyledTableCell align="right">{row.slate_name}</StyledTableCell>
            <StyledTableCell align="right">{row.status}</StyledTableCell>
            <StyledTableCell align="right">{row.assignee}</StyledTableCell>
            <StyledTableCell align="right">{formatDate(row.assigned_date)}</StyledTableCell>
            <StyledTableCell align="right">
              {formatDate(row.due_date)}
              {isOverdue(row) && (
                <TooltipMui title="Slate Overdue">
                  <AssignmentLateIcon style={{ color: 'red', marginLeft: '8px' }} />
                </TooltipMui>
              )}
              {isDueSoon(row) && (
                <TooltipMui title="Overdue Soon">
                  <WarningAmberIcon style={{ color: 'orange', marginLeft: '8px' }} />
              </TooltipMui>
              )}
            </StyledTableCell>
          </StyledTableRow>
        ))}
      </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[25, 50, 100]}
          component="div"
          count={filteredRows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </div>
  );
}

// import React, { useContext, useEffect, useState } from 'react';
// import { styled } from '@mui/material/styles';
// import Table from '@mui/material/Table';
// import TableBody from '@mui/material/TableBody';
// import TableCell, { tableCellClasses } from '@mui/material/TableCell';
// import TableContainer from '@mui/material/TableContainer';
// import TableHead from '@mui/material/TableHead';
// import TableRow from '@mui/material/TableRow';
// import TablePagination from '@mui/material/TablePagination';
// import Paper from '@mui/material/Paper';
// import AssignmentLateIcon from '@mui/icons-material/AssignmentLate';
// import WarningAmberIcon from '@mui/icons-material/WarningAmber';
// import RestartAltIcon from '@mui/icons-material/RestartAlt';
// import InfoIcon from '@mui/icons-material/Info';
// import { SlatesFetch } from '../components/Backend Routes/OrgSlatesFetch';
// import { DashboardDataFetch } from '../components/Backend Routes/DashboardDataFetch';
// import { Grid, Typography, TextField, Card, CardContent, Select, MenuItem, IconButton, Checkbox, ListItemText, } from '@mui/material';
// import TooltipMui from '@mui/material/Tooltip';
// import { LineChart, Line, XAxis, YAxis, Tooltip, Legend, ResponsiveContainer } from 'recharts';
// import { BarChart, Bar } from 'recharts';
// import { v4 as uuidv4 } from 'uuid';
// import { UserContext } from "../components/UserContext";


// // Dummy data for the charts
// const projectHealthData = [
//   // { month: 'Jan 22', overdue: 60 },
//   // { month: 'Feb 22', overdue: 70 },
//   // { month: 'Mar 22', overdue: 72 },
//   { month: 'Apr 22', overdue: 71 },
//   { month: 'May 22', overdue: 74 },
//   { month: 'Jun 22', overdue: 82 },
// ];


// const StyledTableCell = styled(TableCell)(({ theme }) => ({
//   [`&.${tableCellClasses.head}`]: {
//     backgroundColor: theme.palette.common.black,
//     color: theme.palette.common.white,
//   },
//   [`&.${tableCellClasses.body}`]: {
//     fontSize: 14,
//   },
// }));

// const StyledTableRow = styled(TableRow)(({ theme }) => ({
//   '&:nth-of-type(odd)': {
//     backgroundColor: theme.palette.action.hover,
//   },
//   '&:last-child td, &:last-child th': {
//     border: 0,
//   },
// }));

// function createData(
//   id: string,
//   project_name: string,
//   project_type: string,
//   slate_name: string,
//   status: string,
//   assignee: string,
//   assigned_date: Date,
//   due_date: Date,
// ) {
//   return { id, project_name, project_type, slate_name, status, assignee, assigned_date, due_date };
// }

// export default function CustomizedTables() {
//   const [startDate, setStartDate] = React.useState('');
//   const [endDate, setEndDate] = React.useState('');
//   const [complianceStatus, setComplianceStatus] = React.useState([]);
//   const [status, setStatus] = React.useState([]);
//   const [slateName, setSlateName] = React.useState([]);
//   const [assignee, setAssignee] = React.useState([]);
//   const [projectType, setProjectType] = React.useState([]);
//   const [projectName, setProjectName] = React.useState([]);
//   const [page, setPage] = React.useState(0);
//   const [rowsPerPage, setRowsPerPage] = React.useState(10);
//   const { isPremiumUser, premiumKey } = useContext(UserContext);
//   console.log('isPremiumUser:', isPremiumUser)
//   // const slates_url = `${process.env.REACT_APP_API_BASE_URL}/org-slates/`;
//   const slates_url = `${process.env.REACT_APP_API_BASE_URL}/dashboard-data`;
//   const { slates } = DashboardDataFetch(slates_url, premiumKey);

//   console.log('slates:', slates)
//   const [rows, setRows] = useState([]);

//   useEffect(() => {
//     if (slates) {
//       const formattedSlates = slates.map(slate => createData(
//         slate.database_id,
//         slate.project_name,
//         slate.project_type || 'N/A', // Assuming project_type is not in your current data
//         slate.slate_name,
//         slate.status ? 'Completed' : 'Active',
//         slate.assignee_name,
//         new Date(slate.assigned_date),
//         new Date(slate.due_date)
//       ));
//       setRows(formattedSlates);
//     }
//   }, [slates]);



//   // Filter the data based on the current filter values
//   const filteredRows = rows.filter((row) => {
//     const isProjectNameMatch = projectName.length === 0 || projectName.includes(row.project_name);
//     const isProjectTypeMatch = projectType.length === 0 || projectType.includes(row.project_type);
//     const isSlateNameMatch = slateName.length === 0 || slateName.includes(row.slate_name);
//     const isStatusMatch = status.length === 0 || status.includes(row.status);
//     const isAssigneeMatch = assignee.length === 0 || assignee.includes(row.assignee);
//     const isStartDateMatch = startDate ? new Date(row.due_date) >= new Date(startDate) : true;
//     const isEndDateMatch = endDate ? new Date(row.due_date) <= new Date(endDate) : true;
//     const isOverdue = new Date(row.due_date) < new Date();
//     const isComplianceStatusMatch = complianceStatus.length === 0 || 
//     (complianceStatus.includes('Overdue') && isOverdue) || 
//     (complianceStatus.includes('Not Overdue') && !isOverdue);

//     return (
//       isProjectNameMatch &&
//       isProjectTypeMatch &&
//       isSlateNameMatch &&
//       isStatusMatch &&
//       isComplianceStatusMatch &&
//       isAssigneeMatch &&
//       isStartDateMatch &&
//       isEndDateMatch
//     );
//   });


//   // Dummy data for average overdue and project health
//   const averageOverdue = 4;
//   const percentageChange = 50;

//   // RenderValue Function for filter buttons
//   const renderValue = (selected, placeholder) => {
//     if (selected.length === 0) {
//       return <Typography>{placeholder}</Typography>;
//     }
//     else if (selected.length === 1) {
//       return <Typography>{selected}</Typography>;
//     }
//     return <Typography>{`${selected.length} selected`}</Typography>;
//   };
  

//   const AverageOverdue = ({ averageOverdue, percentageChange }) => (
//     <Card style={{ position: 'relative' }}>
//       <CardContent>
//         <div style={{ position: 'absolute', top: 8, right: 8 }}>
//           <TooltipMui title="The average days slates are overdue for the organization.">
//             <InfoIcon />
//           </TooltipMui>
//         </div>
//         <Typography variant="h6">Average Overdue</Typography>
//         <Typography variant="h4">{averageOverdue} days</Typography>
//         <Typography variant="body2" style={{ color: percentageChange >= 0 ? 'red' : 'green' }}>
//           {percentageChange >= 0 ? '+' : ''}{percentageChange}% vs prior month
//         </Typography>
//       </CardContent>
//     </Card>
//   );

//   // Function calcualting the total overdue slates per assignee
//   const calculateOverdueSlatesByAssignee = () => {
//     const overdueSlates = {};
//     const currentDate = new Date();
  
//     filteredRows.forEach((row) => {
//       if (new Date(row.due_date) < currentDate) {
//         overdueSlates[row.assignee] = (overdueSlates[row.assignee] || 0) + 1;
//       }
//     });
  
//     return Object.entries(overdueSlates).map(([name, overdue]) => ({ name, overdue }));
//   };
  
//   const calculateTotalOverdueSlates = () => {
//     const currentDate = new Date();
//     return filteredRows.filter((row) => new Date(row.due_date) < currentDate).length;
//   };
  
//   const calculateProjectHealth = () => {
//     const completedCount = filteredRows.filter((row) => row.status === 'Completed').length;
//     const totalCount = rows.length;
//     return totalCount > 0 ? Math.round((completedCount / totalCount) * 100) : 0;
//   };

//   // Calculate the project health
//   const projectHealth = calculateProjectHealth();
//   // Calculate the total count of overdue slates
//   const totalOverdueSlates = calculateTotalOverdueSlates();
//   // Calculate the count of overdue slates by assignee
//   const overdueSlatesByAssignee = calculateOverdueSlatesByAssignee();

//   const ProjectHealth = ({ projectHealth }) => (
//     <Card style={{ position: 'relative' }}>
//       <CardContent>
//         <div style={{ position: 'absolute', top: 8, right: 8 }}>
//           <TooltipMui title="% of assigned slates which have status Completed.">
//             <InfoIcon />
//           </TooltipMui>
//         </div>
//         <Typography variant="h6">Project Health</Typography>
//         <Typography variant="h4">{projectHealth}%</Typography>
//       </CardContent>
//     </Card>
//   );


//   const handleChangePage = (event, newPage) => {
//     setPage(newPage);
//   };

//   const handleChangeRowsPerPage = (event) => {
//     setRowsPerPage(+event.target.value);
//     setPage(0);
//   };

//   // Function to extract unique project names, project type, slate name, assignees and status
//   const getUniqueProjectNames = () => [...new Set(rows.map((row) => row.project_name))];
//   const getUniqueProjectTypes = () => [...new Set(rows.map((row) => row.project_type))];
//   const getUniqueSlateNames = () => [...new Set(rows.map((row) => row.slate_name))];
//   const getUniqueAssignees = () => [...new Set(rows.map((row) => row.assignee))];
//   const getuniqueStatus = () => [...new Set(rows.map((row) => row.status))];

//   // Get unique values for each filter category
//   const uniqueProjectNames = getUniqueProjectNames();
//   const uniqueProjectTypes = getUniqueProjectTypes();
//   const uniqueSlateNames = getUniqueSlateNames();
//   const uniqueAssignees = getUniqueAssignees();
//   const uniqueStatus = getuniqueStatus();
//   const uniqueComplianceStatus = ['Overdue','Not Overdue']


//   // Sort the filtered rows to display overdue slates at the top
//   const sortedRows = filteredRows.sort((a, b) => {
//     const isOverdueA = new Date(a.due_date) < new Date();
//     const isOverdueB = new Date(b.due_date) < new Date();
//     return isOverdueB - isOverdueA;
//   });

//   // Format date in European format (DD-MM-YYYY)
//   const formatDate = (date: Date) => {
//     const day = date.getDate().toString().padStart(2, '0');
//     const month = (date.getMonth() + 1).toString().padStart(2, '0');
//     const year = date.getFullYear();
//     return `${day}-${month}-${year}`;
//   };

//   return (
//     <div className="dashboard-container">
//       <Typography variant="h4">Compliance Audit Dashboard</Typography>
//       <hr style={{ borderTop: '2px solid black', borderBottom: '2px solid black',  marginBottom: '16px' }} />

//       <Grid container spacing={2} style={{ marginBottom: '16px' }}>
//         <Grid item>
//           <TextField
//             label="Due start date"
//             type="date"
//             value={startDate}
//             onChange={(e) => setStartDate(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             inputProps={{
//               style: { fontSize: 14 },
//               max: endDate,
//             }}
//           />
//         </Grid>
//         <Grid item>
//           <TextField
//             label="Due end date"
//             type="date"
//             value={endDate}
//             onChange={(e) => setEndDate(e.target.value)}
//             InputLabelProps={{
//               shrink: true,
//             }}
//             inputProps={{
//               style: { fontSize: 14 },
//               min: startDate,
//             }}
//           />
//         </Grid>
//         <Grid item>
//           <Select
//             className="filter-dropdown"
//             multiple
//             value={complianceStatus}
//             onChange={(e) => setComplianceStatus(e.target.value)}
//             displayEmpty
//             inputProps={{ 'aria-label': 'status' }}
//             // renderValue={(selected) => (selected.length === 0 ? 'Overdue Status' : selected.join(', '))}
//             renderValue={(selected) => renderValue(selected, 'Overdue Status')}
//           >
//             {uniqueComplianceStatus.map((state) => (
//             <MenuItem key={state} value={state}>
//                 <Checkbox checked={complianceStatus.indexOf(state) > -1} />
//                 <ListItemText primary={state} />
//             </MenuItem>
//           ))}
//           </Select>
//         </Grid>
//         <Grid item>
//           <Select
//             className="filter-dropdown"
//             multiple
//             value={status}
//             onChange={(e) => setStatus(e.target.value)}
//             displayEmpty
//             inputProps={{ 'aria-label': 'status' }}
//             renderValue={(selected) => renderValue(selected, 'Completion Status')}
//           >
//             {uniqueStatus.map((state) => (
//             <MenuItem key={state} value={state}>
//                 <Checkbox checked={status.indexOf(state) > -1} />
//                 <ListItemText primary={state} />
//             </MenuItem>
//           ))}
//           </Select>
//         </Grid>
//         <Grid item>
//           <Select
//             className="filter-dropdown"
//             multiple
//             value={slateName}
//             onChange={(e) => setSlateName(e.target.value)}
//             displayEmpty
//             inputProps={{ 'aria-label': 'slate name' }}
//             renderValue={(selected) => renderValue(selected, 'Slate Name')}
//           >
//             {uniqueSlateNames.map((name) => (
//             <MenuItem key={name} value={name}>
//                 <Checkbox checked={slateName.indexOf(name) > -1} />
//                 <ListItemText primary={name} />
//             </MenuItem>
//           ))}
//           </Select>
//         </Grid>
//         <Grid item>
//           <Select
//             className="filter-dropdown"
//             multiple
//             value={assignee}
//             onChange={(e) => setAssignee(e.target.value)}
//             displayEmpty
//             inputProps={{ 'aria-label': 'assignee' }}
//             renderValue={(selected) => renderValue(selected, 'Assignee')}
//           >
//             {uniqueAssignees.map((user) => (
//               <MenuItem key={user} value={user}>
//                 <Checkbox checked={assignee.indexOf(user) > -1} />
//                 <ListItemText primary={user} />
//               </MenuItem>
//             ))}
//           </Select>
//         </Grid>
//         <Grid item>
//           <Select
//             multiple
//             value={projectType}
//             onChange={(e) => setProjectType(e.target.value)}
//             displayEmpty
//             inputProps={{ 'aria-label': 'project type' }}
//             renderValue={(selected) => renderValue(selected, 'Project Type')}
//           >
//             {uniqueProjectTypes.map((type) => (
//               <MenuItem key={type} value={type}>
//                 <Checkbox checked={projectType.indexOf(type) > -1} />
//                 <ListItemText primary={type} />
//               </MenuItem>
//             ))}
//           </Select>
//         </Grid>
//         <Grid item>
//           <Select
//             multiple
//             value={projectName}
//             onChange={(e) => setProjectName(e.target.value)}
//             displayEmpty
//             inputProps={{ 'aria-label': 'project name' }}
//             renderValue={(selected) => (selected.length === 0 ? 'Project Name' : selected.join(', '))}
//           >
//             {uniqueProjectNames.map((name) => (
//               <MenuItem key={name} value={name}>
//                 <Checkbox checked={projectName.indexOf(name) > -1} />
//                 <ListItemText primary={name} />
//               </MenuItem>
//             ))}
//           </Select>
//           <TooltipMui title="Refresh Filters">
//             <IconButton
//               onClick={() => {
//                 setStartDate('');
//                 setEndDate('');
//                 setComplianceStatus([]);
//                 setStatus([]);
//                 setSlateName([]);
//                 setAssignee([]);
//                 setProjectType([]);
//                 setProjectName([]);
//               }}
//             >
//               <RestartAltIcon />
//             </IconButton>
//           </TooltipMui>
//         </Grid>
//       </Grid>

//       <Grid container spacing={3} style={{ marginBottom: '20px' }}>
//         <Grid item xs={12} sm={2} container spacing={2}>
//           <Grid item xs={12}>
//             <AverageOverdue averageOverdue={averageOverdue} percentageChange={percentageChange} />
//           </Grid>
          
//           <Grid item xs={12}>
//             <ProjectHealth projectHealth={projectHealth} />
//           </Grid>
//           <Grid item xs={12}>
//           <Card style={{ position: 'relative' }}>
//               <CardContent>
//                 <div style={{ position: 'absolute', top: 8, right: 8 }}>
//                   <TooltipMui title="Total number of overdue slates as per the selected filters">
//                     <InfoIcon />
//                   </TooltipMui>
//                 </div>
//                 <Typography variant="h6">Overdue Slates</Typography>
//                 <Typography variant="h4">{totalOverdueSlates}</Typography>
//               </CardContent>
//             </Card>
//           </Grid>
//         </Grid>
//         <Grid item xs={12} sm={5}>
//           <Card style={{ position: 'relative' }}>
//             <CardContent>
//               <div style={{ position: 'absolute', top: 8, right: 8 }}>
//                 <TooltipMui title="Project health of the organization over the last 3 months">
//                   <InfoIcon />
//                 </TooltipMui>
//               </div>
//               <Typography variant="h6">Organization Rolling 3 Month Project Health</Typography>
//               <ResponsiveContainer width="100%" height={320}>
//                 <LineChart data={projectHealthData}>
//                   <XAxis dataKey="month" />
//                   <YAxis
//                     label={{
//                       value: '% Slates Completed',
//                       angle: -90,
//                       position: 'insideLeft',
//                       style: { textAnchor: 'middle', fontSize: '22px' },
//                     }}
//                   />
//                   <Tooltip
//                     formatter={(value, name, props) => {
//                       const completedSlates = 80; // Dummy data
//                       const totalAssignedSlates = 100; // Dummy data
//                       return `${value}% (${completedSlates}/${totalAssignedSlates})`;
//                     }}
//                   />
//                   <Legend />
//                   <Line type="monotone" dataKey="overdue" name="Project Compliance" stroke="#8884d8" />
//                 </LineChart>
//               </ResponsiveContainer>
//             </CardContent>
//           </Card>
//         </Grid>
//         <Grid item xs={12} sm={5}>
//           <Card style={{ position: 'relative' }}>
//             <CardContent>
//               <div style={{ position: 'absolute', top: 8, right: 8 }}>
//                 <TooltipMui title="Count of overdue slates per assignee as per the selected filters">
//                   <InfoIcon />
//                 </TooltipMui>
//               </div>
//               <Typography variant="h6">Count of Overdue Slates by Assignee</Typography>
//               <ResponsiveContainer width="100%" height={320}>
//                 <BarChart data={overdueSlatesByAssignee} layout="vertical">
//                   <XAxis type="number"  />
//                   <YAxis
//                     type="category"
//                     dataKey="name"
//                     label={{
//                       // value: 'Assignee',
//                       angle: -90,
//                       position: 'insideLeft',
//                       style: { textAnchor: 'middle', fontSize: '14px' },
//                     }}
//                     style={{ fontSize: '14px' }}
//                   />
//                   <Tooltip />
//                   <Legend />
//                   <Bar dataKey="overdue" name="Count" fill="#8884d8" />
//                 </BarChart>
//               </ResponsiveContainer>
//             </CardContent>
//           </Card>
//         </Grid>
//       </Grid>

//       <TableContainer component={Paper} sx={{ marginTop:  '20px', marginBottom: '20px'}}>
//         <Table sx={{ minWidth: '100%'}} aria-label="customized table">
//           <TableHead>
//             <TableRow>
//               <StyledTableCell>Project Name</StyledTableCell>
//               <StyledTableCell align="right">Project Type</StyledTableCell>
//               <StyledTableCell align="right">Slate Name</StyledTableCell>
//               <StyledTableCell align="right">Completion Status</StyledTableCell>
//               <StyledTableCell align="right">Assignee</StyledTableCell>
//               <StyledTableCell align="right">Assigned Date</StyledTableCell>
//               <StyledTableCell align="right">Due Date</StyledTableCell>
//             </TableRow>
//           </TableHead>
//           <TableBody>
//             {/* {filteredRows.map((row) => ( */}
//             {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
//               const isOverdue = new Date(row.due_date) < new Date();
//               return (
//               <StyledTableRow key={row.id}>
//                 <StyledTableCell component="th" scope="row">
//                   {row.project_name}
//                 </StyledTableCell>
//                 <StyledTableCell align="right">{row.project_type}</StyledTableCell>
//                 <StyledTableCell align="right">{row.slate_name}</StyledTableCell>
//                 <StyledTableCell align="right">{row.status}</StyledTableCell>
//                 <StyledTableCell align="right">{row.assignee}</StyledTableCell>
//                 <StyledTableCell align="right">{formatDate(row.assigned_date)}</StyledTableCell>
//                 <StyledTableCell align="right">
//                   {formatDate(row.due_date)}
//                   {isOverdue && (
//                     <>
//                       {/* Option 1: Bold red text */}
//                       {/* <span style={{ color: 'red', fontWeight: 'bold', marginLeft: '8px' }}>LATE</span> */}
                      
//                       {/* Option 2: AssignmentLateIcon */}
//                       <AssignmentLateIcon style={{ color: 'red', marginLeft: '8px' }} />
//                     </>
//                   )}
//                 </StyledTableCell>
//               </StyledTableRow>
//               );
//             })}
//           </TableBody>
//         </Table>
//         <TablePagination
//           rowsPerPageOptions={[25, 50, 100]}
//           component="div"
//           count={filteredRows.length}
//           rowsPerPage={rowsPerPage}
//           page={page}
//           onPageChange={handleChangePage}
//           onRowsPerPageChange={handleChangeRowsPerPage}
//         />
//       </TableContainer>
//     </div>
//   );
// }